/* eslint-disable capitalized-comments */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
// MovieDetail.js

/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import _ from "underscore";
import { FiEdit3 } from "react-icons/fi";
import { MdOutlineFileUpload, MdErrorOutline } from "react-icons/md";
import { IoCloseCircleSharp, IoCheckmarkDoneSharp } from "react-icons/io5";
import { v4 as uuidv4 } from "uuid";
import { textStyle , videoTextStyle , sellbutton , videoBigTextStyle } from "../../../../../../components/Styles/index.style"

import { Upload } from "@aws-sdk/lib-storage";
import { S3Client, S3 } from "@aws-sdk/client-s3";

/*
 * CHAKRA UI IMPORT
 */
import {
  Image,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  useToast,
  ModalBody,
  Text,
  Button,
  Flex,
  Box,
  Spinner,
  Select,
  FormControl,
  FormLabel,
  useDisclosure,
  ModalFooter,
  ModalHeader,
  ModalCloseButton,
  Textarea,
  InputGroup,
} from "@chakra-ui/react";
import WebSeriesModal from "./WebSeriesModal";

/*
 * MUTATION
 */
import VideoCategoryUpdate from "../../../__mutation__/index.videoTrayChange.mutation";
import AdminDeleteContent from "../../../__mutation__/index.deleteVideo.mutation";
import UploadTrailer from "../../../__mutation__/index.uploadTrailer.mutation";
import ContentMoveToTop from "../../../__mutation__/index.contentMove.mutation";
import UpdatePremium from "../../../__mutation__/index.updatePremium.mutation";

/*
 * QUERY
 */
import SuperAdminStatusCheck from "../../../__query__/index.adminStatusCheck.query";
import EditContentByAdmin from "../../../../../../components/ApprovalForm/__mutation__/index.editForm.mutation";
import AllCategory from "../../../__query__/index.allCategory.query";


/*
 * OBJECT
 */
const MovieDetail = ({ isOpen, onClose, movieDetails, account, title }) => {
  /*
   * STATE
   */
  const [expandedSummary, setExpandedSummary] = useState({});
  const [summaryId, setSummaryId] = useState("");
  const [detailsId, setDetailsId] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const toast = useToast();
  const history = useHistory();
  const [adminStatusCheck, setAdminStatusCheck] = useState();
  const [editedContentTitle, setEditedContentTitle] = useState("");
  const [editedProducer, setEditedProducer] = useState("");
  const [editedGenre, setEditedGenre] = useState([]);
  const [editedDirector, setEditedDirector] = useState("");
  const [editedCast, setEditedCast] = useState([]);
  const [editedPlotSummary, setEditedPlotSummary] = useState("");
  const [trailerUploadStatus, setTrailerUploadStatus] = useState("");
  const [videoStatus, setVideoStatus] = useState("");
  const [linkState, setLinkState] = useState({
    trailerStoredAt: "",
  });
  const [videoLoading, setVideoLoading] = useState(false);

  const options = [
    { name: "action" },
    { name: "comedy" },
    { name: "documentary" },
    { name: "romance" },
    { name: "drama" },
    { name: "horror" },
    { name: "scienceFiction" },
    { name: "fantasy" },
    { name: "mystery" },
    { name: "thriller" },
    { name: "music" },
    { name: "education" },
  ];

  const {
    isOpen: isOpenPlotSummary,
    onOpen: onOpenPlotSummary,
    onClose: onClosePlotSummary,
  } = useDisclosure();
  const {
    isOpen: isOpenGenre,
    onOpen: onOpenGenre,
    onClose: onCloseGenre,
  } = useDisclosure();
  const {
    isOpen: isOpenContentTitle,
    onOpen: onOpenContentTitle,
    onClose: onCloseContentTitle,
  } = useDisclosure();
  const {
    isOpen: isOpenDirector,
    onOpen: onOpenDirector,
    onClose: onCloseDirector,
  } = useDisclosure();
  const {
    isOpen: isOpenProducer,
    onOpen: onOpenProducer,
    onClose: onCloseProducer,
  } = useDisclosure();
  const {
    isOpen: isOpenCast,
    onOpen: onOpenCast,
    onClose: onCloseCast,
  } = useDisclosure();

  const {
    data: SuperAdminStatusCheckQuery,
    refetch: SuperAdminStatusCheckQueryRefetch,
  } = useQuery(SuperAdminStatusCheck, {
    context: { headers: { "l-authorization": account.token } },
  });
  const [UpdatePremiumMutation, { loading: loadingUpdatePremium }] =
    useMutation(UpdatePremium, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });
  const [ContentMoveToTopMutation, { loading: loadingContentMoveToTop }] =
    useMutation(ContentMoveToTop, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const [AdminDeleteContentMutation, { loading: loadingAdminDeleteContent }] =
    useMutation(AdminDeleteContent, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const [VideoCategoryUpdateMutation, { loading: loadingVideoChanges }] =
    useMutation(VideoCategoryUpdate, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });
  const [EditContentByAdminMutation, { loading: editLoading }] = useMutation(
    EditContentByAdmin,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );

  const [UploadTrailerMutation, { loading: trailerLoading }] = useMutation(
    UploadTrailer,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );

  const _AllCategory = useQuery(AllCategory, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  const handleVideoTrayChange = async () => {
    try {
      const response = await VideoCategoryUpdateMutation({
        variables: {
          categoryId: selectedOption,
          contentDetailId: movieDetails?.ContentSummary?.ContentDetail[0]?.id,
        },
      });

      const data = response.data.VideoCategoryUpdate;

      if (!_.isEmpty(data)) {
        toast({
          title: "Form Submitted",
          description: "Data saved successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        history.push("/");
      }
    } catch (error) {
      // Show error toast
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };
  const toggleSummary = (id) => {
    setExpandedSummary((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handlePremiumStatusUpdate = async (id) => {
    try {
      const response = await UpdatePremiumMutation({
        variables: {
          contentDetailId: id,
          isPremium: videoStatus,
        },
      });

      const data = response.data.UpdatePremium;

      if (data?.status === "CONTENT_DETAIL_UPDATED") {
        toast({
          title: "Content Updated",
          description: "Video status changed successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        onClose();
        history.push("/");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  const handleContentMove = async (id) => {
    try {
      const response = await ContentMoveToTopMutation({
        variables: {
          contentSummaryId: id,
        },
      });

      const data = response.data.ContentMoveToTop;

      if (data?.status === "CONTENT_UPDATE_SUCCESSFUL") {
        toast({
          title: "Content Updated",
          description: "Video moved successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        onClose();
        history.push("/");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  const handleDeleteContent = async (id) => {
    try {
      const response = await AdminDeleteContentMutation({
        variables: {
          contentDetailId: id,
        },
      });

      const data = response.data.AdminDeleteContent;

      if (data?.status === "CONTENT_REMOVED") {
        toast({
          title: "Content Deleted",
          description: "Video deleted successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        onClose();
        history.push("/");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  function formatDuration(durationInMinutes) {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.floor(durationInMinutes % 60);
    const seconds = Math.floor((durationInMinutes % 1) * 60);

    // eslint-disable-next-line init-declarations
    let formattedDuration = "";

    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }

    if (minutes > 0) {
      formattedDuration += `${minutes}m `;
    }

    if (seconds > 0) {
      formattedDuration += `${seconds}s`;
    }

    return formattedDuration.trim(); // Remove any leading/trailing whitespace
  }


  /*
   * FUNCTION
   */
  const confirmLeave = (e) => {
    const confirmationMessage =
      "Your upload is in progress. Are you sure you want to leave?";
    e.returnValue = confirmationMessage;

    return confirmationMessage;
  };

  async function RefetchSuperAdminStatusCheck() {
    await SuperAdminStatusCheckQueryRefetch();
  }


  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      setLinkState((prevFormData) => ({
        ...prevFormData,
        trailerStoredAt: file,
      }));
      setTrailerUploadStatus("success");
    } else {
      setTrailerUploadStatus("error");
    }
  };

  const handleEditRequest = async () => {
    try {
      const response = await EditContentByAdminMutation({
        variables: {
          cast: editedCast,
          contentSummaryId: summaryId,
          contentDetailId: detailsId,
          contentTitle: editedContentTitle,
          dop: editedDirector,
          plotSummary: editedPlotSummary,
          producer: editedProducer,
          genre: editedGenre,
        },
      });

      if (!_.isEmpty(response.data.EditContentByAdmin)) {
        if (
          response.data.EditContentByAdmin.status ===
          "CONTENT_UPDATE_SUCCESSFUL"
        ) {
          toast({
            title: "Form Details Updated",
            description: "Data saved successfully!",
            status: "success",
            duration: 5000,
            isClosable: true,
          });

          onCloseCast();
          onCloseContentTitle();
          onCloseDirector();
          onCloseProducer();
          onClosePlotSummary();
          window.location.reload();
        }
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  const handleTrailerUpload = async (videoLink) => {
    try {
      const response = await UploadTrailerMutation({
        variables: {
          contentDetailId: movieDetails?.ContentSummary?.ContentDetail[0]?.id,
          tailerVideoLink: videoLink,
        },
      });
      const data = response.data.UploadTrailer;
      if (!_.isEmpty(data)) {
        if (data.status === "TRAILER_UPLOADED") {
          toast({
            title: "Form Details Updated",
            description: "Data saved successfully!",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          setLinkState({ trailerStoredAt: "" });
          setTrailerUploadStatus("");
        }
        if (data.status === "TRAILER_UPLOAD_FAILED") {
          toast({
            title: "Form Details Updated",
            description: "Data saved successfully!",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          setLinkState({ trailerStoredAt: "" });
          setTrailerUploadStatus("");
        }
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  const _UploadVideo = async () => {
    if (linkState?.trailerStoredAt) {
      setVideoLoading(true);
      const id = uuidv4();

      // Prevent page refresh or tab close
      window.addEventListener("beforeunload", confirmLeave);

      try {
        const s3Client = new S3Client({
          forcePathStyle: false, // Configures to use subdomain/virtual calling format.
          endpoint: process.env.REACT_APP_S3_ENDPOINT,
          region: "blr1",
          credentials: {
            accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
          },
        });

        const parallelUploads3 = new Upload({
          client: s3Client,
          params: {
            Bucket: "shucaeimage",
            Key: `shucaeContent/${id}.mp4`,
            Body: linkState?.trailerStoredAt,
            ACL: "public-read",
          },
          queueSize: 4, // Optional concurrency configuration
          partSize: 1024 * 1024 * 10, // Optional size of each part, in bytes
          leavePartsOnError: false, // Optional manually handle dropped parts
        });

        const _data = await parallelUploads3.done();
        setVideoLoading(false);

        if (_data.Location) {
          if (!_data.Location.startsWith("https://")) {
            _data.Location = `https://${_data.Location}`;
          }
          handleTrailerUpload(_data.Location);
        }
      } catch (e) {
        setVideoLoading(false);
      } finally {
        window.removeEventListener("beforeunload", confirmLeave);
      }
    }
  };

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  /*
   * USE EFFECT
   */

  useEffect(() => {
    // Handle the admin status check
    if (
      SuperAdminStatusCheckQuery &&
      SuperAdminStatusCheckQuery.SuperAdminStatusCheck
    ) {
      setAdminStatusCheck(
        SuperAdminStatusCheckQuery.SuperAdminStatusCheck.status
      );
    }
  }, [SuperAdminStatusCheckQuery]);

  useEffect(() => {
    RefetchSuperAdminStatusCheck();
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalBody borderRadius="5px" bg="rgba(0, 2, 10, 1)" padding="0px">
          <Box
            borderRadius="10px"
            position="relative"
            overflow="hidden"
            bg="black"
            h="30rem"
          >
            <Image
              position="absolute"
              top="0"
              w="100%"
              h="30rem"
              objectFit="cover"
              src={
                movieDetails?.ContentSummary?.ContentDetail[0]?.thumbnailLink
              }
            />
            <Flex
              position="absolute"
              h="100%"
              w="100%"
              alignItems="center"
              bg="linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.66) 30.6%, rgba(0, 0, 0, 0.00) 103.12%)"
            >
              <Flex
                h="100%"
                flexDir="column"
                ml="2rem"
                justify="flex-end"
                gap="20px"
              >
                <Flex
                  pb="15px"
                  color="rgba(255, 255, 255, 1)"
                  fontFamily="Oswald"
                  fontWeight="700"
                  fontSize={{ base: "35px", md: "50px" }}
                  lineHeight="45px"
                  letterSpacing="-0.75px"
                  gap="8px"
                  align="end"
                >
                  {movieDetails?.ContentSummary?.contentTitle}
                  <FiEdit3
                    size="30px"
                    cursor="pointer"
                    onClick={() => {
                      onOpenContentTitle();
                      setEditedContentTitle(
                        movieDetails?.ContentSummary?.contentTitle
                      );
                      setSummaryId(movieDetails?.ContentSummary?.id);
                      setDetailsId(
                        movieDetails?.ContentSummary?.ContentDetail[0]?.id
                      );
                    }}
                  />
                </Flex>
                <Modal
                  isCentered
                  isOpen={isOpenContentTitle}
                  onClose={onCloseContentTitle}
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Edit Content Title</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <Textarea
                        value={editedContentTitle}
                        onChange={(e) => setEditedContentTitle(e.target.value)}
                        placeholder="Enter content title..."
                      />
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        colorScheme="blue"
                        mr={3}
                        onClick={() => handleEditRequest()}
                      >
                        {editLoading ? <Spinner /> : "Save"}
                      </Button>
                      <Button onClick={onCloseContentTitle}>Cancel</Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
                <Flex align="center" gap="1rem">
                  <IoCloseCircleSharp
                    color="#979898"
                    cursor="pointer"
                    onClick={onClose}
                    size="50px"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Box>
          <Flex
            direction="column"
            align="center"
            bg="rgba(0, 2, 10, 1)"
            justify="center"
          >
            <Flex
              m="15px 0px 15px 0px"
              borderRadius="10px"
              bg="rgba(39, 39, 39, 1)"
              w="90%"
              direction="column"
              justify="space-between"
              p="20px"
              gap="25px"
            >
              <Text
                color="rgba(255, 255, 255, 1)"
                fontFamily="Roboto"
                fontSize="20px"
                fontWeight="700"
                lineHeight="21.54px"
                letterSpacing="0.2px"
              >
                About Movie
              </Text>
              <Flex w="100%" justify="space-between">
                <Flex justify="center" gap="30px" w="55%" direction="column">
                  <Flex align="center" gap="8px">
                    <Text
                      color="rgba(69, 211, 105, 1)"
                      fontFamily="Roboto"
                      fontSize="19px"
                      fontWeight="700"
                      lineHeight="21.54px"
                      letterSpacing="-0.36px"
                    >
                      {new Date(
                        movieDetails?.ContentSummary?.ContentDetail[0]?.createdAt
                      ).getFullYear()}
                    </Text>
                    <Text
                      color="rgba(186, 186, 186, 1)"
                      fontFamily="Roboto"
                      fontSize="19px"
                      fontWeight="400"
                      lineHeight="21.54px"
                      letterSpacing="-0.36px"
                    >
                      {formatDuration(
                        movieDetails?.ContentSummary?.ContentDetail[0]?.duration
                      )}
                    </Text>
                    <Flex
                      borderRadius="3px"
                      justify="center"
                      align="center"
                      w="32px"
                      h="19px"
                      border="1px solid rgba(116, 116, 116, 1)"
                      color="rgba(255, 255, 255, 1)"
                      fontFamily="Roboto"
                      fontWeight="400"
                      fontSize="12px"
                      lineHeight="21.54px"
                    >
                      HD
                    </Flex>
                  </Flex>
                  <Flex align="center" gap="5px">
                    <Flex
                      color="white"
                      w="70px"
                      h="20px"
                      justify="center"
                      align="center"
                      border="1px solid rgba(116, 116, 116, 1)"
                    >
                      {`${
                        movieDetails?.ContentSummary?.ageRating === "18"
                          ? "A"
                          : "U/A"
                      } ${movieDetails?.ContentSummary?.ageRating}+`}
                    </Flex>
                    {movieDetails?.ContentSummary?.ageRating === "18" ? (
                      <Text style={textStyle}>
                        <span style={{ textTransform: "capitalize" }}>
                          gore, language, mature themes, violence
                        </span>
                      </Text>
                    ) : (
                      <></>
                    )}
                  </Flex>
                  <Text style={textStyle}>
                    <Flex
                      fontWeight="800"
                      fontSize="20px"
                      align="center"
                      gap="8px"
                    >
                      Plot Summary{" "}
                      <FiEdit3
                        cursor="pointer"
                        onClick={() => {
                          onOpenPlotSummary();
                          setEditedPlotSummary(
                            movieDetails?.ContentSummary?.ContentDetail[0]
                              ?.plotSummary
                          );
                          setSummaryId(movieDetails?.ContentSummary?.id);
                          setDetailsId(
                            movieDetails?.ContentSummary?.ContentDetail[0]?.id
                          );
                        }}
                      />
                    </Flex>
                    {expandedSummary[
                      movieDetails?.ContentSummary?.ContentDetail[0]?.id
                    ] ? (
                      <span>
                        {
                          movieDetails?.ContentSummary?.ContentDetail[0]
                            ?.plotSummary
                        }
                        <Text
                          color="rgba(0, 129, 223, 1)"
                          cursor="pointer"
                          onClick={() =>
                            toggleSummary(
                              movieDetails?.ContentSummary?.ContentDetail[0]?.id
                            )
                          }
                        >
                          read less
                        </Text>
                      </span>
                    ) : (
                      <span>
                        {movieDetails?.ContentSummary?.ContentDetail[0]?.plotSummary
                          ?.split(" ")
                          ?.slice(0, 20)
                          ?.join(" ")}
                        {movieDetails?.ContentSummary?.ContentDetail[0]?.plotSummary?.split(
                          " "
                        ).length > 20 && (
                          <Text
                            color="rgba(0, 129, 223, 1)"
                            cursor="pointer"
                            onClick={() =>
                              toggleSummary(
                                movieDetails?.ContentSummary?.ContentDetail[0]
                                  ?.id
                              )
                            }
                          >
                            read more
                          </Text>
                        )}
                      </span>
                    )}
                  </Text>
                  <Modal
                    isCentered
                    isOpen={isOpenPlotSummary}
                    onClose={onClosePlotSummary}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Edit Plot Summary</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <Textarea
                          value={editedPlotSummary}
                          onChange={(e) => setEditedPlotSummary(e.target.value)}
                          placeholder="Enter plot summary..."
                        />
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          colorScheme="blue"
                          mr={3}
                          onClick={() => handleEditRequest()}
                        >
                          {editLoading ? <Spinner /> : "Save"}
                        </Button>
                        <Button onClick={onClosePlotSummary}>Cancel</Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </Flex>
                <Flex gap="20px" style={textStyle} w="40%" direction="column">
                  <Text>
                    <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                      Cast:
                    </span>{" "}
                    {movieDetails?.ContentSummary?.cast?.map((item) => (
                      <span
                        key={item.id}
                        style={{ textTransform: "capitalize" }}
                      >
                        {item.name},{" "}
                      </span>
                    ))}
                  </Text>
                  <Flex align="center">
                    <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                      Genres:
                    </span>
                    <span
                      style={{
                        textTransform: "capitalize",
                        marginRight: "5px",
                      }}
                    >
                      {movieDetails?.ContentSummary?.genre}
                    </span>
                    <FiEdit3
                      cursor="pointer"
                      onClick={() => {
                        onOpenGenre();
                        setEditedGenre(movieDetails?.ContentSummary?.genre);
                        setSummaryId(movieDetails?.ContentSummary?.id);
                        setDetailsId(
                          movieDetails?.ContentSummary?.ContentDetail[0]?.id
                        );
                      }}
                    />
                  </Flex>
                  <Modal isCentered isOpen={isOpenGenre} onClose={onCloseGenre}>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Edit Genre</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <Select
                          bg="white"
                          style={videoTextStyle}
                          value={editedGenre}
                          onChange={(e) => setEditedGenre([e.target.value])}
                        >
                          {options?.map((option, index) => (
                            <option key={index} value={option?.name}>
                              {option?.name}
                            </option>
                          ))}
                        </Select>
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          colorScheme="blue"
                          mr={3}
                          onClick={() => handleEditRequest()}
                        >
                          {editLoading ? <Spinner /> : "Save"}
                        </Button>
                        <Button onClick={onCloseGenre}>Cancel</Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                  <Text>
                    <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                      Content language:
                    </span>
                    <span style={{ textTransform: "capitalize" }}>
                      {` ${movieDetails?.ContentSummary?.contentLanguage}`}
                    </span>
                  </Text>
                  <Flex align="center" gap="8px">
                    <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                      Producer:
                    </span>
                    <span style={{ textTransform: "capitalize" }}>
                      {movieDetails?.ContentSummary?.producer}
                    </span>
                    <FiEdit3
                      cursor="pointer"
                      onClick={() => {
                        onOpenProducer();
                        setEditedProducer(
                          movieDetails?.ContentSummary?.producer
                        );
                        setSummaryId(movieDetails?.ContentSummary?.id);
                        setDetailsId(
                          movieDetails?.ContentSummary?.ContentDetail[0]?.id
                        );
                      }}
                    />
                  </Flex>
                  <Modal
                    isCentered
                    isOpen={isOpenProducer}
                    onClose={onCloseProducer}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Edit Producer</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <Textarea
                          value={editedProducer}
                          onChange={(e) => setEditedProducer(e.target.value)}
                          placeholder="Enter producer..."
                        />
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          colorScheme="blue"
                          mr={3}
                          onClick={() => handleEditRequest()}
                        >
                          {editLoading ? <Spinner /> : "Save"}
                        </Button>
                        <Button onClick={onCloseProducer}>Cancel</Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                  <Flex align="center" gap="8px">
                    <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                      Director:
                    </span>
                    <span style={{ textTransform: "capitalize" }}>
                      {movieDetails?.ContentSummary?.dop}
                    </span>
                    <FiEdit3
                      cursor="pointer"
                      onClick={() => {
                        onOpenDirector();
                        setEditedDirector(movieDetails?.ContentSummary?.dop);
                        setSummaryId(movieDetails?.ContentSummary?.id);
                        setDetailsId(
                          movieDetails?.ContentSummary?.ContentDetail[0]?.id
                        );
                      }}
                    />
                  </Flex>
                  <Modal
                    isCentered
                    isOpen={isOpenDirector}
                    onClose={onCloseDirector}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Edit Director</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <Textarea
                          value={editedDirector}
                          onChange={(e) => setEditedDirector(e.target.value)}
                          placeholder="Enter director..."
                        />
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          colorScheme="blue"
                          mr={3}
                          onClick={() => handleEditRequest()}
                        >
                          {editLoading ? <Spinner /> : "Save"}
                        </Button>
                        <Button onClick={onCloseDirector}>Cancel</Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </Flex>
              </Flex>
            </Flex>
            {title === "Web Series" ? (
              <Flex
                m="15px 0px 15px 0px"
                borderRadius="10px"
                bg="rgba(39, 39, 39, 1)"
                w="90%"
                direction="column"
                justify="space-between"
                p="20px"
              >
                <Flex
                  mt="1rem"
                  w="100%"
                  justify="space-between"
                  direction={{ base: "column-reverse", md: "row" }}
                >
                  <Flex w="100%" gap="20px" direction="column">
                    <Flex align="center" gap="8px">
                      <Text
                        color="rgba(255, 255, 255, 1)"
                        fontFamily="Roboto"
                        fontSize="20px"
                        fontWeight="700"
                        lineHeight="21.54px"
                        letterSpacing="0.2px"
                      >
                        Episodes
                      </Text>
                    </Flex>

                    <Flex w="100%" direction="column" gap="30px">
                      {movieDetails?.ContentSummary?.ContentDetail?.map(
                        (item, index, array) => (
                          <Flex key={item.id} gap="10px" w="100%">
                            <Flex
                              direction={{ base: "column", md: "row" }}
                              gap="10px"
                            >
                              <Flex w="180px" h="100px">
                                <Image
                                  w="100%"
                                  h="100%"
                                  objectFit="fill"
                                  src={item?.thumbnailLink}
                                />
                              </Flex>
                              <Flex direction="column" gap="10px">
                                <Flex align="center" gap="10px">
                                  <Text
                                    color="rgba(255, 255, 255, 1)"
                                    fontFamily="Roboto"
                                    fontWeight="700"
                                    fontSize="14px"
                                    lineHeight="21.54px"
                                    letterSpacing="0.14px"
                                    cursor="pointer"
                                  >{`Episode ${item?.episodeNumber}`}</Text>
                                  <Text
                                    color="rgba(186, 186, 186, 1)"
                                    fontFamily="Roboto"
                                    fontSize="15px"
                                    fontWeight="400"
                                    lineHeight="21.54px"
                                    letterSpacing="-0.36px"
                                  >
                                    {formatDuration(item?.duration)}
                                  </Text>
                                  {index === array.length - 1 && ( // Check if it's the last element
                                    <Flex justify="flex-end" w="70%">
                                      <Flex
                                        color="red"
                                        cursor="pointer"
                                        onClick={() =>
                                          handleDeleteContent(item.id)
                                        } // Pass the ID of the current item
                                      >
                                        {loadingAdminDeleteContent ? (
                                          <Spinner />
                                        ) : (
                                          "delete"
                                        )}
                                      </Flex>
                                    </Flex>
                                  )}
                                </Flex>
                                <Flex w={{ base: "100%", md: "500px" }}>
                                  <Text
                                    fontFamily="Roboto"
                                    fontSize="12px"
                                    fontWeight="400"
                                    lineHeight="28px"
                                    color="rgba(255, 255, 255, 1)"
                                    w="100%"
                                  >
                                    {expandedSummary[item.id] ? (
                                      <span>
                                        {item.plotSummary}
                                        <Text
                                          color="rgba(0, 129, 223, 1)"
                                          cursor="pointer"
                                          onClick={() => toggleSummary(item.id)}
                                        >
                                          read less
                                        </Text>
                                      </span>
                                    ) : (
                                      <span>
                                        {item?.plotSummary
                                          ?.split(" ")
                                          ?.slice(0, 20)
                                          ?.join(" ")}
                                        {item.plotSummary?.split(" ").length >
                                          20 && (
                                          <Text
                                            color="rgba(0, 129, 223, 1)"
                                            cursor="pointer"
                                            onClick={() =>
                                              toggleSummary(item.id)
                                            }
                                          >
                                            read more
                                          </Text>
                                        )}
                                      </span>
                                    )}
                                  </Text>
                                </Flex>
                              </Flex>
                            </Flex>
                          </Flex>
                        )
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            ) : (
              <></>
            )}
            <Flex direction="column" align="left" justify="center" w="80%">
              {title === "Web Series" ? (
                <Flex
                  p="20px"
                  gap="10px"
                  justify="space-between"
                  align="center"
                  style={videoBigTextStyle}
                >
                  <Text>Add more episodes to this series</Text>
                  <WebSeriesModal
                    contentDetailId={movieDetails?.ContentSummary?.id}
                  />
                </Flex>
              ) : (
                <></>
              )}

              {adminStatusCheck === "SUPER_LEVEL_ADMIN" &&
              title !== "Web Series" ? (
                <Flex
                  p="20px"
                  gap="10px"
                  justify="space-between"
                  align="center"
                  style={videoBigTextStyle}
                >
                  <Text>Delete this content</Text>
                  <Button
                    bg="red"
                    style={sellbutton}
                    w="100px"
                    _hover={{ bg: "red" }}
                    onClick={() =>
                      handleDeleteContent(
                        movieDetails?.ContentSummary?.ContentDetail[0]?.id
                      )
                    }
                  >
                    {loadingAdminDeleteContent ? <Spinner /> : "Delete"}
                  </Button>
                </Flex>
              ) : (
                <></>
              )}

              <Flex
                p="20px"
                gap="10px"
                justify="space-between"
                align="center"
                style={videoBigTextStyle}
              >
                <Text>Move this content to top</Text>
                <Button
                  bg="green"
                  style={sellbutton}
                  w="100px"
                  _hover={{ bg: "green" }}
                  onClick={() =>
                    handleContentMove(movieDetails?.ContentSummary?.id)
                  }
                >
                  {loadingContentMoveToTop ? <Spinner /> : "Move"}
                </Button>
              </Flex>

              <Flex p="20px" justify="space-between" align="end">
                <FormControl w="70%">
                  <Flex align="center">
                    <FormLabel style={videoBigTextStyle}>
                      Upload trailer
                    </FormLabel>
                    {trailerUploadStatus === "success" ? (
                      <IoCheckmarkDoneSharp color="white" />
                    ) : trailerUploadStatus === "error" ? (
                      <MdErrorOutline color="white" />
                    ) : (
                      ""
                    )}
                  </Flex>

                  <InputGroup align="center">
                    <Button
                      border="1px solid #3D445A"
                      bg="transparent"
                      _hover={{ bg: "transparent" }}
                      onClick={() => {
                        // Trigger the hidden file input when the button is clicked
                        document.getElementById("trailerInput").click();
                      }}
                      cursor="pointer"
                      position="relative"
                      w="100%"
                    >
                      <MdOutlineFileUpload
                        size="25px"
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                        color="grey"
                      />
                    </Button>
                    {/* Hidden file input */}
                    <Input
                      type="file"
                      id="trailerInput"
                      name="trailerStoredAt"
                      onChange={handleImageUpload}
                      display="none"
                    />
                  </InputGroup>
                </FormControl>
                <Button
                  bg="blue.500"
                  _hover={{ bg: "blue.500" }}
                  type="submit"
                  style={sellbutton}
                  onClick={() => _UploadVideo()}
                  w="100px"
                >
                  {trailerLoading || videoLoading ? <Spinner /> : "Upload"}
                </Button>
              </Flex>

              <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                <Flex p="20px" justify="space-between" align="end">
                  <FormControl w="70%">
                    <FormLabel style={videoBigTextStyle}>
                      Choose video tray
                    </FormLabel>
                    <Select
                      style={videoTextStyle}
                      value={selectedOption}
                      color="white"
                      onChange={handleSelectChange}
                      placeholder="Choose video section"
                    >
                      {_AllCategory?.data?.AllCategory?.map((option, index) => (
                        <option key={option.id} value={option?.id}>
                          {option?.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <Button
                    bg="blue.500"
                    _hover={{ bg: "blue.500" }}
                    type="submit"
                    style={sellbutton}
                    onClick={handleVideoTrayChange}
                    w="100px"
                  >
                    {loadingVideoChanges ? <Spinner /> : "Save"}
                  </Button>
                </Flex>
              </form>

              <Flex p="20px" justify="space-between" align="end">
                <FormControl w="70%">
                  <FormLabel style={videoBigTextStyle}>
                    Change video status
                  </FormLabel>
                  <Select
                    style={videoTextStyle}
                    value={videoStatus}
                    color="white"
                    onChange={(e) => setVideoStatus(e.target.value)}
                    placeholder="Choose video status"
                  >
                    <option value="FREE">Free</option>
                    <option value="PREMIUM">Premium</option>
                  </Select>
                </FormControl>

                <Button
                  bg="blue.500"
                  _hover={{ bg: "blue.500" }}
                  type="submit"
                  style={sellbutton}
                  onClick={() =>
                    handlePremiumStatusUpdate(
                      movieDetails?.ContentSummary?.ContentDetail?.[0]?.id
                    )
                  }
                  w="100px"
                >
                  {loadingUpdatePremium ? <Spinner /> : "Save"}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

/*
 * EXPORT
 */
export default MovieDetail;
