export const tabStyle = {
  fontFamily: "Poppins",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "55.62px",
  letterSpacing: "0.32px",
};
export const videoSmallTextStyle = {
  color: "#FFFFFF",
  fontFamily: "Poppins",
  fontWeight: "400",
  fontSize: "15px",
  lineHeight: "22px",
};
export const videoBigTextStyle = {
  color: "#FFFFFF",
  fontFamily: "Poppins",
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "30px",
  letterSpacing: "0.2px",
};
export const videoBigTextStyle1 = {
  color: "rgba(116, 116, 116, 1)",
  fontFamily: "Poppins",
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "30px",
  letterSpacing: "0.2px",
};
export const textStyle = {
  color: "rgba(255, 255, 255, 1)",
  fontFamily: "Roboto",
  fontWeight: "400",
  fontSize: "15px",
  lineHeight: "21.54px",
};
export const videoTextStyle = {
  color: "#7D798E",
  fontFamily: "Poppins",
  fontWeight: "500",
  fontSize: "15px",
  lineHeight: "17.04px",
  borderColor: "blue.500",
};
export const sellbutton = {
  borderRadius: "8px",
  fontFamily: "Poppins",
  fontSize: "20px",
  fontWeight: "500",
  lineHeight: "31.74px",
  letterSpacing: "-0.2px",
  color: "#D9D9D9",
  cursor: "pointer",
};
export const labelStyle = {
  fontFamily: "Roboto",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "14px",
  color: "#B5C9DB",
};
export const inputStyle = {
  // Width: "410px",
  height: "50px",
  border: "1px solid #3D445A",
  borderRadius: "16px",
  fontFamily: "Roboto",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "14px",
  letterSpacing: "-0.28px",
  color: "#878992",
};
export const inputStyle1 = {
  // Width: "852px",
  height: "50px",
  border: "1px solid #3D445A",
  borderRadius: "16px",
  fontFamily: "Roboto",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "14px",
  letterSpacing: "-0.28px",
  color: "#878992",
};
export const cardTextStyle = {
  fontFamily: "Montserrat",
  fontSize: "20px",
  fontWeight: "700",
  lineHeight: "48px",
  letterSpacing: "0.03em",
  color: "rgba(255, 255, 255, 1)",
};
export const cardNumberStyle = {
  fontFamily: "Montserrat",
  fontSize: "20px",
  fontWeight: "700",
  lineHeight: "48px",
  letterSpacing: "0.03em",
  color: "rgba(255, 255, 255, 1)",
};
