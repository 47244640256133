
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
/*
 * IMPORTS
 */
import { useState, useEffect } from "react";
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";
import _ from "underscore";
import { useHistory } from "react-router-dom";
import {
  cardTextStyle,
  cardNumberStyle,
} from "../../../../components/Styles/index.style";

/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  Flex,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  InputGroup,
  FormLabel,
  FormControl,
  Grid,
  useToast,
  Spinner,
} from "@chakra-ui/react";

/*
 * QUERY
 */
import DashBoardBrandName from "./__query__/index.fetchBrand.query";

/*
 * MUTATAION
 */
import BrandNameCreate from "./__mutation__/index.brandCreate.mutation";
import { useMutation, useQuery } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import { connect } from "react-redux"; // NPM: React-redux library.

/*
 * OBJECT
 */
function Index({ account }) {
  /*
   * STATE
   */
  const history = useHistory();
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [BrandNameCreateMutation, { loading: loadingBrandCreate }] =
    useMutation(BrandNameCreate, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });



  const _DashBoardBrandName = useQuery(DashBoardBrandName, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  /*
   * STYLE
   */

  /*
   * FUNCTION
   */

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleBrandCreate = async () => {
    try {
      const response = await BrandNameCreateMutation({
        variables: {
          brandName: inputValue,
        },
      });

      const data = response.data.BrandNameCreate;

      if (!_.isEmpty(data)) {
        toast({
          title: "Form Submitted",
          description: "Data saved successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setInputValue("");
        closeModal();
      }
    } catch (error) {
      // Show error toast
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    _DashBoardBrandName?.refetch();
  }, [loadingBrandCreate]);

  return (
    <>
      <Box bg="#00020A">
        <Navbar />
        <Flex
          h="80vh"
          align="center"
          bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
          direction="column"
          w="100%"
          p="40px"
        >
          <Flex
            borderRadius="20px"
            w="80%"
            h="100%"
            p="40px"
            gap="50px"
            justify="space-evenly"
            direction="column"
          >
            <Button onClick={openModal}>Add new section</Button>

            <Modal isCentered isOpen={isOpen} onClose={closeModal}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Create A New Brand Section</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>Brand Name</FormLabel>
                    <InputGroup>
                      <Input
                        placeholder="Section Name"
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </FormControl>
                </ModalBody>

                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={handleBrandCreate}>
                    {loadingBrandCreate ? <Spinner /> : "Create"}
                  </Button>
                  <Button variant="ghost" onClick={closeModal}>
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
          <Flex align="center" w="100%" direction="column">
            <Grid justify="center" gap="10rem" templateColumns="repeat(3, 1fr)">
              {_DashBoardBrandName?.data?.DashBoardBrandName?.map(
                (item, index) => (
                  <Flex
                    key={item.id}
                    w="300px"
                    h="200px"
                    bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
                    border="1px solid purple"
                    borderRadius="20px"
                    boxShadow="0px 0px 5px 0px white" // Add box shadow
                    transition="box-shadow 0.3s, transform 0.3s" // Add transition for smooth effect
                    _hover={{
                      boxShadow: "0px 0px 10px 5px white", // Change box shadow on hover
                      transform: "rotate(5deg)", // Apply tilt effect on hover
                      cursor: "pointer", // Change cursor on hover
                    }}
                    onClick={() => history.push(`/ads?id=${item.id}&name=${item.brandName}&duration=${item.duration}`)}
                  >
                    <Flex
                      w="100%"
                      align="center"
                      justify="center"
                      direction="column"
                    >
                      <Text style={cardNumberStyle}>{index + 1}</Text>
                      <Text style={cardTextStyle}>{item?.brandName}</Text>
                    </Flex>
                  </Flex>
                )
              )}
            </Grid>
          </Flex>
        </Flex>
        <Footer />
      </Box>
    </>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
  LogoUpdate: (u) => __dispatch({ type: "LOGO_INIT", Logo: u }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
  logo: state.Logo,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
