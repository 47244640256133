/* eslint-disable capitalized-comments */
/*
 * IMPORT
 */
import React from "react"; // Npm: React.
import ReactDOM from "react-dom"; // Npm: React dom.
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from "@apollo/client"; // Npm: Apollo client for handling graphql.
import { createUploadLink } from "apollo-upload-client"; // Npm: Apollo client upload link.
import App from "index.app.js"; // App component.
import { Provider } from "react-redux"; // Npm: Redux provider.
import { PersistGate } from "redux-persist/integration/react"; // Npm: Redux persist gate.
import { ChakraProvider } from "@chakra-ui/react"; // Npm: Chakra UI provider.

/*
 * PACKAGES
 */
import Redux, { Persist } from "./Redux";

/*
 * STYLES
 */
import theme from "theme/theme";
import "./Assets/css/App.css";

/*
 * APOLLO CLIENT PROVIDER
 */
const ApolloClientProvider = (initialState) => {
  const _NodeBackendHttpLink = ApolloLink.from([
    createUploadLink({
      uri: process.env.REACT_APP_NODE_BACKEND,
    }),
  ]);

  const _ApolloClient = new ApolloClient({
    connectToDevTools: false,
    ssrMode: true,
    link: ApolloLink.from([_NodeBackendHttpLink]),
    cache: new InMemoryCache().restore(initialState || {}),
    defaultOptions: {
      query: {
        context: {
          clientName: "default",
        },
      },

      mutate: {
        context: {
          clientName: "default",
        },
      },
      watchQuery: {
        context: {
          clientName: "default",
        },
      },
    },
  });

  return _ApolloClient;
};

/*
 * RENDER
 */
const Root = () => (
  <Provider store={Redux}>
    <PersistGate persistor={Persist}>
      <ChakraProvider theme={theme}>
        <ApolloProvider client={ApolloClientProvider()}>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </ApolloProvider>
      </ChakraProvider>
    </PersistGate>
  </Provider>
);

ReactDOM.render(<Root />, document.getElementById("root"));
