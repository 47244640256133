/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
/*
 * IMPORTS
 */
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";
import ShucaeContent from "components/ShucaeContent";
import { useHistory } from "react-router-dom";
import {
  cardTextStyle,
  cardNumberStyle,
} from "../../../../components/Styles/index.style";

/*
 * CHAKRA IMPORTS
 */
import { Box, Flex, Text, Spinner, useDisclosure } from "@chakra-ui/react";

/*
 * QUERY
 */
import DashBoardNumbers from "../__query__/index.dashboardData.query";
import SuperAdminStatusCheck from "../__query__/index.adminStatusCheck.query";
import { useQuery } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import { connect } from "react-redux"; // NPM: React-redux library.
import ShucaeContentWebseries from "components/ShucaeContentWebseries";
import { useEffect } from "react";

function Index({ account }) {
  /*
   * STATE
   */
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenWebseries,
    onOpen: onOpenWebseries,
    onClose: onCloseWebseries,
  } = useDisclosure();

  function handleOpen() {
    onOpen();
  }

  function handleOpenWebseries() {
    onOpenWebseries();
  }

  const _DashBoardNumbers = useQuery(DashBoardNumbers, {
    context: { headers: { "l-authorization": account.token } },
  });

  const _SuperAdminStatusCheck = useQuery(SuperAdminStatusCheck, {
    context: { headers: { "l-authorization": account.token } },
  });

  /*
   * STYLE
   */

  /*
   * FUNCTION
   */

  /*
   * USEEFFECT
   */
  useEffect(() => {
    _SuperAdminStatusCheck.refetch();
  }, []);

  return (
    <>
      <Box bg="#00020A">
        <Navbar />
        {_SuperAdminStatusCheck?.data?.SuperAdminStatusCheck?.status ===
        "ZERO_LEVEL_ADMIN" ? (
          <Flex bg="#00020A" w="100%" h="100vh" justify="center" align="center">
            <Flex
              w="300px"
              h="200px"
              bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
              border="1px solid purple"
              borderRadius="20px"
              boxShadow="0px 0px 10px 0px white" // Add box shadow
              transition="box-shadow 0.3s, transform 0.3s" // Add transition for smooth effect
              _hover={{
                boxShadow: "0px 0px 100px 5px white", // Change box shadow on hover
                transform: "rotate(5deg)", // Apply tilt effect on hover
                cursor: "pointer", // Change cursor on hover
              }}
              onClick={() => history.push("/customer-associate")}
            >
              <Flex w="100%" align="center" justify="center" direction="column">
                <Text style={cardNumberStyle}>
                  {_DashBoardNumbers.loading ? (
                    <Spinner />
                  ) : (
                    `${_DashBoardNumbers?.data?.DashBoardNumbers?.totalAds}`
                  )}
                </Text>
                <Text style={cardTextStyle}>Customer Associate</Text>
              </Flex>
            </Flex>
          </Flex>
        ) : (
          <Flex bg="#00020A" direction="column" w="100%" mb="15rem">
            <Flex mt="15rem" justify="space-evenly">
              {/* Number's of Creator's */}
              <Flex
                w="300px"
                h="200px"
                bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
                border="1px solid purple"
                borderRadius="20px"
                boxShadow="0px 0px 10px 0px white" // Add box shadow
                transition="box-shadow 0.3s, transform 0.3s" // Add transition for smooth effect
                _hover={{
                  boxShadow: "0px 0px 100px 0px white", // Change box shadow on hover
                  transform: "rotate(5deg)", // Apply tilt effect on hover
                  cursor: "pointer", // Change cursor on hover
                }}
                onClick={() => history.push("/creators")}
              >
                <Flex
                  w="100%"
                  align="center"
                  justify="center"
                  direction="column"
                >
                  <Text style={cardNumberStyle}>
                    {_DashBoardNumbers.loading ? (
                      <Spinner />
                    ) : (
                      `${_DashBoardNumbers?.data?.DashBoardNumbers?.TotalCreator}`
                    )}
                  </Text>
                  <Text style={cardTextStyle}>Number Of Creator's</Text>
                </Flex>
              </Flex>

              {/* Approval Request's */}
              <Flex
                w="300px"
                h="200px"
                bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
                border="1px solid purple"
                borderRadius="20px"
                boxShadow="0px 0px 10px 0px white" // Add box shadow
                transition="box-shadow 0.3s, transform 0.3s" // Add transition for smooth effect
                _hover={{
                  boxShadow: "0px 0px 100px 0px white", // Change box shadow on hover
                  transform: "rotate(5deg)", // Apply tilt effect on hover
                  cursor: "pointer", // Change cursor on hover
                }}
                onClick={() => history.push("/request-page")}
              >
                <Flex
                  w="100%"
                  align="center"
                  justify="center"
                  direction="column"
                >
                  <Text style={cardNumberStyle}>
                    {_DashBoardNumbers.loading ? (
                      <Spinner />
                    ) : (
                      `${_DashBoardNumbers?.data?.DashBoardNumbers?.unApprovedvideos}`
                    )}
                  </Text>
                  <Text style={cardTextStyle}>Approval Request's</Text>
                </Flex>
              </Flex>

              {/* Manage Home Page */}
              <Flex
                w="300px"
                h="200px"
                bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
                border="1px solid purple"
                borderRadius="20px"
                boxShadow="0px 0px 10px 0px white" // Add box shadow
                transition="box-shadow 0.3s, transform 0.3s" // Add transition for smooth effect
                _hover={{
                  boxShadow: "0px 0px 100px 0px white", // Change box shadow on hover
                  transform: "rotate(5deg)", // Apply tilt effect on hover
                  cursor: "pointer", // Change cursor on hover
                }}
                onClick={() => history.push("/manage-home-page")}
              >
                <Flex
                  w="100%"
                  align="center"
                  justify="center"
                  direction="column"
                >
                  {/* <Text style={cardNumberStyle}>100</Text> */}
                  <Text style={cardTextStyle}>Manage Home Page</Text>
                </Flex>
              </Flex>
            </Flex>

            <Flex mt="5rem" justify="space-evenly">
              {/* Approved Video's */}
              <Flex
                w="300px"
                h="200px"
                bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
                border="1px solid purple"
                borderRadius="20px"
                boxShadow="0px 0px 10px 0px white" // Add box shadow
                transition="box-shadow 0.3s, transform 0.3s" // Add transition for smooth effect
                _hover={{
                  boxShadow: "0px 0px 100px 0px white", // Change box shadow on hover
                  transform: "rotate(5deg)", // Apply tilt effect on hover
                  cursor: "pointer", // Change cursor on hover
                }}
                onClick={() => history.push("/approved-videos")}
              >
                <Flex
                  w="100%"
                  align="center"
                  justify="center"
                  direction="column"
                >
                  <Text style={cardNumberStyle}>
                    {_DashBoardNumbers.loading ? (
                      <Spinner />
                    ) : (
                      `${_DashBoardNumbers?.data?.DashBoardNumbers?.approvedVideos}`
                    )}
                  </Text>
                  <Text style={cardTextStyle}>Approved Video's</Text>
                </Flex>
              </Flex>

              {/* Total Video's */}
              <Flex
                w="300px"
                h="200px"
                bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
                border="1px solid purple"
                borderRadius="20px"
                boxShadow="0px 0px 10px 0px white" // Add box shadow
                transition="box-shadow 0.3s, transform 0.3s" // Add transition for smooth effect
                _hover={{
                  boxShadow: "0px 0px 100px 0px white", // Change box shadow on hover
                  transform: "rotate(5deg)", // Apply tilt effect on hover
                  cursor: "pointer", // Change cursor on hover
                }}
                onClick={() => history.push("/total-videos")}
              >
                <Flex
                  w="100%"
                  align="center"
                  justify="center"
                  direction="column"
                >
                  <Text style={cardNumberStyle}>
                    {_DashBoardNumbers.loading ? (
                      <Spinner />
                    ) : (
                      `${_DashBoardNumbers?.data?.DashBoardNumbers?.totalVideos}`
                    )}
                  </Text>
                  <Text style={cardTextStyle}>Total Videos</Text>
                </Flex>
              </Flex>

              {/* Manage Ad's */}
              <Flex
                w="300px"
                h="200px"
                bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
                border="1px solid purple"
                borderRadius="20px"
                boxShadow="0px 0px 10px 0px white" // Add box shadow
                transition="box-shadow 0.3s, transform 0.3s" // Add transition for smooth effect
                _hover={{
                  boxShadow: "0px 0px 100px 5px white", // Change box shadow on hover
                  transform: "rotate(5deg)", // Apply tilt effect on hover
                  cursor: "pointer", // Change cursor on hover
                }}
                onClick={() => history.push("/manage-ads")}
              >
                <Flex
                  w="100%"
                  align="center"
                  justify="center"
                  direction="column"
                >
                  <Text style={cardNumberStyle}>
                    {_DashBoardNumbers.loading ? (
                      <Spinner />
                    ) : (
                      `${_DashBoardNumbers?.data?.DashBoardNumbers?.totalAds}`
                    )}
                  </Text>
                  <Text style={cardTextStyle}>Manage Ads</Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex mt="5rem" justify="space-evenly">
              {/* Mange Authorization */}
              {_SuperAdminStatusCheck?.data?.SuperAdminStatusCheck?.status ===
              "SUPER_LEVEL_ADMIN" ? (
                <Flex
                  w="300px"
                  h="200px"
                  bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
                  border="1px solid purple"
                  borderRadius="20px"
                  boxShadow="0px 0px 10px 0px white" // Add box shadow
                  transition="box-shadow 0.3s, transform 0.3s" // Add transition for smooth effect
                  _hover={{
                    boxShadow: "0px 0px 100px 0px white", // Change box shadow on hover
                    transform: "rotate(5deg)", // Apply tilt effect on hover
                    cursor: "pointer", // Change cursor on hover
                  }}
                  onClick={() => history.push("/manage-authorization")}
                >
                  <Flex
                    w="100%"
                    align="center"
                    justify="center"
                    direction="column"
                  >
                    {/* <Text style={cardNumberStyle}>150</Text> */}
                    <Text textAlign="center" style={cardTextStyle}>
                      Manage Authorization
                    </Text>
                  </Flex>
                </Flex>
              ) : (
                <></>
              )}

              {/* Buy and Sell */}
              {_SuperAdminStatusCheck?.data?.SuperAdminStatusCheck?.status ===
              "SUPER_LEVEL_ADMIN" ? (
                <Flex
                  w="300px"
                  h="200px"
                  bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
                  border="1px solid purple"
                  borderRadius="20px"
                  boxShadow="0px 0px 10px 0px white" // Add box shadow
                  transition="box-shadow 0.3s, transform 0.3s" // Add transition for smooth effect
                  _hover={{
                    boxShadow: "0px 0px 100px 0px white", // Change box shadow on hover
                    transform: "rotate(5deg)", // Apply tilt effect on hover
                    cursor: "pointer", // Change cursor on hover
                  }}
                  onClick={() => history.push("/buy-sell")}
                >
                  <Flex
                    w="100%"
                    align="center"
                    justify="center"
                    direction="column"
                  >
                    {/* <Text style={cardNumberStyle}>150</Text> */}
                    <Text textAlign="center" style={cardTextStyle}>
                      Buy And Sell
                    </Text>
                  </Flex>
                </Flex>
              ) : (
                <></>
              )}

              {/* Track Admin Activity */}
              {/* {adminStatusCheck === "SUPER_LEVEL_ADMIN" ? (
              <Flex
                w="300px"
                h="200px"
                bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
                border="1px solid purple"
                borderRadius="20px"
                boxShadow="0px 0px 10px 0px white" // Add box shadow
                transition="box-shadow 0.3s, transform 0.3s" // Add transition for smooth effect
                _hover={{
                  boxShadow: "0px 0px 100px 0px white", // Change box shadow on hover
                  transform: "rotate(5deg)", // Apply tilt effect on hover
                  cursor: "pointer", // Change cursor on hover
                }}
              >
                <Flex
                  w="100%"
                  align="center"
                  justify="center"
                  direction="column"
                >
                  <Text textAlign="center" style={cardTextStyle}>
                    Track Admin Activity
                  </Text>
                </Flex>
              </Flex>
            ) : (
              <></>
            )} */}

              {/* Subscription Activity */}
              {_SuperAdminStatusCheck?.data?.SuperAdminStatusCheck?.status ===
              "SUPER_LEVEL_ADMIN" ? (
                <Flex
                  w="300px"
                  h="200px"
                  bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
                  border="1px solid purple"
                  borderRadius="20px"
                  boxShadow="0px 0px 10px 0px white" // Add box shadow
                  transition="box-shadow 0.3s, transform 0.3s" // Add transition for smooth effect
                  _hover={{
                    boxShadow: "0px 0px 100px 0px white", // Change box shadow on hover
                    transform: "rotate(5deg)", // Apply tilt effect on hover
                    cursor: "pointer", // Change cursor on hover
                  }}
                  onClick={() => history.push("/subscription-activity")}
                >
                  <Flex
                    w="100%"
                    align="center"
                    justify="center"
                    direction="column"
                  >
                    <Text textAlign="center" style={cardTextStyle}>
                      Subscription Activity
                    </Text>
                  </Flex>
                </Flex>
              ) : (
                <></>
              )}
            </Flex>
            <Flex mt="5rem" justify="space-evenly">
              {/* Shucae Films Content */}
              <Flex
                w="300px"
                h="200px"
                bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
                border="1px solid purple"
                borderRadius="20px"
                boxShadow="0px 0px 10px 0px white" // Add box shadow
                transition="box-shadow 0.3s, transform 0.3s" // Add transition for smooth effect
                _hover={{
                  boxShadow: "0px 0px 100px 0px white", // Change box shadow on hover
                  transform: "rotate(5deg)", // Apply tilt effect on hover
                  cursor: "pointer", // Change cursor on hover
                }}
                onClick={handleOpen}
              >
                <Flex
                  w="100%"
                  align="center"
                  justify="center"
                  direction="column"
                >
                  <Text style={cardTextStyle}>Shucae Films Content</Text>
                </Flex>
              </Flex>

              {/* Total User's */}
              {_SuperAdminStatusCheck?.data?.SuperAdminStatusCheck?.status ===
              "SUPER_LEVEL_ADMIN" ? (
                <Flex
                  w="300px"
                  h="200px"
                  bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
                  border="1px solid purple"
                  borderRadius="20px"
                  boxShadow="0px 0px 10px 0px white" // Add box shadow
                  transition="box-shadow 0.3s, transform 0.3s" // Add transition for smooth effect
                  _hover={{
                    boxShadow: "0px 0px 100px 0px white", // Change box shadow on hover
                    transform: "rotate(5deg)", // Apply tilt effect on hover
                    cursor: "pointer", // Change cursor on hover
                  }}
                  onClick={() => history.push("/total-user")}
                >
                  <Flex
                    w="100%"
                    align="center"
                    justify="center"
                    direction="column"
                  >
                    {/* <Text style={cardNumberStyle}>150</Text> */}
                    <Text style={cardNumberStyle}>
                      {_DashBoardNumbers.loading ? (
                        <Spinner />
                      ) : (
                        `${_DashBoardNumbers?.data?.DashBoardNumbers?.totalAccountsNumber}`
                      )}
                    </Text>
                    <Text textAlign="center" style={cardTextStyle}>
                      Total Users
                    </Text>
                  </Flex>
                </Flex>
              ) : (
                <></>
              )}

              {/* Shucae Films Webseries */}
              <Flex
                w="300px"
                h="200px"
                bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
                border="1px solid purple"
                borderRadius="20px"
                boxShadow="0px 0px 10px 0px white" // Add box shadow
                transition="box-shadow 0.3s, transform 0.3s" // Add transition for smooth effect
                _hover={{
                  boxShadow: "0px 0px 100px 0px white", // Change box shadow on hover
                  transform: "rotate(5deg)", // Apply tilt effect on hover
                  cursor: "pointer", // Change cursor on hover
                }}
                onClick={handleOpenWebseries}
              >
                <Flex
                  w="100%"
                  align="center"
                  justify="center"
                  direction="column"
                >
                  <Text style={cardTextStyle}>Shucae Films Webseries</Text>
                </Flex>
              </Flex>
            </Flex>

            <Flex mt="5rem" justify="space-evenly">
              {/* Blogs */}
              <Flex
                w="300px"
                h="200px"
                bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
                border="1px solid purple"
                borderRadius="20px"
                boxShadow="0px 0px 10px 0px white" // Add box shadow
                transition="box-shadow 0.3s, transform 0.3s" // Add transition for smooth effect
                _hover={{
                  boxShadow: "0px 0px 100px 0px white", // Change box shadow on hover
                  transform: "rotate(5deg)", // Apply tilt effect on hover
                  cursor: "pointer", // Change cursor on hover
                }}
                onClick={() => history.push("/blogs")}
              >
                <Flex
                  w="100%"
                  align="center"
                  justify="center"
                  direction="column"
                >
                  <Text style={cardTextStyle}>Blog's</Text>
                </Flex>
              </Flex>

              {/* PodCast */}
              <Flex
                w="300px"
                h="200px"
                bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
                border="1px solid purple"
                borderRadius="20px"
                boxShadow="0px 0px 10px 0px white" // Add box shadow
                transition="box-shadow 0.3s, transform 0.3s" // Add transition for smooth effect
                _hover={{
                  boxShadow: "0px 0px 100px 0px white", // Change box shadow on hover
                  transform: "rotate(5deg)", // Apply tilt effect on hover
                  cursor: "pointer", // Change cursor on hover
                }}
                onClick={() => history.push("/pod-cast")}
              >
                <Flex
                  w="100%"
                  align="center"
                  justify="center"
                  direction="column"
                >
                  {/* <Text style={cardNumberStyle}>
                  {loading ? (
                    <Spinner />
                  ) : (
                    `${dashboardData?.totalDeveloperAccounts}`
                  )}
                </Text> */}
                  <Text style={cardTextStyle}>Podcast</Text>
                </Flex>
              </Flex>

              {/* Developer Token Approval */}
              <Flex
                w="300px"
                h="200px"
                bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
                border="1px solid purple"
                borderRadius="20px"
                boxShadow="0px 0px 10px 0px white" // Add box shadow
                transition="box-shadow 0.3s, transform 0.3s" // Add transition for smooth effect
                _hover={{
                  boxShadow: "0px 0px 100px 0px white", // Change box shadow on hover
                  transform: "rotate(5deg)", // Apply tilt effect on hover
                  cursor: "pointer", // Change cursor on hover
                }}
                onClick={() => history.push("/dev-token-approval")}
              >
                <Flex
                  w="100%"
                  align="center"
                  justify="center"
                  direction="column"
                >
                  <Text style={cardNumberStyle}>
                    {_DashBoardNumbers.loading ? (
                      <Spinner />
                    ) : (
                      `${_DashBoardNumbers?.data?.DashBoardNumbers?.totalDeveloperAccounts}`
                    )}
                  </Text>
                  <Text style={cardTextStyle}>Developer Token Request</Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )}

        <Footer />
      </Box>
      {_SuperAdminStatusCheck?.data?.SuperAdminStatusCheck?.status !==
        "ZERO_LEVEL_ADMIN" && (
        <>
          <ShucaeContent isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
          <ShucaeContentWebseries
            isOpen={isOpenWebseries}
            onOpen={onOpenWebseries}
            onClose={onCloseWebseries}
          />
        </>
      )}
    </>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
  LogoUpdate: (u) => __dispatch({ type: "LOGO_INIT", Logo: u }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
  logo: state.Logo,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
