/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation($accountId: ID! , $time: Int!) {
    FreeSubscriptionExtension(accountId: $accountId , time: $time) {
      status
      message
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
