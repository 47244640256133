/* eslint-disable react/prop-types */
import React from "react";
import { Flex, Badge } from "@chakra-ui/react";
import ReactApexChart from "react-apexcharts";

function ChartTabPanelCreator({ totalFollower, totalFollowing, totalVideos }) {
  const data = [
    {
      name: "Total Followers",
      value: totalFollower,
      color: "#FF6384", // Red color
    },
    {
      name: "Total Following",
      value: totalFollowing,
      color: "#FFCE56", // Yellow color
    },
    {
      name: "Total Videos",
      value: totalVideos,
      color: "#0c74d4", // Blue color
    },
  ];

  const options = {
    chart: {
      type: "donut",
    },
    legend: {
      show: true,
      labels: {
        colors: "white", // Set legend item colors here
      },
    },
    labels: data.map((item) => item.name),
    colors: data.map((item) => item.color),
  };

  const series = data.map((item) => item.value ?? 0);


  return (
    <Flex
      w="100%"
      h="500px"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <div style={{ width: "500px", height: "400px" }}>
        <ReactApexChart
          options={options}
          series={series}
          type="donut"
          width="100%"
        />
      </div>

      <Flex w="100%" justify="center" align="center" mt={4}>
        {data.map((item, index) => (
          <Badge key={index} colorScheme="teal" mr={2}>
            {item.name}: {item.value}
          </Badge>
        ))}
      </Flex>
    </Flex>
  );
}

export default ChartTabPanelCreator;
