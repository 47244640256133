/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/react-in-jsx-scope */
/*
 * IMPORT
 */
import { NavLink } from "react-router-dom";
import logo0 from "../../Assets/images/shucae/logo0.svg";
import facebook from "../../Assets/images/shucae/facebook.svg";
import insta from "../../Assets/images/shucae/insta.svg";
import MadeInIndia from "../../Assets/images/landingPage/madeInIndia.svg";

/*
 * CHAKRA UI IMPORT
 */
import { Text, Flex, Image } from "@chakra-ui/react";

export default function Footer() {
  /*
   * STATE
   */

  return (
    <Flex
      fontFamily="Montserrat"
      color="#828797"
      flexDirection="column"
      fontSize={["clamp(12px, 1.5vw, 15px)"]}
      fontWeight="500"
      bg="#00020A"
      w="100vw"
    >
      {/* Hl:  ---------------------------- bottom footer -------------------------------- */}
      <Flex pb="20px" direction="column" width="100%">
        <Flex
          pt={{ base: "10px", md: "50px" }}
          pr={{ base: "10px", md: "50px" }}
          pl={{ base: "10px", md: "50px" }}
          justify="space-between"
          fontFamily="Plus Jakarta Sans"
          fontWeight="500"
          fontSize="14px"
          lineHeight="24px"
          align="top"
          color="rgba(163, 164, 169, 1)"
        >
          <Flex gap="1rem" w={{ base: "7.5rem", md: "30%" }} direction="column">
            <Flex gap=".3rem" align="center">
              <NavLink to="/">
                <img src={logo0} />
              </NavLink>
              <NavLink to="/">
                <Text
                  fontFamily="Yeseva One"
                  fontWeight="400"
                  lineHeight={{ base: "18.534px", md: "27.72px" }}
                  fontSize={{ base: "12px", md: "24px" }}
                  color="rgba(255, 255, 255, 1)"
                >
                  Shucae Films
                </Text>
              </NavLink>
            </Flex>
            <Text
              fontWeight="500"
              fontSize={{ base: "5px", md: "14px" }}
              marginTop={{ base: "0.2rem", md: "0.45rem" }}
            >
              Shucae Films Pvt. Ltd. , Banyorka (Ropri),Sarka ghat, Himachal
              Pradesh, India, 175024.
            </Text>
          </Flex>
          <Flex
            gap=".5rem"
            width={{ base: "60px", md: "118px" }}
            direction="column"
            // Mt={{ base: "0", md: "3.3rem", lg: "0" }}
          >
            <Text
              mb="1rem"
              fontSize={{ base: "7px", md: "12px" }}
              fontWeight="500"
            >
              Contact Us
            </Text>
            {/* <Text>+911204902825</Text>
            <Text>+919818204406</Text>
            <Text>+919205022725</Text> */}
            <Text
              w={{ base: null, md: "190px" }}
              fontWeight={{ base: "500", md: "500" }}
              fontSize={{ base: "5px", md: "14px" }}
            >
              contact.us@shucaefilms.com
            </Text>
          </Flex>
          <Flex
            gap=".5rem"
            direction="column"
            align="center"
            width={{ base: "60px", md: "118px" }}
            textAlign={{ base: "center", md: "right", lg: "center" }}
            //   Mt={{ base: "0", md: "3.3rem", lg: "0" }}
          >
            <Text
              mb="1rem"
              fontSize={{ base: "7px", md: "13px" }}
              fontWeight="500"
            >
              Quicklinks
            </Text>
            <NavLink to="/">
              <Text
                fontWeight={{ base: "500", md: "500" }}
                fontSize={{ base: "5.5px", md: "14px" }}
              >
                Home
              </Text>
            </NavLink>
           
           

            {/* <Text>Our Presence</Text> */}
          </Flex>
          <Flex
            gap=".5rem"
            direction="column"
            align="center"
            width={{ base: "60px", md: "118px" }}
            // Mt={{ base: "0", md: "3.3rem", lg: "0" }}
          >
            <Text
              mb="1rem"
              fontSize={{ base: "7px", md: "12px" }}
              fontWeight="500"
            >
              Follow Us
            </Text>
            <Flex
              gap="1rem"
              align={{ base: "start", md: "center" }}
              direction={{ base: "column", md: "row" }}
            >
              <a
                href="https://www.facebook.com/profile.php?id=100089912959610"
                target="_blank" rel="noreferrer"
              >
                <Image
                  width={{ base: "20px", md: "24px" }}
                  height={{ base: "15px", md: "24px" }}
                  src={facebook}
                />
              </a>
              {/* <img src={whatsapp} /> */}
              <a href="https://www.instagram.com/shucae_films/" target="_blank" rel="noreferrer">
                <Image
                  width={{ base: "20px", md: "24px" }}
                  height={{ base: "15px", md: "24px" }}
                  src={insta}
                />
              </a>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          mt=".5rem"
          align="center"
          justify="space-evenly"
          direction={{ base: "column", md: "row" }}
        >
          <Flex w={{ base: "100px", md: "200px" }}>
            <Image src={MadeInIndia} alt="MadeInIndia" />
          </Flex>

          <Text
            fontFamily="Plus Jakarta Sans"
            fontWeight={{ base: "400", md: "600" }}
            lineHeight={{ base: "8px", md: "24px" }}
            fontSize={{ base: "10px", md: "15px" }}
            color="rgba(163, 164, 169, 1)"
          >
            Copyright 2024 @ Shucae films Pvt Ltd. All rights Reserved.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
