
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
/*
 * IMPORTS
 */
import { useState, useEffect } from "react";

import Carousel from "./Components/carousel";

import CategoryRead from "../../__query__/index.trayFetch.query.js";
import { useQuery } from "@apollo/client";
import { connect } from "react-redux"; // NPM: React-redux library.
/*
 * CHAKRA IMPORTS
 */
import { Box, Flex } from "@chakra-ui/react";

/*
 * Query
 */
import GetTopTen from "../../__query__/index.getTopRated.query";

/*
 * OBJECT
 */
function Index({ account, loadingCategory }) {
  const [trayList, setTrayList] = useState();
  const [trayEditLoading, setTrayEditLoading] = useState(false);

  const _GetTopTen = useQuery(GetTopTen);


  const { refetch: CategoryReadRefetch, data: CategoryReadQuery } = useQuery(
    CategoryRead,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );

  async function HandleCategoryReadRefetch() {
    await CategoryReadRefetch();
  }

  useEffect(() => {
    if (CategoryReadQuery && CategoryReadQuery.CategoryRead) {
      setTrayList(CategoryReadQuery.CategoryRead);
    }
  }, [CategoryReadQuery]);

  useEffect(() => {
    HandleCategoryReadRefetch();
  }, [loadingCategory, trayEditLoading]);

  return (
    <Box
      w="100%"
      pt="5rem"
      fontFamily="Poppins"
      fontSize={["clamp(12px, 1.5vw, 15px)"]}
      fontWeight="500"
      color="#263238"
    >
      <Flex direction="column">
        {trayList?.map((data) => (
          <Carousel
            setTrayEditLoading={setTrayEditLoading}
            key={data.id}
            props={{
              title: data?.name,
              trayData: data?.ContentDetailCategory,
              trayId: data?.id,
            }}
          />
        ))}
        <Carousel
          setTrayEditLoading={setTrayEditLoading}
          key={_GetTopTen?.data?.GetTopTen?.[0]?.id}
          props={{
            title: _GetTopTen?.data?.GetTopTen?.[0]?.name,
            trayData: _GetTopTen?.data?.GetTopTen?.[0]?.ContentDetailCategory,
            trayId: _GetTopTen?.data?.GetTopTen?.[0]?.id,
          }}
        />
      </Flex>
    </Box>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
