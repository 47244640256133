/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery($search: String) {
    SubscribedAccountSearch(search: $search) {
      status
      message
      fullName
      email
      id
      expiry
      createdAt
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
