/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // Npm: Apollo client for handling graphql request.

/*
 * GRAPHS
 */
const Index = gql`
  query MyQuery($contentDetailId: ID!) {
    FetchSpecificVideo(contentDetailId: $contentDetailId) {
      message
      plotSummary
      subtitleDetails
      contentLink
      likes
      id
      duration
      thumbnailLink
      verticalThumbnailStoredAt
      ContentSummary {
        contentType
        id
        ageRating
        contentTitle
        contentLanguage
        genre
        cast
        dop
        producer

        Account {
          id
          userName
          gender
        }
      }
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
