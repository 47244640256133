
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
/*
 * IMPORTS
 */
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";
import { useHistory } from "react-router-dom";
import { cardTextStyle } from "../../../../components/Styles/index.style"

/*
 * CHAKRA IMPORTS
 */
import { Box, Flex, Text } from "@chakra-ui/react";

/*
 * QUERY
 */

/*
 * MUTATAION
 */

import { connect } from "react-redux"; // NPM: React-redux library.

/*
 * OBJECT
 */
function Index() {
  /*
   * STATE
   */
  const history = useHistory();

  /*
   * STYLE
   */

 

  /*
   * FUNCTION
   */

  return (
    <>
      <Box bg="#00020A">
        <Navbar />
        <Flex
          h="78vh"
          align="center"
          bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
          direction="column"
          w="100%"
          p="40px"
        >
          <Flex
            h="100%"
            w="100%"
            mt="5rem"
            align="center"
            justify="space-evenly"
          >
            {/* Buyer */}
            <Flex
              w="300px"
              h="200px"
              bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
              border="1px solid purple"
              borderRadius="20px"
              boxShadow="0px 0px 10px 0px white" // Add box shadow
              transition="box-shadow 0.3s, transform 0.3s" // Add transition for smooth effect
              _hover={{
                boxShadow: "0px 0px 100px 0px white", // Change box shadow on hover
                transform: "rotate(5deg)", // Apply tilt effect on hover
                cursor: "pointer", // Change cursor on hover
              }}
              onClick={() => history.push("/buyers")}
            >
              <Flex w="100%" align="center" justify="center" direction="column">
                {/* <Text style={cardNumberStyle}>150</Text> */}
                <Text textAlign="center" style={cardTextStyle}>
                  Buyer
                </Text>
              </Flex>
            </Flex>

            {/* Seller */}
            <Flex
              w="300px"
              h="200px"
              bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
              border="1px solid purple"
              borderRadius="20px"
              boxShadow="0px 0px 10px 0px white" // Add box shadow
              transition="box-shadow 0.3s, transform 0.3s" // Add transition for smooth effect
              _hover={{
                boxShadow: "0px 0px 100px 0px white", // Change box shadow on hover
                transform: "rotate(5deg)", // Apply tilt effect on hover
                cursor: "pointer", // Change cursor on hover
              }}
              onClick={() => history.push("/seller")}
            >
              <Flex w="100%" align="center" justify="center" direction="column">
                {/* <Text style={cardNumberStyle}>150</Text> */}
                <Text textAlign="center" style={cardTextStyle}>
                  Seller
                </Text>
              </Flex>
            </Flex>
   
          </Flex>
        </Flex>
        <Footer />
      </Box>
    </>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
  LogoUpdate: (u) => __dispatch({ type: "LOGO_INIT", Logo: u }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
  logo: state.Logo,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
