/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
query MyQuery {
    PaymentLogs {
      id
      fullName
      email
      message
      Payment {
        id
        amount
        orderId
        recieptId
        status
        createdAt
       
      }
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
