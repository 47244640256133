/* eslint-disable react/prop-types */

/*
 * IMPORT
 */
import React, { useEffect, useState } from "react";
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";

import { useLazyQuery } from "@apollo/client";
import _ from "underscore";

import { connect } from "react-redux"; // NPM: React-redux library.
import Loader from "../LandingPage/Loader";

import { tabStyle , videoSmallTextStyle , videoBigTextStyle } from "../../../../components/Styles/index.style"


/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  Flex,
  Image,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";

/*
 * QUERY
 */
import FetchAllContentDetail from "../__query__/index.totalVideos.query";

function Index({ account }) {
  /*
   * States
   */

  const [expandedSummary, setExpandedSummary] = useState({});
  const [approved, setApproved] = useState();
  const toast = useToast();

  const [FetchAllContentDetailQuery, { loading: loadingVideoList }] =
    useLazyQuery(FetchAllContentDetail, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  /*
   * STYLE
   */
 

  /*
   * Functions
   */
  const toggleSummary = (id) => {
    setExpandedSummary((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  function formatDuration(durationInMinutes) {
    const hours = Math.floor(durationInMinutes / 60);
    const remainingMinutes = durationInMinutes % 60;

    // eslint-disable-next-line init-declarations
    let formattedDuration = "";

    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }

    if (remainingMinutes > 0) {
      formattedDuration += `${remainingMinutes}m`;
    }

    return formattedDuration.trim(); // Remove any leading/trailing whitespace
  }

  const handleCreatorList = async () => {
    try {
      const response = await FetchAllContentDetailQuery({
        variables: {},
      });

      const data = response.data.FetchAllContentDetail;

      if (!_.isEmpty(data)) {
        // Filter data based on "approved" and "declined" properties
        const approvedData = data.filter((item) => true === item.approved);

        // Store the filtered data in separate state variables
        setApproved(approvedData);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred .",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    handleCreatorList();
  }, []);

  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
        w="100%"
        minHeight="100vh"
      >
        <Flex w="100%" h="100px"></Flex>

        <Flex mt="1rem">
          <Flex overflow="scroll" direction="column" w="100%">
            <Tabs width="100%">
              <TabList
                gap="3rem"
                padding="0px"
                style={tabStyle}
                borderColor="none"
                borderBottom="1px solid #505050"
              >
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Videos
                </Tab>
              </TabList>
              <TabIndicator
                mt="-2px"
                height="3px"
                bg="#FFFFFF"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel w="100%" padding="0px">
                  {loadingVideoList ? (
                    <Flex align="center" justify="center" w="100%">
                      <Loader />
                    </Flex>
                  ) : (
                    <Flex
                      overflowY="auto"
                      maxHeight="700px"
                      p="35px 24px"
                      direction="column"
                      gap="1rem"
                    >
                      {approved?.map((data, index) => (
                        <Flex
                          p="15px"
                          key={data.id}
                          borderRadius="10px"
                          bg="#000000"
                          w="100%"
                          gap="1rem"
                          cursor="pointer"
                        >
                          <Flex color="white">{index + 1}</Flex>
                          <Flex w="256px" h="158px">
                            <Image
                              w="256px"
                              h="158px"
                              src={data?.lowerThumbnailLink}
                            />
                          </Flex>
                          <Flex gap=".5rem" direction="column">
                            <Text style={videoBigTextStyle}>
                              {data?.ContentSummary?.contentTitle}
                            </Text>
                            <Text w="850px" style={videoSmallTextStyle}>
                              {expandedSummary[index] ? (
                                <span>
                                  {data?.plotSummary}
                                  <Text
                                    color="rgba(0, 129, 223, 1)"
                                    cursor="pointer"
                                    onClick={() => toggleSummary(index)}
                                  >
                                    read less
                                  </Text>
                                </span>
                              ) : (
                                <span>
                                  {data?.plotSummary
                                    ?.split(" ")
                                    ?.slice(0, 20)
                                    ?.join(" ")}
                                  {20 <
                                    data?.plotSummary?.split(" ").length && (
                                    <Text
                                      color="rgba(0, 129, 223, 1)"
                                      cursor="pointer"
                                      onClick={() => toggleSummary(index)}
                                    >
                                      read more
                                    </Text>
                                  )}
                                </span>
                              )}
                            </Text>
                            <Flex gap=".5rem" direction="column">
                              <Flex align="center" gap="1rem">
                                <Text
                                  color="rgba(69, 211, 105, 1)"
                                  fontFamily="Roboto"
                                  fontSize="19px"
                                  fontWeight="700"
                                  lineHeight="21.54px"
                                  letterSpacing="-0.36px"
                                >
                                  {new Date(data?.createdAt).getFullYear()}
                                </Text>
                                <Text
                                  color="rgba(186, 186, 186, 1)"
                                  fontFamily="Roboto"
                                  fontSize="19px"
                                  fontWeight="400"
                                  lineHeight="21.54px"
                                  letterSpacing="-0.36px"
                                >
                                  {formatDuration(data?.duration)}
                                </Text>
                                <Flex
                                  borderRadius="3px"
                                  justify="center"
                                  align="center"
                                  h="19px"
                                  border="1px solid rgba(116, 116, 116, 1)"
                                  color="rgba(255, 255, 255, 1)"
                                  fontFamily="Roboto"
                                  fontWeight="400"
                                  fontSize="12px"
                                  lineHeight="21.54px"
                                >
                                  HD
                                </Flex>
                              </Flex>
                              <Flex align="center" gap="1rem">
                                <Flex
                                  color="white"
                                  w="70px"
                                  h="20px"
                                  justify="center"
                                  align="center"
                                  border="1px solid rgba(116, 116, 116, 1)"
                                >
                                  {`${
                                    "18" === data?.ContentSummary?.ageRating
                                      ? "A"
                                      : "U/A"
                                  } ${data?.ContentSummary?.ageRating}+`}
                                </Flex>
                                <Text style={videoSmallTextStyle}>
                                  <span style={{ textTransform: "capitalize" }}>
                                    {data?.ContentSummary?.genre}
                                  </span>
                                </Text>
                              </Flex>
                            </Flex>
                          </Flex>
                          <Flex ml="15rem" gap=".5rem" direction="column">
                            <Text
                              style={videoBigTextStyle}
                            >{`@${data?.ContentSummary?.Account?.userName}`}</Text>
                            <Text style={videoSmallTextStyle}>
                              {data?.ContentSummary?.Account?.fullName}
                            </Text>
                            <Flex gap=".5rem" direction="column">
                              <Text style={videoSmallTextStyle}>
                                Followers :{" "}
                                <span>
                                  {
                                    data?.ContentSummary?.Account
                                      ?.Follow_Follow_Account__followerIdToAccount
                                      ?.length
                                  }
                                </span>
                              </Text>
                              <Flex align="center" gap="1rem">
                                <Text color="#5A2CC1">Status</Text>

                                <Text color="green">Approved</Text>
                              </Flex>
                            </Flex>
                          </Flex>
                        </Flex>
                      ))}
                    </Flex>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
