/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery {
    ContentApprovedUpdated {
      id
      plotSummary
      createdAt
      duration
      status
      thumbnailLink
      isWebseries
      ContentSummary {
        contentTitle
        id
        ageRating
        genre
        contentType
        Account {
          id
          userName
          fullName
          Follow_Follow_Account__followerIdToAccount {
            id
          }
        }
      }
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
