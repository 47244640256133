/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // Npm: Apollo client for handling graphql request.

/*
 * GRAPHS
 */
const Index = gql`
  mutation MyMutation($categoryId: ID, $contentDetailId: ID , $contentSummaryId: ID) {
    ContentApprove(categoryId: $categoryId, contentDetailId: $contentDetailId , contentSummaryId: $contentSummaryId) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
