/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery {
    SubscribedUser {
      email
      fullName
      Subscription {
        amount
        subscriptionName
      }
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
