/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // Npm: Apollo client for handling graphql request.

/*
 * GRAPHS
 */
const Index = gql`
  mutation MyMutation(
    $cast: [JSON]
    $contentSummaryId: ID
    $contentDetailId: ID
    $contentTitle: String
    $dop: String
    $plotSummary: String
    $producer: String
    $genre: [String]
  ) {
    EditContentByAdmin(
      cast: $cast
      contentSummaryId: $contentSummaryId
      contentDetailId: $contentDetailId
      contentTitle: $contentTitle
      dop: $dop
      plotSummary: $plotSummary
      producer: $producer
      genre: $genre
    ) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
