/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation($homePageMovieId: ID!) {
    HomePageMovieDelete(homePageMovieId: $homePageMovieId) {
      status
      message
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
