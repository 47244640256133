/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery($companyName: String!) {
    SpecificLogsRead(companyName: $companyName) {
      status
      message
      queryName
      createdAt
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
