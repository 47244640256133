/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery($companyName: String!) {
    VideoLogsRead(companyName: $companyName) {
      status
      message
      companyName
      ContentDetail {
        ContentSummary {
          contentTitle
        }
      }
      createdAt
      id
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
