/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation(
    $amount: Int
    $subscriptionName: String
    $subscriptionPlanId: String!
    $discount: Int
  ) {
    SubscriptionPlanUpdate(
      subscriptionPlanId: $subscriptionPlanId
      amount: $amount
      subscriptionName: $subscriptionName
      discount: $discount
    ) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
