/* eslint-disable no-shadow */
/* eslint-disable max-lines-per-function */
/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React, { useState } from "react";

import { connect } from "react-redux"; // NPM: React-redux library.

/*
 * CHAKRA IMPORTS
 */
import {
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

import ChartTabPanel from "./VideoChart";

/*
 * QUERY
 */

function ModalOpen({ data }) {
  /*
   * States
   */

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [watchTime, setWatchTime] = useState("");
  const [duration, setDuration] = useState("");
  const [chartId, setChartId] = useState("");

  /*
   * FUNCTION
   */

  /*
   * STYLE
   */

  /*
   * Functions
   */

  
  return (
    <>
      <Button
        colorScheme="blue"
        mr={3}
        onClick={() => {
          setWatchTime(data?.watchTime);
          setDuration(data?.duration);
          setChartId(data);
          setIsModalOpen(true); // Open the modal
        }}
      >
        Open chart
      </Button>

      <Modal
        isCentered
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size="3xl"
      >
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Chart</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Flex bg="rgba(0, 0, 0, 0.1)" borderRadius="5px">
              {chartId && (
                <ChartTabPanel duration={duration} watchTime={watchTime} />
              )}{" "}
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setIsModalOpen(false)}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(ModalOpen);
