/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation(
    $brandCarousel: String
    $brandNameId: ID
    $brandPoster: JSON
    $brandUrl: String
  ) {
    BrandDetailsCreate(
      brandCarousel: $brandCarousel
      brandNameId: $brandNameId
      brandPoster: $brandPoster
      brandUrl: $brandUrl
    ) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
