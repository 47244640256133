/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import _ from "underscore";
import { MdOutlineFileUpload } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux"; // NPM: React-redux library.
import { useMutation, useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";

/*
 * CHAKRA IMPORTS
 */
import {
  Link,
  Image,
  Flex,
  Text,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  Select,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalBody,
  useToast,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";

/*
 * MUTATAION
 */
import BrandDetailsCreate from "./__mutation__/index.detailCreate.mutation";
import BrandDetailsRemove from "./__mutation__/index.adRemove.mutation";

/*
 * QUERY
 */
import BrandDetailsRead from "./__query__/index.brandDetailRead.query";

function Index({ account }) {
  /*
   * States
   */
  const [poster, setPoster] = useState({
    thumbnailStoredAt: null,
    uniqueId: null,
  });

  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [adDetail, setAdDetail] = useState();
  const id = searchParams.get("id");
  const toast = useToast();
  const [adRemoveId, setAdRemoveId] = useState();

  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure();

  const {
    refetch: BrandDetailsReadRefetch,
    data: BrandDetailsReadQuery,
  } = useQuery(BrandDetailsRead, {
    variables: { BrandNameId: id },
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  const [BrandDetailsCreateMutation, { loading: loadingBrandCreate }] =
    useMutation(BrandDetailsCreate, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const [BrandDetailsRemoveMutation, { loading: loadingBrandDetailsRemove }] =
    useMutation(BrandDetailsRemove, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  /*
   * STYLE
   */

  const videoBigTextStyle = {
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0.2px",
  };

  const videoBigTextStyle1 = {
    color: "rgba(116, 116, 116, 1)",
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0.2px",
  };

  /*
   * Functions
   */

  const handleAdRemove = async () => {
    try {
      const response = await BrandDetailsRemoveMutation({
        variables: {
          BrandDetailsId: adRemoveId,
        },
      });

      const data = response.data.BrandDetailsRemove;

      if (!_.isEmpty(data)) {
        toast({
          title: "Form Submitted",
          description: "Data saved successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        closeModal();
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  const handleBrandDetail = async (e) => {
    e.preventDefault();

    try {
      const response = await BrandDetailsCreateMutation({
        variables: {
          brandCarousel: selectedOption,
          brandNameId: id,
          brandPoster: poster,
          brandUrl: inputValue,
        },
      });

      const data = response.data.BrandDetailsCreate;

      if (!_.isEmpty(data)) {
        toast({
          title: "Form Submitted",
          description: "Data saved successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setPoster({
          thumbnailStoredAt: null,
          uniqueId: null,
        });
        setInputValue("");
        setSelectedOption("");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  async function HandleBrandDetailsReadRefetch() {
    await BrandDetailsReadRefetch();
    // Your code to execute after the refetch is complete
  }

  useEffect(() => {
    if (BrandDetailsReadQuery && BrandDetailsReadQuery.BrandDetailsRead) {
      setAdDetail(BrandDetailsReadQuery.BrandDetailsRead[0].BrandDetails);
    }
  }, [BrandDetailsReadQuery]);

  useEffect(() => {
    HandleBrandDetailsReadRefetch();
  }, [loadingBrandCreate, loadingBrandDetailsRemove]);

  const handleRemoveClick = (id) => {
    setAdRemoveId(id);
    openModal();
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    // Check if a file is selected
    if (file) {
      const generatedUniqueId = uuidv4();

      setPoster({
        ...poster,
        thumbnailStoredAt: file,
        uniqueId: generatedUniqueId,
      });
    }
  };

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <Flex direction="column" pt="50px" pb="50px" align="center">
      <Flex direction="column">
        <form style={{ width: "100%" }} onSubmit={(e) => handleBrandDetail(e)}>
          <Flex align="end" p="20px" w="100%">
            <FormControl>
              <FormLabel style={videoBigTextStyle}>
                Upload brand poster
              </FormLabel>
              <InputGroup align="center">
                <Button
                  style={videoBigTextStyle1}
                  border="1px solid #3D445A"
                  bg="transparent"
                  _hover={{ bg: "transparent" }}
                  onClick={() => {
                    // Trigger the hidden file input when the button is clicked
                    document.getElementById("thumbnailInput").click();
                  }}
                  width={{ base: "320px" }}
                  position="relative"
                >
                  <MdOutlineFileUpload
                    size="25px"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                </Button>
                {/* Hidden file input */}
                <Input
                  type="file"
                  id="thumbnailInput"
                  name="thumbnailStoredAt"
                  onChange={handleImageUpload}
                  display="none"
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel style={videoBigTextStyle}>Brand Url</FormLabel>
              <InputGroup>
                <Input
                  width="320px"
                  borderColor="rgba(116, 116, 116, 1)"
                  color="white"
                  placeholder="Url"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel style={videoBigTextStyle}>
                Choose poster carousel
              </FormLabel>
              <Select
                width="320px"
                borderColor="rgba(116, 116, 116, 1)"
                color="white"
                value={selectedOption}
                onChange={handleSelectChange}
                placeholder="Choose poster carousel"
              >
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>
            </FormControl>
            <Button
              bg="blue.600"
              color="white"
              w="210px"
              h="50px"
              _hover={{ bg: "blue.500" }}
              type="submit"
            >
              {loadingBrandCreate ? <Spinner /> : "Save"}
            </Button>
          </Flex>
        </form>
        <Flex
          gap="20px"
          align="center"
          borderRadius="10px"
          border="1px solid #878992"
          p="20px"
        >
          <Flex>
            {poster ? (
              <Image
                src={
                  !!poster?.thumbnailStoredAt
                    ? URL.createObjectURL(poster?.thumbnailStoredAt)
                    : ""
                }
              />
            ) : (
              <Text style={videoBigTextStyle}>No poster</Text>
            )}
          </Flex>
          <Link style={videoBigTextStyle} href={inputValue} target="_blank">
            {inputValue || "No url provided"}
          </Link>
          <Text style={videoBigTextStyle}>
            {selectedOption || "No carousel provided"}
          </Text>
        </Flex>
      </Flex>

      <Flex
        overflowY="auto"
        maxHeight="700px"
        p="35px 24px"
        direction="column"
        gap="3rem"
      >
        {adDetail?.map((data, index) => (
          <Flex
          key={data.id}
            p="15px"
            borderRadius="10px"
            bg="#000000"
            w="100%"
            gap="1rem"
            cursor="pointer"
            direction="column"
            align="center"
          >
            <Flex
              bg="white"
              w="40px"
              h="40px"
              borderRadius="50%"
              textAlign="center"
              color="white"
              justify="center"
              align="center"
            >
              <Text fontWeight="600" fontSize="20px" color="black">
                {index + 1}
              </Text>
            </Flex>
            <Flex direction="column">
              <Text style={videoBigTextStyle1}>Brand poster :</Text>
              <Flex bg="red">
                <Image src={data?.brandPoster} />
              </Flex>
            </Flex>
            <Flex mt="1rem" w="100%" direction="column">
              <Text style={videoBigTextStyle1}>Brand url :</Text>
              <Link
                href={data.brandUrl}
                target="_blank"
                style={videoBigTextStyle}
              >
                <Text color="blue.400">{data?.brandUrl}</Text>
              </Link>
            </Flex>
            <Flex mt="1rem" w="100%" direction="column">
              <Text style={videoBigTextStyle1}>Brand poster carousel :</Text>
              <Text color="blue.600" style={videoBigTextStyle}>
                {data?.brandCarousel}
              </Text>
            </Flex>
            <Button
              colorScheme="red"
              onClick={() => handleRemoveClick(data?.id)}
            >
              Remove
            </Button>
            {/* Remove Modal */}
            <Modal isCentered isOpen={isModalOpen} onClose={closeModal}>
              <ModalContent>
                <ModalHeader>Confirm Removal</ModalHeader>
                <ModalBody>
                  Are you sure you want to remove this poster from our platform?
                </ModalBody>
                <ModalFooter gap="20px">
                  <Button colorScheme="red" onClick={handleAdRemove}>
                    {loadingBrandDetailsRemove ? <Spinner /> : "Yes"}
                  </Button>
                  <Button onClick={closeModal}>No</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
