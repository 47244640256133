/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation(
    $bulletpoints: [JSON]
    $content: String
    $heading: String
    $author: String
    $horizontalImageFile: JSON
    $title: String
    $metaTitle: String
    $metaDescription: String
    $keywords: [String]
    $verticalImageFile: JSON
  ) {
    BlogsCreate(
      bulletpoints: $bulletpoints
      content: $content
      heading: $heading
      author: $author
      metaTitle: $metaTitle
      metaDescription: $metaDescription
      keywords: $keywords
      horizontalImageFile: $horizontalImageFile
      title: $title
      verticalImageFile: $verticalImageFile
    ) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
