/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // Npm: Apollo client for handling graphql request.

/*
 * GRAPHS
 */
const Index = gql`
  mutation MyMutation($contentDetailId: ID) {
    ContentDeclinedUpdated(contentDetailId: $contentDetailId) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
