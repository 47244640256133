
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-shadow */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import _ from "underscore";
import { createUploadLink } from "apollo-upload-client";
import { FaPlus } from "react-icons/fa6";
import { MdOutlineFileUpload, MdErrorOutline } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import { IoClose, IoCheckmarkDoneSharp } from "react-icons/io5";
import { useLazyQuery, useMutation } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import { connect } from "react-redux"; // NPM: React-redux library.
import WebseriesUpload from "./__mutation__/index.uploadSeries.mutation";
import {
  labelStyle,
  inputStyle,
  inputStyle1,
} from "../Styles/index.style.js";
import NoSleep from "nosleep.js";



// Import YoutubeLinkVerify from "./__query__/index.verifyLink.query";
import TimeInput from "../timeInput/timeInput";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client, S3 } from "@aws-sdk/client-s3";

const initialState = {
  creatorName: "",
  lastName: "",
  userName: "",
  email: "",
  phone: "",
  contentType: "",
  contentTitle: "",
  contentLink: "",
  contentVideoLink: "",
  plotSummary: "",
  duration: "",
  language: "",
  verticalThumbnailStoredAt: "",
  thumbnailStoredAt: "",
  ageRating: "",
  genre: "",
  dop: "",
  episodeNumber: "",
  producer: "",
  cast: "",
  name: "",
  music: "",

  isEmbedded: false,
};

/*
 * CHAKRA UI IMPORT
 */
import {
  FormControl,
  FormLabel,
  Text,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Input,
  Select,
  Checkbox,
  InputGroup,
  InputRightElement,
  CircularProgress,
  CircularProgressLabel,
  Image,
  useToast,
} from "@chakra-ui/react";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

/*
 * OBJECT
 */
const TimeWrapper = ({ onDataFromChild }) => {
  const [timeDuration, setTimeDuration] = useState("");

  const onTimeChangeHandler = (val) => {
    const dur = parseInt(val.split(":")[0]) * 60 + parseInt(val.split(":")[1]) + parseInt(val.split(":")[2]) / 60 ;
    setTimeDuration(dur);
    onDataFromChild(dur);
  };

  return (
    <TimeInput
      name="duration"
      borderColor="none"
      className="s-input -time"
      onTimeChange={onTimeChangeHandler}
      placeholder="HH:MM:SS" // Set the placeholder text
    />
  );
};

/*
 * OBJECT
 */
function Index({ account, isOpen, onClose }) {
  /*
   * STATE
   */

  const toast = useToast();
  const history = useHistory();
  const [formData, setFormData] = useState(initialState);
  const [linkStateMultiple, setLinkStateMultiple] = useState([]);
  const [videoLoading, setVideoLoading] = useState(false);
  const [videoUploadStatus, setVideoUploadStatus] = useState("");
  const [horizontalUploadStatus, setHorizontalUploadStatus] = useState("");
  const [verticalUploadStatus, setVerticalUploadStatus] = useState("");
  const [emptyDuration, setEmptyDuration] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [videoUploadFile, setVideoUploadFile] = useState();
  const [progress, setProgress] = useState(0); // State to track progress

  const [linkState, setLinkState] = useState({
    id: uuidv4(),
    contentLink: "",
    plotSummary: "",
    duration: "",
    episodeNumber: "",
    verticalThumbnailStoredAt: "",
    contentVideoLink: "",
    thumbnailStoredAt: "",
    isEmbedded: false,
  });

  // Function to confirm leaving the page
  const confirmLeave = (e) => {
    const confirmationMessage =
      "Your upload is in progress. Are you sure you want to leave?";
    e.returnValue = confirmationMessage;

    return confirmationMessage;
  };

  const [castState, setCastState] = useState([]);

  

  const [
    // eslint-disable-next-line array-element-newline
    WebseriesUploadMutation,
    { loading: loadingWebseriesUpload },
  ] = useMutation(WebseriesUpload, {});

  const resetForm = () => {
    setFormData({
      creatorName: "",
      lastName: "",
      userName: "",
      email: "",
      phone: "",
      contentType: "",
      contentTitle: "",
      contentLink: "",
      contentVideoLink: "",
      plotSummary: "",
      duration: "",
      language: "",
      verticalThumbnailStoredAt: "",
      thumbnailStoredAt: "",
      ageRating: "",
      genre: "",
      dop: "",
      episodeNumber: "",
      producer: "",
      cast: "",
      name: "",
      music: "",
      isEmbedded: false,
    });
    setVideoUploadStatus("");
    setVerticalUploadStatus("");
    setHorizontalUploadStatus("");
    setLinkState({});
    setLinkStateMultiple([]);
    setCastState([]);
  };

  /*
   * FUNCTION
   */

  const handleCreatorForm = async () => {
    try {
      const response = await WebseriesUploadMutation({
        variables: {
          ageRating: formData.ageRating,
          cast: castState,
          content: linkStateMultiple,
          contentLanguage: formData.language,
          contentTitle: formData.contentTitle,
          contentType: formData.contentType,
          dop: formData.dop,
          producer: formData.producer,
          genre: formData.genre,
          music: formData.music,
        },
      });

      const data = response.data.WebseriesUpload;

      if (!_.isEmpty(data)) {
        if (data.status === "REQUIRE__LOGIN") {
          resetForm(); // Reset form data after submission
          onClose(); // Close the modal after submission
          // Show success toast
          toast({
            title: "Error",
            description: "Login Required",
            status: "error",
            duration: 5000, // Milliseconds
            isClosable: true,
          });
        } else if (data.status === "FORM_SUBMIT") {
          resetForm(); // Reset form data after submission
          onClose();
          history.push("/");
          // Show success toast
          toast({
            title: "Form Submitted",
            description: "Your form has been submitted successfully!",
            status: "success",
            duration: 5000, // Milliseconds
            isClosable: true,
          });
        }
      }
    } catch (error) {
      // Show error toast
      toast({
        title: "Error",
        description:
          "An error occurred while submitting the form Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  // Const handleYoutubeLinkVerify = async () => {
  //   Try {
  //     Const response = await YoutubeLinkVerifyQuery({
  //       Variables: {
  //         ContentLink: linkState.contentLink,
  //       },
  //     });

  //     Const data = response.data.YoutubeLinkVerify;

  //     If (!_.isEmpty(data)) {
  //       If (data.status === "INVALID_LINK") {
  //         Toast({
  //           Title: "Error",
  //           Description: "Invalid Link",
  //           Status: "error",
  //           Duration: 5000, // Milliseconds
  //           IsClosable: true,
  //         });
  //       } else if (data.status === "VALID_LINK") {
  //         /*
  //          * ResetForm(); // Reset form data after submission
  //          * OnClose(); // Close the modal after submission
  //          * Show success toast
  //          */
  //         Toast({
  //           Title: "Valid Link",
  //           Description: "This is a valid link",
  //           Status: "success",
  //           Duration: 5000, // Milliseconds
  //           IsClosable: true,
  //         });
  //       }
  //     }
  //   } catch (error) {
  //     Toast({
  //       Title: "Error",
  //       Description: "An error occurred while verifing link.",
  //       Status: "error",
  //       Duration: 5000, // Milliseconds
  //       IsClosable: true,
  //     });
  //   }
  // };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      setLinkState((prevFormData) => ({
        ...prevFormData,
        thumbnailStoredAt: file,
      }));
      setHorizontalUploadStatus("success");
    } else {
      setHorizontalUploadStatus("error");
    }
  };
  // Function to disable modal closing actions
  const disableModalClosing = () => {
    // Disable modal close
    setModalOpen(false);
  };

  const enableModalClosing = () => {
    // Enable modal close
    setModalOpen(true);
  };

  const handleVideoUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      setLinkState((prevFormData) => ({
        ...prevFormData,
        contentVideoLink: file,
      }));
      setVideoUploadStatus("success");
    } else {
      setVideoUploadStatus("error");
    }
  };

  const handleVerticalImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      setLinkState((prevFormData) => ({
        ...prevFormData,
        verticalThumbnailStoredAt: file,
      }));
      setVerticalUploadStatus("success");
    } else {
      setVerticalUploadStatus("error");
    }
  };

  React.useEffect(() => {
    // Update buffer to window.
    window.Buffer = window.Buffer || require("buffer").Buffer;
  });

  const updateProgressBar = (percent) => {
    setProgress(percent); // Update the progress state
  };

  const handleLinkState = () => {
    if (
      linkState.duration &&
      linkState.plotSummary &&
      linkState.episodeNumber &&
      linkState.contentVideoLink
    ) {
      const uniqueId = uuidv4();

      const newLinkState = {
        ...linkState,
        id: uniqueId, // Add the unique ID to the linkState object
      };

      setLinkStateMultiple((prevLinkState) => [...prevLinkState, newLinkState]);

      setLinkState((prev) => ({
        ...prev,
        contentLink: "",
        plotSummary: "",
        episodeNumber: "",
        duration: "",
        verticalThumbnailStoredAt: "",
        thumbnailStoredAt: "",
        contentVideoLink: "",
        isEmbedded: false,
      }));
      setEmptyDuration(true);
      setVideoUploadStatus("");
      setHorizontalUploadStatus("");
      setVerticalUploadStatus("");
    } else if (!linkState.duration) {
      toast({
        title: "Duration is required",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else if (!linkState.plotSummary) {
      toast({
        title: "Plot Summary is required",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else if (!linkState.episodeNumber) {
      toast({
        title: "Episode Number is required",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else if (!linkState.contentVideoLink) {
      toast({
        title: "Content Video Link is required",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleCastState = () => {
    // Check if contentLink and summary are not empty

    if (formData.cast && formData.name) {
      // Create a new object with values from formData
      const newLink = {
        id: uuidv4(),
        cast: formData.cast,
        name: formData.name,
      };

      // Update castState with the new object
      setCastState((prevLinkState) => [...prevLinkState, newLink]);

      // Clear the cast and name fields in formData
      setFormData((prevFormData) => ({
        ...prevFormData,
        cast: "",
        name: "",
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const form = { ...formData, [name]: value };

    setFormData(form);

    // Log the formData state to see if it's updating correctly
  };


  const handleLinkStateChange = (e) => {
    const { name, value } = e.target;

    setLinkState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (linkStateMultiple.length > 0) {
      // Check if there are files to upload
      setVideoLoading(true);
      disableModalClosing();
      const noSleep = new NoSleep();


      // Prevent page refresh or tab close
      window.addEventListener("beforeunload", confirmLeave);
      noSleep.enable();

      try {
        const s3Client = new S3Client({
          forcePathStyle: false, // Configures to use subdomain/virtual calling format.
          endpoint: process.env.REACT_APP_S3_ENDPOINT,
          region: "blr1",
          credentials: {
            accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
          },
        });

        // Array to store promises for each upload
        const uploadPromises = [];

        // Iterate over each object in linkStateMultiple
        linkStateMultiple.forEach((item, index) => {
          const id = uuidv4();
          const parallelUploads3 = new Upload({
            client: s3Client,
            params: {
              Bucket: "shucaeimage",
              Key: `shucaeContent/${id}.mp4`,
              Body: item.contentVideoLink,
              ACL: "public-read",
            },
            queueSize: 4, // Optional concurrency configuration
            partSize: 1024 * 1024 * 5, // Optional size of each part, in bytes, at least 5MB
            leavePartsOnError: false, // Optional manually handle dropped parts
          });

          // Add event listener for progress
          parallelUploads3.on("httpUploadProgress", (progress) => {
            const percentUploaded = Math.round(
              (progress.loaded / progress.total) * 100
            );
            updateProgressBar(percentUploaded); // Update progress bar
          });

          // Push promise to array
          uploadPromises.push(
            parallelUploads3.done().then((_data) => {
              // Store location in linkStateMultiple
              if (_data.Location) {
                if (!_data.Location.startsWith("https://")) {
                  _data.Location = `https://${_data.Location}`;
                }
                linkStateMultiple[index].contentVideoLink = _data.Location;
              }
            })
          );
        });

        // Wait for all uploads to complete
        await Promise.all(uploadPromises);
        handleCreatorForm();
        setVideoLoading(false);
        noSleep.disable();

        // Now linkStateMultiple has updated contentVideoLink with _data.Location
      } catch (e) {
        setVideoLoading(false);
        noSleep.disable();
      } finally {
        window.removeEventListener("beforeunload", confirmLeave);
        enableModalClosing();
        noSleep.disable();
      }
    }
  };

  const handleDeleteLinkState = (id) => {
    // Filter out the item with the specified id
    const updatedLinkState = linkStateMultiple.filter((item) => item.id !== id);

    // Update the state with the modified array
    setLinkStateMultiple(updatedLinkState);
  };

  const handleClose = () => {
    if (modalOpen) {
      resetForm();
      onClose();
    }
  };

  const handleDeleteCastState = (id) => {
    // Filter out the item with the specified id
    const updatedCastState = castState.filter((item) => item.id !== id);

    // Update the state with the modified array
    setCastState(updatedCastState);
  };

  const handleDuration = (data) => {
    // Handle the data received from the child component
    const form = { ...formData, duration: data };
    setLinkState((prevLinkState) => ({
      ...prevLinkState,
      duration: data,
    }));
    setFormData(form);
  };

  return (
    <Flex
      w="100%"
      justifyContent={{
        base: "center",
        md: "center",
        lg: "center",
        xl: "left",
      }}
    >
      <Modal
        size="5xl"
        isOpen={isOpen}
        onClose={handleClose}
        scrollBehavior="inside"
        closeOnOverlayClick={false}
      >
        {" "}
        <ModalOverlay />
        <ModalContent
          p={{ base: "7px", md: "15px" }}
          borderRadius="20px"
          bg="rgba(42, 45, 62, 1)"
          position="fixed"
        >
          <ModalHeader
            fontFamily="Roboto"
            fontWeight="500"
            fontSize={{ base: "25px", md: "32px" }}
            lineHeight="28.21px"
            letterSpacing="-0.64px"
            color="rgba(255, 255, 255, 1)"
          >
            Submit Your Webseries
          </ModalHeader>
          <ModalCloseButton
            size="lg"
            mt="15px"
            mr="10px"
            color="rgba(238, 93, 80, 1)"
          />
          <ModalBody pb={10} overflowY="auto" overflowX="hidden">
            {/* /
            Form
            / */}

            <form onSubmit={handleSubmit}>
              <Flex gap="1rem" direction="column">
                {/* /
                Upper Section of Form
                / */}

                <Flex
                  gap="32px"
                  justify={{ base: "start", md: "center" }}
                  flexWrap="wrap"
                  w={{ base: "80%", md: "100%" }}
                >
                  <Flex gap="1rem" direction="column">
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Type of content</FormLabel>
                      <Select
                        w={{ base: "320px", md: "410px" }}
                        name="contentType"
                        onChange={handleInputChange}
                        placeholder="Choose content type"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                        color="#666874"
                      >
                        {/* Add your options here */}

                        <option value="webSeries">Web Series</option>

                        {/* Add more options as needed */}
                      </Select>
                    </FormControl>
                  </Flex>
                  <Flex gap="1rem" direction="column">
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Content title</FormLabel>
                      <Input
                        w={{ base: "320px", md: "410px" }}
                        name="contentTitle"
                        type="text"
                        onChange={handleInputChange}
                        value={formData.contentTitle}
                        placeholder="Title"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                      />
                    </FormControl>
                  </Flex>
                </Flex>

                {/* /
                Link of Content
                and
                Plot Summary
                and 
                Duration
                and
                Thumbnail
                / */}

                <Flex
                  p="1rem 10px"
                  align="center"
                  direction="column"
                  bg="#1E202D"
                  w={{ base: "106%", md: "100%" }}
                  ml={{ base: "-0.4rem", md: "0" }}
                >
                  <Flex
                    align="center"
                    flexWrap="wrap"
                    p="10px 10px 10px 30px"
                    w="100%"
                    gap="1rem"
                  >
                    {linkStateMultiple.map((item) => (
                      <Flex
                        p="20px"
                        borderRadius="5px"
                        bg="#D9D9D9"
                        key={item.id}
                        align="top"
                        gap="1rem"
                        style={labelStyle}
                        w="100%"
                      >
                        <Flex
                          w="100%"
                          gap=".5rem"
                          color="#666874"
                          direction="column"
                        >
                          <Text>
                            Video name:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item?.contentVideoLink?.name}
                            </span>
                          </Text>
                          <Text>
                            Plot:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item?.plotSummary}
                            </span>
                          </Text>
                          <Text>
                            Episode number:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item?.episodeNumber}
                            </span>
                          </Text>
                          <Text>
                            Duration:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item?.duration} mins
                            </span>
                          </Text>
                          <Text>
                            Embedded Link:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item?.isEmbedded ? "Yes" : "No"}
                            </span>
                          </Text>
                          <Text>
                            Horizontal Poster Name:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item?.thumbnailStoredAt?.name
                                ? item?.thumbnailStoredAt?.name
                                : "No Poster"}
                            </span>
                          </Text>
                          <Text>
                            Vertical Poster Name:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item?.verticalThumbnailStoredAt?.name
                                ? item?.verticalThumbnailStoredAt?.name
                                : "No Poster"}
                            </span>
                          </Text>
                          {item?.thumbnailStoredAt?.name ? (
                            <Flex
                              borderRadius="10px"
                              border="1px solid #878992"
                            >
                              <Image
                                borderRadius="10px"
                                w="100%"
                                src={
                                  item.thumbnailStoredAt
                                    ? URL.createObjectURL(
                                        item.thumbnailStoredAt
                                      )
                                    : ""
                                }
                              />
                            </Flex>
                          ) : (
                            <></>
                          )}
                          {item?.verticalThumbnailStoredAt?.name ? (
                            <Flex
                              w="250px"
                              h="375px"
                              borderRadius="10px"
                              border="1px solid #878992"
                            >
                              <Image
                                borderRadius="10px"
                                src={
                                  item.verticalThumbnailStoredAt
                                    ? URL.createObjectURL(
                                        item.verticalThumbnailStoredAt
                                      )
                                    : ""
                                }
                              />
                            </Flex>
                          ) : (
                            <></>
                          )}
                        </Flex>
                        <Flex>
                          <IoClose
                            size="20px"
                            cursor="pointer"
                            color="#7551FF"
                            onClick={() => handleDeleteLinkState(item.id)}
                          />
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                  <Flex
                    gap="1rem"
                    direction="column"
                    w={{ base: "100%", md: "92%" }}
                  >
                    <Flex
                      gap="32px"
                      align="center"
                      direction={{ base: "column", md: "row" }}
                      width="100%"
                    >
                      {/* <FormControl>
                        <FormLabel style={labelStyle}>Content Link</FormLabel>
                        <InputGroup align="center" justify="center">
                          <Input
                            name="contentLink"
                            type="text"
                            onChange={handleLinkStateChange}
                            value={linkState.contentLink}
                            placeholder="Paste link"
                            _placeholder={{ color: "#878992" }}
                            style={inputStyle}
                          />
                          <InputRightElement h="100%" width="4.5rem">
                            <Button
                              onClick={handleYoutubeLinkVerify}
                              h="1.75rem"
                              size="sm"
                            >
                              {loadingYoutubeLinkVerify ? (
                                <Spinner />
                              ) : (
                                "Verify"
                              )}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                      </FormControl> */}
                      <FormControl w="200%">
                        <FormLabel style={labelStyle}>
                          <Flex align="center" gap="5px">
                            Upload video
                            {videoUploadStatus === "success" ? (
                              <IoCheckmarkDoneSharp />
                            ) : videoUploadStatus === "error" ? (
                              <MdErrorOutline />
                            ) : (
                              ""
                            )}
                          </Flex>
                        </FormLabel>

                        <InputGroup align="center">
                          <Button
                            border="1px solid #3D445A"
                            bg="transparent"
                            _hover={{ bg: "transparent" }}
                            onClick={() => {
                              // Trigger the hidden file input when the button is clicked
                              document.getElementById("videoInput").click();
                            }}
                            style={inputStyle}
                            w="100%"
                            position="relative"
                          >
                            {videoLoading ? (
                              <Spinner />
                            ) : (
                              <MdOutlineFileUpload
                                size="25px"
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                }}
                              />
                            )}
                          </Button>
                          {/* Hidden file input */}
                          <Input
                            type="file"
                            id="videoInput"
                            name="contentVideoLink"
                            onChange={handleVideoUpload}
                            display="none"
                          />
                        </InputGroup>
                      </FormControl>

                      <FormControl>
                        <FormLabel style={labelStyle}>Duration</FormLabel>
                        {/* <Input
                          name="duration"
                          type="number"
                          value={linkState.duration}
                          placeholder="HH:MM"
                          _placeholder={{ color: "#878992" }}
                          style={inputStyle}
                          width={{ base: "320px", md: "410px" }}
                          onChange={handleLinkStateChange}
                        /> */}
                        <TimeWrapper
                          onDataFromChild={handleDuration}
                          emptyDuration={emptyDuration}
                        />
                      </FormControl>
                    </Flex>
                    <FormControl>
                      <FormLabel style={labelStyle}>Episode number</FormLabel>
                      <Input
                        name="episodeNumber"
                        type="number"
                        onChange={handleLinkStateChange}
                        value={linkState.episodeNumber}
                        placeholder="Episode number"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                        w={{ base: "320px", md: "410px" }}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel style={labelStyle}>Plot summary</FormLabel>
                      <Input
                        name="plotSummary"
                        type="text"
                        onChange={handleLinkStateChange}
                        value={linkState.plotSummary}
                        placeholder="Write summary"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle1}
                        width={{ base: "320px", md: "852px" }}
                      />
                    </FormControl>

                    <FormControl w="200%">
                      <FormLabel style={labelStyle}>
                        <Flex align="center" gap="5px">
                          Upload content horizontal poster
                          {horizontalUploadStatus === "success" ? (
                            <IoCheckmarkDoneSharp />
                          ) : horizontalUploadStatus === "error" ? (
                            <MdErrorOutline />
                          ) : (
                            ""
                          )}
                        </Flex>
                      </FormLabel>

                      <InputGroup align="center">
                        <Button
                          border="1px solid #3D445A"
                          bg="transparent"
                          _hover={{ bg: "transparent" }}
                          onClick={() => {
                            // Trigger the hidden file input when the button is clicked
                            document.getElementById("thumbnailInput").click();
                          }}
                          style={inputStyle1}
                          width={{ base: "320px", md: "852px" }}
                          position="relative"
                        >
                          <MdOutlineFileUpload
                            size="25px"
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          />
                        </Button>
                        {/* Hidden file input */}
                        <Input
                          type="file"
                          id="thumbnailInput"
                          name="thumbnailStoredAt"
                          onChange={handleImageUpload}
                          display="none"
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl w="200%">
                      <FormLabel style={labelStyle}>
                        <Flex align="center" gap="5px">
                          Upload content vertical poster
                          {verticalUploadStatus === "success" ? (
                            <IoCheckmarkDoneSharp />
                          ) : verticalUploadStatus === "error" ? (
                            <MdErrorOutline />
                          ) : (
                            ""
                          )}
                        </Flex>
                      </FormLabel>

                      <InputGroup align="center">
                        <Button
                          border="1px solid #3D445A"
                          bg="transparent"
                          _hover={{ bg: "transparent" }}
                          width={{ base: "320px", md: "852px" }}
                          onClick={() => {
                            // Trigger the hidden file input when the button is clicked
                            document
                              .getElementById("verticalThumbnailInput")
                              .click();
                          }}
                          style={inputStyle1}
                          position="relative"
                        >
                          <MdOutlineFileUpload
                            size="25px"
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          />
                        </Button>
                        {/* Hidden file input */}
                        <Input
                          type="file"
                          id="verticalThumbnailInput"
                          name="verticalThumbnailStoredAt"
                          onChange={handleVerticalImageUpload}
                          display="none"
                        />
                      </InputGroup>
                    </FormControl>

                    {formData.contentType === "webSeries" ? (
                      <Flex
                        w="54px"
                        h="50px"
                        border="1px solid #3D445A"
                        borderRadius="12px"
                        justify="center"
                        align="center"
                        onClick={() => handleLinkState()}
                        cursor="pointer"
                      >
                        <FaPlus color="#666874" />
                      </Flex>
                    ) : null}
                  </Flex>
                </Flex>

                {/* /
                Mid Section of Form
                / */}

                <Flex
                  gap="32px"
                  justify="center"
                  w="100%"
                  direction={{ base: "column", md: "row" }}
                  flexWrap="wrap"
                >
                  <Flex gap="1rem" direction="column">
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Genre</FormLabel>
                      <Select
                        name="genre"
                        onChange={handleInputChange}
                        placeholder="Genre"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                        w={{ base: "320px", md: "410px" }}
                        color="#666874"
                      >
                        {/* Add your options here */}
                        <option value="action">Action</option>
                        <option value="comedy">Comedy</option>
                        <option value="documentary">Documentary</option>
                        <option value="romance">Romance</option>
                        <option value="drama">Drama</option>
                        <option value="horror">Horror</option>
                        <option value="scienceFiction">Science Fiction</option>
                        <option value="fantasy">Fantasy</option>
                        <option value="mystery">Mystery</option>
                        <option value="thriller">Thriller</option>
                        <option value="music">Music</option>
                        <option value="education">Education</option>
                        {/* Add more options as needed */}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel style={labelStyle}>Director</FormLabel>
                      <Input
                        name="dop"
                        type="text"
                        onChange={handleInputChange}
                        value={formData.dop}
                        placeholder="Director"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                        w={{ base: "320px", md: "410px" }}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Producer</FormLabel>
                      <Input
                        name="producer"
                        type="text"
                        onChange={handleInputChange}
                        value={formData.producer}
                        placeholder="Producer"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                        w={{ base: "320px", md: "410px" }}
                      />
                    </FormControl>
                  </Flex>
                  <Flex gap="1rem" direction="column">
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Content language</FormLabel>
                      <Select
                        name="language"
                        onChange={handleInputChange}
                        placeholder="Choose content language"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                        w={{ base: "320px", md: "410px" }}
                        color="#666874"
                      >
                        {/* Add your options here */}
                        <option value="english">English</option>
                        
                        <option value="swedish">Swedish</option>

                        <option value="slovenia">Slovenia</option>

                        <option value="german">German</option>

                        <option value="spanish">Spanish</option>

                        <option value="french">French</option>

                        <option value="italian">Italian</option>

                        <option value="croatian">Croatian</option>
                        <option value="persian">Persian</option>

                        <option value="mandarin">Mandarin</option>
                        <option value="hindi">Hindi</option>
                        <option value="telugu">Telugu</option>
                        <option value="assamese">Assamese</option>
                        <option value="nepali">Nepali</option>
                        <option value="konkani">Konkani</option>
                        <option value="gujarati">Gujarati</option>
                        <option value="kannada">Kannada</option>
                        <option value="malayalam">Malayalam</option>
                        <option value="marathi">Marathi</option>
                        <option value="manipuri">Manipuri</option>
                        <option value="mizo">Mizo</option>
                        <option value="odia">Odia</option>
                        <option value="punjabi">Punjabi</option>
                        <option value="tamil">Tamil</option>
                        <option value="bengali">Bengali</option>
                        <option value="ladakhi">Ladakhi</option>
                        <option value="urdu">Urdu</option>
                        <option value="kashmiri">Kashmiri</option>
                        <option value="chhattisgarhi">Chhattisgarhi</option>
                        <option value="dogri">Dogri</option>
                        <option value="awadhi">Awadhi</option>
                        <option value="bundelkhandi">Bundelkhandi</option>
                        <option value="maithili">Maithili</option>
                        <option value="bhojpuri">Bhojpuri</option>
                        <option value="garhwali">Garhwali</option>
                        <option value="kumaoni">Kumaoni</option>
                        <option value="pahari ">Himachali (Pahari)</option>
                        {/* Add more options as needed */}
                      </Select>
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Age rating</FormLabel>
                      <Select
                        name="ageRating"
                        onChange={handleInputChange}
                        placeholder="Choose age rating"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                        w={{ base: "320px", md: "410px" }}
                        color="#666874"
                      >
                        {/* Add your options here */}
                        <option value="7">7+</option>
                        <option value="13">13+</option>
                        <option value="16">16+</option>
                        <option value="18">18+</option>
                        {/* Add more options as needed */}
                      </Select>
                    </FormControl>
                  </Flex>
                </Flex>

                {/* /
                Cast
                and
                Name
                / */}

                <Flex
                  p="1rem 10px"
                  align="center"
                  direction="column"
                  bg="#1E202D"
                  w={{ base: "106%", md: "100%" }}
                  ml={{ base: "-0.4rem", md: "0" }}
                >
                  <Flex
                    align="center"
                    flexWrap="wrap"
                    p="10px 10px 10px 30px"
                    w="100%"
                    gap="1rem"
                  >
                    {castState.map((item) => (
                      <Flex
                        p="20px"
                        borderRadius="5px"
                        bg="#D9D9D9"
                        key={item.id}
                        align="top"
                        gap="1rem"
                        style={labelStyle}
                        w="100%"
                      >
                        <Flex
                          w="100%"
                          gap=".5rem"
                          color="#666874"
                          direction="column"
                        >
                          <Text>
                            Cast:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item.cast}
                            </span>
                          </Text>
                          <Text>
                            Name:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item.name}
                            </span>
                          </Text>
                        </Flex>
                        <Flex>
                          <IoClose
                            size="20px"
                            cursor="pointer"
                            color="#7551FF"
                            onClick={() => handleDeleteCastState(item.id)}
                          />
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>

                  <Flex w={{ base: "100%", md: "92%" }}>
                    <Flex w="100%" align="end">
                      <FormControl>
                        <FormLabel style={labelStyle}>Cast member</FormLabel>
                        <Select
                          name="cast"
                          value={formData.cast}
                          onChange={handleInputChange}
                          placeholder="Choose cast"
                          _placeholder={{ color: "#878992" }}
                          style={inputStyle}
                          w={{ base: "280px", md: "370px" }}
                          color="#666874"
                        >
                          {/* Add your options here */}

                          <option value="ACTOR">Actor</option>
                          <option value="Story Writer">Story writer</option>
                          <option value="Lyricist">Lyricist</option>
                          <option value="Music">Music</option>
                          {/* Add more options as needed */}
                        </Select>
                      </FormControl>
                      <FormControl>
                        <FormLabel style={labelStyle}>Name</FormLabel>
                        <Input
                          name="name"
                          type="text"
                          onChange={handleInputChange}
                          value={formData.name}
                          placeholder="Name"
                          _placeholder={{ color: "#878992" }}
                          style={inputStyle}
                          w={{ base: "280px", md: "370px" }}
                        />
                      </FormControl>
                      <Flex
                        w="54px"
                        h="50px"
                        border="1px solid #3D445A"
                        borderRadius="12px"
                        justify="center"
                        align="center"
                        cursor="pointer"
                        onClick={() => handleCastState()}
                      >
                        <FaPlus color="#666874" />
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>

                {/* /
                Lower section of Form
                / */}
                {/* 
                <Flex pl="3rem">
                  <FormControl>
                    <FormLabel style={labelStyle}>Music</FormLabel>
                    <Input
                      name="music"
                      type="text"
                      onChange={handleInputChange}
                      value={formData.music}
                      placeholder="Music"
                      _placeholder={{ color: "#878992" }}
                      style={inputStyle}
                    />
                  </FormControl>
                </Flex> */}

                <Flex
                  color="rgba(43, 54, 116, 1)"
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="16px"
                  fontFamily="Roboto"
                  letterSpacing="-0.32px"
                  justifyContent="center"
                  mt="3rem"
                >
                  <Button
                    _focus={{ outline: "2px solid rgba(66, 153, 225, 0.6)" }}
                    type="submit"
                    width={{ base: "361px", md: "385px" }}
                    height={{ base: "41.34px", md: "54px" }}
                    borderRadius={{ base: "7.6px", md: "10px" }}
                    bg="rgba(255, 255, 255, 1)"
                  >
                    <Text
                      bg="linear-gradient(96.31deg, #052DB0 7.3%, #C30280 118.79%)"
                      bgClip="text"
                    >
                      {loadingWebseriesUpload || videoLoading ? (
                        <Spinner color="black" />
                      ) : (
                        <Text>Submit</Text>
                      )}
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

/*
 * EXPORT
 */

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
