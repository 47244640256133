/* eslint-disable react/prop-types */
// Import necessary libraries
import React from "react";
import Chart from "react-apexcharts";
import { Box } from "@chakra-ui/react";

const UserActivityChart = ({ data }) => {
  // Prepare data for the chart
  const chartData = {
    options: {
      chart: {
        id: "user-activity-chart",
        background: "#333", // Set chart background color
      },
      xaxis: {
        type: "category",
        categories: data?.data?.map((item) => item.Month),
        labels: {
          rotate: -45,
          style: {
            colors: "#ffffff",
          },
        },
        title: {
          text: "Months",
          style: {
            color: "#ffffff",
            fontSize: "16px", // Set x-axis title font size
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#ffffff",
            fontSize: "14px", // Set y-axis label font size
          },
        },
        title: {
          text: "Number of Users",
          style: {
            color: "#ffffff",
            fontSize: "16px", // Set y-axis title font size
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "60%", // Set column width for bars
          endingShape: "rounded", // Set bar endings to be rounded
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#63b7af"], // Set color for the bars
      fill: {
        type: "gradient", // Set fill type to gradient
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
      tooltip: {
        theme: "dark", // Set tooltip theme to dark
      },
    },
    series: [
      {
        name: "No. of Users",
        data: data?.data?.map((item) => item.Users),
      },
    ],
  };

  return (
    <Box p={4} borderWidth="1px" borderRadius="lg">
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={500}
      />
    </Box>
  );
};

export default UserActivityChart;
