/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // Npm: Apollo client for handling graphql request.

/*
 * GRAPHS
 */
const Index = gql`
  query MyQuery($contentDetailId: ID!) {
    ContentAprovedAdminDetails(contentDetailId: $contentDetailId) {
      message
      status
      Approved {
        Admin {
          id
          name
          email
          level
        }
      }
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
