
/* eslint-disable no-unused-vars */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import _ from "underscore";
import { MdOutlineFileUpload } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux"; // NPM: React-redux library.
import { useMutation, useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { IoMdDoneAll } from "react-icons/io";
/*
 * CHAKRA IMPORTS
 */
import {
  Link,
  Image,
  Flex,
  Text,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  Select,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalBody,
  useToast,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";

/*
 * MUTATAION
 */
import BrandSVGUpload from "./__mutation__/index.uploadWatermark.mutation";

/*
 * QUERY
 */
import BrandDetailsRead from "./__query__/index.brandDetailRead.query";

function Index({ account }) {
  /*
   * States
   */
  const [poster, setPoster] = useState();

  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [adDetail, setAdDetail] = useState();
  const [uploadStatus, setUploadStatus] = useState(false);
  const id = searchParams.get("id");
  const toast = useToast();
  const [adRemoveId, setAdRemoveId] = useState();

  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure();

  const [BrandSVGUploadMutation, { loading: loadingBrandSVGUpload }] =
    useMutation(BrandSVGUpload, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const { refetch: BrandDetailsReadRefetch, data: BrandDetailsReadQuery } =
    useQuery(BrandDetailsRead, {
      variables: { BrandNameId: id },
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  /*
   * STYLE
   */

  const videoBigTextStyle = {
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0.2px",
  };

  const videoBigTextStyle1 = {
    color: "rgba(116, 116, 116, 1)",
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0.2px",
  };

  /*
   * Functions
   */

  const _handleWatermarkUpload = async (e) => {
    e.preventDefault();

    try {
      const response = await BrandSVGUploadMutation({
        variables: {
          brandNameId: id,
          brandSVG: poster,
        },
      });

      const data = response.data.BrandSVGUpload;

      if (!_.isEmpty(data) && data.status === "BANNER_UPLOAD_SUCCESSFULLY") {
        toast({
          title: "Form Submitted",
          description: "Data saved successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setPoster();
        setUploadStatus(false);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  const handleRemoveClick = (id) => {
    setAdRemoveId(id);
    openModal();
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    // Check if a file is selected
    if (file) {
      setPoster(file);
      setUploadStatus(true);
    }
  };


  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  async function HandleBrandDetailsReadRefetch() {
    await BrandDetailsReadRefetch();
    // Your code to execute after the refetch is complete
  }

  useEffect(() => {
    if (BrandDetailsReadQuery && BrandDetailsReadQuery.BrandDetailsRead) {
      setAdDetail(BrandDetailsReadQuery.BrandDetailsRead);
    }
  }, [BrandDetailsReadQuery]);

  useEffect(() => {
    HandleBrandDetailsReadRefetch();
  }, [loadingBrandSVGUpload]);


  return (
    <Flex direction="column" pt="50px" pb="50px" align="center">
      <Flex direction="column">
        <form
          style={{ width: "100%" }}
          onSubmit={(e) => _handleWatermarkUpload(e)}
        >
          <Flex align="end" p="20px" w="100%">
            <FormControl>
              <FormLabel style={videoBigTextStyle}>
                <Flex gap="10px" align="center">
                  Upload brand watermark
                  <Flex>{uploadStatus ? <IoMdDoneAll /> : ""}</Flex>
                </Flex>
              </FormLabel>
              <InputGroup align="center">
                <Button
                  style={videoBigTextStyle1}
                  border="1px solid #3D445A"
                  bg="transparent"
                  _hover={{ bg: "transparent" }}
                  onClick={() => {
                    // Trigger the hidden file input when the button is clicked
                    document.getElementById("watermarkInput").click();
                  }}
                  width={{ base: "320px" }}
                  position="relative"
                >
                  <MdOutlineFileUpload
                    size="25px"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                </Button>
                {/* Hidden file input */}
                <Input
                  type="file"
                  id="watermarkInput"
                  name="thumbnailStoredAt"
                  onChange={handleImageUpload}
                  display="none"
                />
              </InputGroup>
            </FormControl>

            <Button
              bg="blue.600"
              color="white"
              w="210px"
              h="50px"
              _hover={{ bg: "blue.500" }}
              type="submit"
            >
              {loadingBrandSVGUpload ? <Spinner /> : "Save"}
            </Button>
          </Flex>
        </form>
      </Flex>

      <Flex
        overflowY="auto"
        maxHeight="700px"
        p="35px 24px"
        direction="column"
        gap="3rem"
      >
        {adDetail?.map((data, index) => (
          <Flex
            key={data.id}
            p="15px"
            borderRadius="10px"
            bg="#00020A"
            w="100%"
            gap="1rem"
            cursor="pointer"
            direction="column"
            align="center"
          >
            <Flex
              bg="white"
              w="40px"
              h="40px"
              borderRadius="50%"
              textAlign="center"
              color="white"
              justify="center"
              align="center"
            >
              <Text fontWeight="600" fontSize="20px" color="black">
                {index + 1}
              </Text>
            </Flex>
            <Flex direction="column">
              <Text style={videoBigTextStyle1}>Brand watermark :</Text>
              <Image src={data?.brandSVG} />
            </Flex>
            {/* <Button
              colorScheme="red"
              onClick={() => handleRemoveClick(data?.id)}
            >
              Remove
            </Button> */}
            {/* Remove Modal */}
            <Modal isCentered isOpen={isModalOpen} onClose={closeModal}>
              <ModalContent>
                <ModalHeader>Confirm Removal</ModalHeader>
                <ModalBody>
                  Are you sure you want to remove this video from our platform?
                </ModalBody>
                <ModalFooter gap="20px">
                  {/* <Button colorScheme="red" onClick={handleAdRemove}>
                    {loadingBrandVideoRemove ? <Spinner /> : "Yes"}
                  </Button> */}
                  <Button onClick={closeModal}>No</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
