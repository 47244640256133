/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import Navbar from "../LandingNavbar/index";
import Footer from "../LandingFooter/index";

import { connect } from "react-redux"; // NPM: React-redux library.

import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";

import Loader from "../../Views/User/website/LandingPage/Loader";
import SellerFormReadAll from "./__query__/index.sellerRequestRead.query";
/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  Image,
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
} from "@chakra-ui/react";

/*
 * QUERY
 */

function Index({ account }) {
  /*
   * States
   */

  const history = useHistory();

  const [expandedSummary, setExpandedSummary] = useState({});
  const _SellerFormReadAll = useQuery(SellerFormReadAll, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  /*
   * STYLE
   */
  const tabStyle = {
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "55.62px",
    letterSpacing: "0.32px",
  };

  const videoSmallTextStyle = {
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "22px",
  };

  const videoBigTextStyle = {
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0.2px",
  };

  /*
   * Functions
   */
  const toggleSummary = (id) => {
    setExpandedSummary((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  function formatDuration(durationInMinutes) {
    const hours = Math.floor(durationInMinutes / 60);
    const remainingMinutes = durationInMinutes % 60;

    // eslint-disable-next-line init-declarations
    let formattedDuration = "";

    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }

    if (remainingMinutes > 0) {
      formattedDuration += `${remainingMinutes}m`;
    }

    return formattedDuration.trim(); // Remove any leading/trailing whitespace
  }

  useEffect(() => {
    _SellerFormReadAll?.refetch();
  }, []);

  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
        w="100%"
        minHeight="100vh"
      >
        <Flex w="100%" h="100px"></Flex>

        <Flex mt="1rem">
          <Flex overflow="scroll" direction="column" w="100%">
            <Tabs width="100%">
              <TabList
                gap="3rem"
                padding="0px"
                style={tabStyle}
                borderColor="none"
                borderBottom="1px solid #505050"
              >
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Videos
                </Tab>
              </TabList>
              <TabIndicator
                mt="-2px"
                height="3px"
                bg="#FFFFFF"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel w="100%" padding="0px">
                  {_SellerFormReadAll?.loading ? (
                    <Flex align="center" justify="center" w="100%">
                      <Loader />
                    </Flex>
                  ) : (
                    <Flex
                      overflowY="auto"
                      maxHeight="700px"
                      p="35px 24px"
                      direction="column"
                      gap="1rem"
                    >
                      {_SellerFormReadAll?.data?.SellerFormReadAll?.length ===
                      0 ? (
                        <Flex
                          align="center"
                          justify="center"
                          w="100%"
                          h="100vh"
                          color="white"
                          fontSize="60px"
                          fontWeight="700"
                        >
                          No Videos Found
                        </Flex>
                      ) : (
                        <>
                          {_SellerFormReadAll?.data?.SellerFormReadAll?.map(
                            (data, index) => (
                              <Flex
                                p="15px"
                                key={data.id}
                                borderRadius="10px"
                                bg="#000000"
                                w="100%"
                                gap="1rem"
                                onClick={() =>
                                  history.push(
                                    `/seller-form-approval?id=${data.id}`
                                  )
                                }
                                cursor="pointer"
                              >
                                <Flex color="white">{index + 1}</Flex>
                                <Flex w="256px" h="158px">
                                  <Image
                                    width="100%"
                                    height="100%"
                                    src={data?.thumbnailStoredAt}
                                  />
                                </Flex>
                                <Flex gap=".5rem" direction="column">
                                  <Text style={videoBigTextStyle}>
                                    {data?.contentTitle}
                                  </Text>
                                  <Text w="850px" style={videoSmallTextStyle}>
                                    {data?.plotSummary}
                                  </Text>
                                  <Flex gap=".5rem" direction="column">
                                    <Flex align="center" gap="1rem">
                                      <Text
                                        color="rgba(69, 211, 105, 1)"
                                        fontFamily="Roboto"
                                        fontSize="19px"
                                        fontWeight="700"
                                        lineHeight="21.54px"
                                        letterSpacing="-0.36px"
                                      >
                                        {new Date(
                                          data?.createdAt
                                        ).getFullYear()}
                                      </Text>
                                      <Text
                                        color="rgba(186, 186, 186, 1)"
                                        fontFamily="Roboto"
                                        fontSize="19px"
                                        fontWeight="400"
                                        lineHeight="21.54px"
                                        letterSpacing="-0.36px"
                                      >
                                        {formatDuration(data?.duration)}
                                      </Text>
                                      <Flex
                                        borderRadius="3px"
                                        justify="center"
                                        align="center"
                                        h="19px"
                                        border="1px solid rgba(116, 116, 116, 1)"
                                        color="rgba(255, 255, 255, 1)"
                                        fontFamily="Roboto"
                                        fontWeight="400"
                                        fontSize="12px"
                                        lineHeight="21.54px"
                                      >
                                        HD
                                      </Flex>
                                    </Flex>
                                    <Flex align="center" gap="1rem">
                                      <Flex
                                        color="white"
                                        w="70px"
                                        h="20px"
                                        justify="center"
                                        align="center"
                                        border="1px solid rgba(116, 116, 116, 1)"
                                      >
                                        {`${
                                          data?.ageRating === "18" ? "A" : "U/A"
                                        } ${data?.ageRating}+`}
                                      </Flex>
                                      <Text style={videoSmallTextStyle}>
                                        <span
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {data?.genre}
                                        </span>
                                      </Text>
                                    </Flex>
                                  </Flex>
                                </Flex>
                                <Flex ml="15rem" gap=".5rem" direction="column">
                                  <Flex gap=".5rem" direction="column">
                                    <Text style={videoSmallTextStyle}>
                                      Tray Title :{" "}
                                      <span
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {data?.contentType}
                                      </span>
                                    </Text>
                                    <Flex align="center" gap="1rem">
                                      <Text color="#5A2CC1">Status</Text>
                                      <Text color="yellow">Pending</Text>
                                    </Flex>
                                  </Flex>
                                </Flex>
                              </Flex>
                            )
                          )}
                        </>
                      )}
                    </Flex>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
