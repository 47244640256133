/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation($accountId: ID!) {
    SpecificUserfetch(accountId: $accountId) {
      status
      message
      id
      MyList {
        id
      }
      lowerThumbnailLink
      likes
      watchTime
      ContentSummary {
        contentTitle
        ageRating
        genre
      }
      createdAt
      duration
      plotSummary

    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
