/* eslint-disable capitalized-comments */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
// MovieDetail.js

/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import _ from "underscore";
import { FiEdit3 } from "react-icons/fi";
import { MdOutlineFileUpload, MdErrorOutline } from "react-icons/md";
import { IoCloseCircleSharp, IoCheckmarkDoneSharp } from "react-icons/io5";
import { v4 as uuidv4 } from "uuid";

/*
 * CHAKRA UI IMPORT
 */
import {
  Image,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  useToast,
  ModalBody,
  Text,
  Button,
  Flex,
  Box,
  Spinner,
  Select,
  FormControl,
  FormLabel,
  useDisclosure,
  ModalFooter,
  ModalHeader,
  ModalCloseButton,
  Textarea,
  InputGroup,
  AspectRatio,
} from "@chakra-ui/react";
import ReactPlayer from "react-player";

/*
 * MUTATION
 */

/*
 * QUERY
 */

/*
 * OBJECT
 */
const MovieDetail = ({ item, isOpen, onClose }) => {
  /*
   * STATE
   */
  const [expandedSummary, setExpandedSummary] = useState({});
  const { isOpenPlotSummary, onClosePlotSummary } = useDisclosure();
  const { isOpenProducer, onCloseProducer } = useDisclosure();
  const { isOpenDirector, onCloseDirector } = useDisclosure();

  const toggleSummary = (id) => {
    setExpandedSummary((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };


  function formatDuration(durationInMinutes) {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.floor(durationInMinutes % 60);
    const seconds = Math.floor((durationInMinutes % 1) * 60);
  
    // eslint-disable-next-line init-declarations
    let formattedDuration = "";
  
    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }
  
    if (minutes > 0) {
      formattedDuration += `${minutes}m `;
    }
  
    if (seconds > 0) {
      formattedDuration += `${seconds}s`;
    }
  
    return formattedDuration.trim(); // Remove any leading/trailing whitespace
  }

  const textStyle = {
    color: "rgba(255, 255, 255, 1)",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "21.54px",
  };
  const videoTextStyle = {
    color: "#7D798E",
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "17.04px",
    borderColor: "blue.500",
  };
  const videoBigTextStyle = {
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0.2px",
  };
  const sellbutton = {
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "31.74px",
    letterSpacing: "-0.2px",
    color: "#D9D9D9",
    cursor: "pointer",
  };

  /*
   * FUNCTION
   */

  /*
   * USE EFFECT
   */

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalBody borderRadius="5px" bg="rgba(0, 2, 10, 1)" padding="0px">
          <Box
            borderRadius="10px"
            position="relative"
            overflow="hidden"
            bg="black"
            h="30rem"
          >
            <Flex position="absolute" w="100%" h="100%">
              <Image
                w="100%"
                h="100%"
                objectFit="fill"
                src={item?.SellForm?.thumbnailStoredAt}
                alt="image"
              />
            </Flex>
            <Flex
              position="absolute"
              h="100%"
              w="100%"
              alignItems="center"
              bg="linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.66) 30.6%, rgba(0, 0, 0, 0.00) 103.12%)"
            >
              <Flex
                h="100%"
                flexDir="column"
                ml="2rem"
                justify="flex-end"
                gap="20px"
              >
                <Flex
                  pb="15px"
                  color="rgba(255, 255, 255, 1)"
                  fontFamily="Oswald"
                  fontWeight="700"
                  fontSize={{ base: "35px", md: "50px" }}
                  lineHeight="45px"
                  letterSpacing="-0.75px"
                  gap="8px"
                  align="end"
                >
                  {item?.SellForm?.contentTitle}
                  {/* <FiEdit3
                    size="30px"
                    cursor="pointer"
                 
                  /> */}
                </Flex>

                <Flex align="center" gap="1rem">
                  <IoCloseCircleSharp
                    color="#979898"
                    cursor="pointer"
                    onClick={onClose}
                    size="50px"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Box>
          <Flex
            direction="column"
            align="center"
            bg="rgba(0, 2, 10, 1)"
            justify="center"
          >
            <Flex
              m="15px 0px 15px 0px"
              borderRadius="10px"
              bg="rgba(39, 39, 39, 1)"
              w="90%"
              direction="column"
              justify="space-between"
              p="20px"
              gap="25px"
            >
              <Text
                color="rgba(255, 255, 255, 1)"
                fontFamily="Roboto"
                fontSize="20px"
                fontWeight="700"
                lineHeight="21.54px"
                letterSpacing="0.2px"
              >
                About Movie
              </Text>
              <Flex w="100%" justify="space-between">
                <Flex justify="center" gap="30px" w="55%" direction="column">
                  <Text
                    color="rgba(255, 255, 255, 1)"
                    fontFamily="Roboto"
                    fontSize="20px"
                    fontWeight="700"
                    lineHeight="21.54px"
                    letterSpacing="0.2px"
                  >
                    {item?.SellForm?.contentTitle}
                  </Text>
                  <Flex align="center" gap="8px">
                    <Text
                      color="rgba(69, 211, 105, 1)"
                      fontFamily="Roboto"
                      fontSize="19px"
                      fontWeight="700"
                      lineHeight="21.54px"
                      letterSpacing="-0.36px"
                    >
                      {new Date(item?.SellForm?.createdAt).getFullYear()}
                    </Text>
                    <Text
                      color="rgba(186, 186, 186, 1)"
                      fontFamily="Roboto"
                      fontSize="19px"
                      fontWeight="400"
                      lineHeight="21.54px"
                      letterSpacing="-0.36px"
                    >
                      {formatDuration(item?.SellForm?.duration)}
                    </Text>
                    <Flex
                      borderRadius="3px"
                      justify="center"
                      align="center"
                      w="32px"
                      h="19px"
                      border="1px solid rgba(116, 116, 116, 1)"
                      color="rgba(255, 255, 255, 1)"
                      fontFamily="Roboto"
                      fontWeight="400"
                      fontSize="12px"
                      lineHeight="21.54px"
                    >
                      HD
                    </Flex>
                  </Flex>
                  <Flex align="center" gap="5px">
                    <Flex
                      color="white"
                      w="70px"
                      h="20px"
                      justify="center"
                      align="center"
                      border="1px solid rgba(116, 116, 116, 1)"
                    >
                      {`${item?.SellForm?.ageRating === "18" ? "A" : "U/A"} ${
                        item?.SellForm?.ageRating
                      }+`}
                    </Flex>
                    {item?.SellForm?.ageRating === "18" ? (
                      <Text style={textStyle}>
                        <span style={{ textTransform: "capitalize" }}>
                          gore, language, mature themes, violence
                        </span>
                      </Text>
                    ) : (
                      <></>
                    )}
                  </Flex>
                  <Text style={textStyle}>
                    <Flex
                      fontWeight="800"
                      fontSize="20px"
                      align="center"
                      gap="8px"
                    >
                      Plot Summary{" "}
                    </Flex>
                    {item?.SellForm?.plotSummary}
                    {/* {expandedSummary[
                      movieDetails?.ContentSummary?.ContentDetail[0]?.id
                    ] ? (
                      <span>
                        {
                          movieDetails?.ContentSummary?.ContentDetail[0]
                            ?.plotSummary
                        }
                        <Text
                          color="rgba(0, 129, 223, 1)"
                          cursor="pointer"
                          onClick={() =>
                            toggleSummary(
                              movieDetails?.ContentSummary?.ContentDetail[0]?.id
                            )
                          }
                        >
                          read less
                        </Text>
                      </span>
                    ) : (
                      <span>
                        {movieDetails?.ContentSummary?.ContentDetail[0]?.plotSummary
                          ?.split(" ")
                          ?.slice(0, 20)
                          ?.join(" ")}
                        {movieDetails?.ContentSummary?.ContentDetail[0]?.plotSummary?.split(
                          " "
                        ).length > 20 && (
                          <Text
                            color="rgba(0, 129, 223, 1)"
                            cursor="pointer"
                            onClick={() =>
                              toggleSummary(
                                movieDetails?.ContentSummary?.ContentDetail[0]
                                  ?.id
                              )
                            }
                          >
                            read more
                          </Text>
                        )}
                      </span>
                    )} */}
                  </Text>
                </Flex>
                <Flex gap="20px" style={textStyle} w="40%" direction="column">
                  <Text>
                    <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                      Content Type:
                    </span>
                    <span style={{ textTransform: "capitalize" }}>
                      {item?.SellForm?.contentType}
                    </span>
                  </Text>
                  <Text>
                    <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                      Cast:
                    </span>{" "}
                    {item?.SellForm?.cast?.map((data) => (
                      <span
                        key={data.id}
                        style={{ textTransform: "capitalize" }}
                      >
                        {data.name},{" "}
                      </span>
                    ))}
                  </Text>
                  <Text>
                    <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                      Genres:
                    </span>
                    <span style={{ textTransform: "capitalize" }}>
                      {item?.SellForm?.genre}
                    </span>
                  </Text>
                  <Text>
                    <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                      Content language:
                    </span>
                    <span style={{ textTransform: "capitalize" }}>
                      {` ${item?.SellForm?.contentLanguage}`}
                    </span>
                  </Text>
                  <Flex align="center" gap="8px">
                    <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                      Producer / Produnction House:
                    </span>
                    <span style={{ textTransform: "capitalize" }}>
                      {item?.SellForm?.producer}
                    </span>
                  </Flex>

                  <Flex align="center" gap="8px">
                    <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                      Director:
                    </span>
                    <span style={{ textTransform: "capitalize" }}>
                      {item?.SellForm?.directorName}
                    </span>
                  </Flex>
                  <Flex align="center" gap="8px">
                    <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                      Music:
                    </span>
                    <span style={{ textTransform: "capitalize" }}>
                      {item?.SellForm?.music}
                    </span>
                  </Flex>
                  <Flex align="center" gap="8px">
                    <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                      Story Writer:
                    </span>
                    <span style={{ textTransform: "capitalize" }}>
                      {item?.SellForm?.storyWriter}
                    </span>
                  </Flex>
                </Flex>
              </Flex>
              <Flex direction={"column"}>
                <Text
                  color="rgba(255, 255, 255, 1)"
                  fontFamily="Roboto"
                  fontSize="20px"
                  fontWeight="700"
                  lineHeight="21.54px"
                  letterSpacing="0.2px"
                >
                  About Seller
                </Text>
                <Flex justify={"space-between"} padding={"10px"}>
                  <Flex direction={"column"}>
                    {" "}
                    <Text>
                      <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                        Name :
                      </span>
                      <span
                        style={{ textTransform: "capitalize", color: "#fff" }}
                      >
                        {` ${item?.SellForm?.firstName} ${item?.SellForm?.lastName}`}
                      </span>
                    </Text>
                    <Text>
                      <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                        Email:
                      </span>
                      <span
                        style={{ textTransform: "capitalize", color: "#fff" }}
                      >
                        {` ${item?.SellForm?.email}`}
                      </span>
                    </Text>
                    <Text>
                      <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                        Phone:
                      </span>
                      <span
                        style={{ textTransform: "capitalize", color: "#fff" }}
                      >
                        {` ${item?.SellForm?.phone}`}
                      </span>
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

/*
 * EXPORT
 */
export default MovieDetail;
