
/* eslint-disable array-element-newline */
/* eslint-disable array-bracket-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable max-lines-per-function */
/*
 * IMPORT
 */
import React, { useEffect, useState } from "react";
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";
import { connect } from "react-redux"; // NPM: React-redux library.
import { useMutation, useQuery } from "@apollo/client";
import _ from "underscore";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { MdOutlineFileUpload, MdErrorOutline } from "react-icons/md";
import { useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./myEditor.css";
import { TagsInput } from "react-tag-input-component";
import { tabStyle , videoBigTextStyle } from "../../../../components/Styles/index.style"


/*
 * CHAKRA IMPORTS
 */
import {
  Flex,
  Box,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  FormControl,
  FormLabel,
  Input,
  Image,
  useToast,
  Spinner,
  InputGroup,
  Textarea,
  Text,
} from "@chakra-ui/react";

/*
 * MUTATAION
 */
import BlogsCreate from "../__mutation__/index.blogsCreate.mutation";

/*
 * QUERY
 */
import ReadAllBlogs from "../__query__/index.getAllBlogs.query";

/*
 * OBJECT
 */
function Index({ account }) {
  /*
   * States
   */
  const toast = useToast();
  const history = useHistory();
  const [imageUploadStatus, setImageUploadStatus] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [values, setValues] = useState("");
  const [selected, setSelected] = useState([]);

  const [formData, setFormData] = useState({
    heading: "",
    contentTitle: "",
    author: "",
    imageStoredAt: "", 
    metaTitle: "",
    metaDescription: "",
  });

  const _getBlogs = useQuery(ReadAllBlogs);
  const [BlogsCreateMutation, { loading: loadingBlogs }] = useMutation(
    BlogsCreate,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );

  /*
   * STYLE
   */
  

  /*
   * Functions
   */

  const handleHover = (index) => {
    setHoveredIndex(index);
  };

  const handleLeave = () => {
    setHoveredIndex(null);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        imageStoredAt: file,
      }));
      setImageUploadStatus("success");
    } else {
      setImageUploadStatus("error");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBlogCreate = async (e) => {
    e.preventDefault();

    try {
      const response = await BlogsCreateMutation({
        variables: {
          bulletpoints: "",
          content: values,
          heading: formData.heading,
          author: formData.author,
          horizontalImageFile: formData.imageStoredAt,
          title: formData.contentTitle,
          metaTitle: formData.metaTitle,
          metaDescription: formData.metaDescription,
          keywords: selected,
          verticalImageFile: "",
        },
      });

      const data = response.data.BlogsCreate;

      if (!_.isEmpty(data) && data.status === "BLOG__CREATED") {
        toast({
          title: "Form Submitted",
          description: "Data saved successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setFormData({
          heading: "",
          contentTitle: "",
          metaTitle: "",
          metaDescription: "",
          author: "",
          imageStoredAt: "",
        });
        setValues("");
        setSelected([]);
        setImageUploadStatus("");
        _getBlogs?.refetch();
      }
    } catch (error) {
      // Show error toast
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };

    return date.toLocaleDateString("en-US", options);
  };

  /*
   * UseEffect
   */

  useEffect(() => {
    _getBlogs?.refetch();
  }, []);

  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
        w="100%"
        minHeight="100vh"
      >
        <Flex w="100%" h="100px"></Flex>

        <Flex mt="1rem">
          <Flex overflow="scroll" direction="column" w="100%">
            <Tabs width="100%">
              <TabList
                gap="3rem"
                padding="0px"
                style={tabStyle}
                borderColor="none"
                borderBottom="1px solid #505050"
              >
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Upload Blogs
                </Tab>
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Blogs List
                </Tab>
              </TabList>
              <TabIndicator
                mt="-2px"
                height="3px"
                bg="#FFFFFF"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel w="100%" padding="0px">
                  <Flex direction="column" pt="50px" pb="50px" align="center">
                    <form
                      style={{ width: "80%" }}
                      onSubmit={(e) => handleBlogCreate(e)}
                    >
                      <Flex
                        borderRadius="20px"
                        w="100%"
                        h="100%"
                        border="2px solid purple"
                        p="40px"
                        gap="50px"
                        justify="space-evenly"
                        direction="column"
                      >
                        <Flex align="center">
                          <FormControl isRequired>
                            <FormLabel
                              style={videoBigTextStyle}
                              htmlFor="heading"
                            >
                              Heading:
                            </FormLabel>
                            <Input
                              w="500px"
                              h="50px"
                              borderColor="rgba(116, 116, 116, 1)"
                              placeholder="Heading"
                              color="white"
                              type="text"
                              id="heading"
                              name="heading"
                              value={formData.heading}
                              onChange={handleChange}
                              required
                            />
                          </FormControl>
                          <FormControl isRequired>
                            <FormLabel
                              style={videoBigTextStyle}
                              htmlFor="contentTitle"
                            >
                              Title:
                            </FormLabel>
                            <Input
                              w="500px"
                              h="50px"
                              borderColor="rgba(116, 116, 116, 1)"
                              placeholder="Content Title"
                              color="white"
                              type="text"
                              id="contentTitle"
                              name="contentTitle"
                              value={formData.contentTitle}
                              onChange={handleChange}
                              required
                            />
                          </FormControl>
                        </Flex>
                        <Flex align="center">
                          <FormControl isRequired>
                            <Flex align="center">
                              <FormLabel style={videoBigTextStyle}>
                                Upload Image
                              </FormLabel>
                              {imageUploadStatus === "success" ? (
                                <IoCheckmarkDoneSharp color="white" />
                              ) : imageUploadStatus === "error" ? (
                                <MdErrorOutline color="white" />
                              ) : (
                                ""
                              )}
                            </Flex>

                            <InputGroup align="center">
                              <Button
                                border="1px solid #3D445A"
                                w="500px"
                                h="50px"
                                borderColor="rgba(116, 116, 116, 1)"
                                bg="transparent"
                                _hover={{ bg: "grey" }}
                                onClick={() => {
                                  // Trigger the hidden file input when the button is clicked
                                  document
                                    .getElementById("imageUpload")
                                    .click();
                                }}
                                position="relative"
                              >
                                <MdOutlineFileUpload
                                  size="25px"
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    color: "white",
                                    _hover: { color: "black" },
                                  }}
                                />
                              </Button>
                              {/* Hidden file input */}
                              <Input
                                type="file"
                                id="imageUpload"
                                name="imageStoredAt"
                                onChange={handleImageUpload}
                                display="none"
                              />
                            </InputGroup>
                          </FormControl>
                          <FormControl isRequired>
                            <FormLabel
                              style={videoBigTextStyle}
                              htmlFor="author"
                            >
                              Author:
                            </FormLabel>
                            <Input
                              w="500px"
                              h="50px"
                              borderColor="rgba(116, 116, 116, 1)"
                              placeholder="Author"
                              color="white"
                              type="text"
                              id="author"
                              name="author"
                              value={formData.author}
                              onChange={handleChange}
                              required
                            />
                          </FormControl>
                        </Flex>
                        <Flex>
                          <FormControl isRequired>
                            <FormLabel
                              style={videoBigTextStyle}
                              htmlFor="metaTitle"
                            >
                              Meta Title:
                            </FormLabel>
                            <Input
                              w="500px"
                              h="50px"
                              borderColor="rgba(116, 116, 116, 1)"
                              placeholder="Meta Title"
                              color="white"
                              type="text"
                              id="metaTitle"
                              name="metaTitle"
                              value={formData.metaTitle}
                              onChange={handleChange}
                              required
                            />
                          </FormControl>
                          <FormControl isRequired>
                            <FormLabel
                              style={videoBigTextStyle}
                              htmlFor="metaDescription"
                            >
                              Meta Description:
                            </FormLabel>
                            <Input
                              w="500px"
                              h="50px"
                              borderColor="rgba(116, 116, 116, 1)"
                              placeholder="Meta Description"
                              color="white"
                              type="text"
                              id="metaDescription"
                              name="metaDescription"
                              value={formData.metaDescription}
                              onChange={handleChange}
                              required
                            />
                          </FormControl>
                        </Flex>
                        <FormControl isRequired>
                          <FormLabel style={videoBigTextStyle} htmlFor="tags">
                            Keywords:
                          </FormLabel>
                          <TagsInput
                            value={selected}
                            onChange={setSelected}
                            name="fruits"
                            placeHolder="enter fruits"
                          />
                        </FormControl>

                        <FormControl isRequired>
                          <FormLabel
                            style={videoBigTextStyle}
                            htmlFor="content"
                          >
                            Content:
                          </FormLabel>
                          <ReactQuill
                            theme="snow"
                            value={values}
                            onChange={setValues}
                            className="ql-container"
                          />
                        </FormControl>

                        <Button type="submit" mt={4} colorScheme="teal">
                          {loadingBlogs ? <Spinner /> : "Create"}
                        </Button>
                      </Flex>
                    </form>
                  </Flex>
                </TabPanel>
                <TabPanel w="100%" padding="0px">
                  <Flex
                    justify="center"
                    gap="50px"
                    p="50px"
                    align="center"
                    direction="column"
                  >
                    {_getBlogs?.data?.ReadAllBlogs?.map((data, index) => (
                      <Flex
                        key={data?.id}
                        w="100%"
                        p="10px"
                        gap="20px"
                        bg="transparent"
                        position="relative"
                        boxShadow={
                          hoveredIndex === index
                            ? "0px 0px 20px 5px rgba(255, 255, 255, 0.8)"
                            : "0px 0px 5px 1px white"
                        }
                        cursor="pointer"
                        onClick={() =>
                          history.push(`/blog-page?id=${data?.id}`)
                        }
                        onMouseEnter={() => handleHover(index)}
                        onMouseLeave={handleLeave}
                        transition="box-shadow 0.2s ease-in-out" // Apply transition to box-shadow
                      >
                        <Flex w="35%" h="350px">
                          <Image
                            objectFit="fill"
                            w="100%"
                            h="100%"
                            src={data?.horizontalImage}
                            alt="image"
                          />
                        </Flex>
                        <Flex
                          gap="20px"
                          direction="column"
                          justify="center"
                          w="60%"
                        >
                          <Text
                            fontFamily="Poppins"
                            fontSize="30px"
                            fontWeight="700"
                            color="white"
                          >
                            {data?.heading}
                          </Text>
                          <Text
                            fontFamily="Poppins"
                            fontSize="20px"
                            fontWeight="400"
                            color="white"
                          >
                            {data?.title}
                          </Text>
                          {/* <Flex w="70%">
                            <div
                              dangerouslySetInnerHTML={{ __html: data.content }}
                            />
                          </Flex> */}
                        </Flex>
                        <Flex pos="absolute" bottom="5" right="5">
                          <Text color="white">
                            {formatDate(data?.createdAt)}
                          </Text>
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
