/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { connect } from "react-redux"; // NPM: React-redux library.
import { MdOutlineFileUpload } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import _ from "underscore";
import { videoBigTextStyle, videoBigTextStyle1 } from "../../../../components/Styles/index.style"

/*
 * CHAKRA IMPORTS
 */
import {
  Image,
  Flex,
  Text,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  InputGroup,
  useToast,
  Spinner,
  FormControl,
  FormLabel,
  Link,
  useDisclosure,
} from "@chakra-ui/react";

/*
 * QUERY
 */
import HomePageAdsread from "../__query__/index.homeAdFetch.query";

/*
 * MUTATAION
 */
import HomePageAdsDelete from "../__mutation__/index.homePageAdDelete.mutation";
import HomePageAdsCreate from "../__mutation__/index.homeAdUpload.mutation";

function Index({ account }) {
  /*
   * States
   */

  const toast = useToast();

  const [adUrl, setAdUrl] = useState();
  const [adList, setAdList] = useState();
  const [adName, setAdName] = useState();
  const [poster, setPoster] = useState();
  const [posterMovie, setPosterMovie] = useState();
  const [posterDeleteId, setPosterDeleteId] = useState();
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure();

  const [HomePageAdsDeleteMutation, { loading: loadingHomePageAdsDelete }] =
    useMutation(HomePageAdsDelete, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const [HomePageAdsCreateMutation, { loading: loadingHomeAdUpload }] =
    useMutation(HomePageAdsCreate, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const { refetch: HomePageAdsreadRefetch, data: HomePageAdsreadQuery } =
    useQuery(HomePageAdsread, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  /*
   * STYLE
   */
 

  /*
   * Functions
   */

  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    // Check if a file is selected
    if (file) {
      const generatedUniqueId = uuidv4();

      setPoster({
        ...poster,
        thumbnailStoredAt: file,
        uniqueId: generatedUniqueId,
      });
    }
  };

  const handleImageVerticalUpload = (e) => {
    const file = e.target.files[0];

    // Check if a file is selected
    if (file) {
      const generatedUniqueId = uuidv4();

      setPosterMovie({
        ...posterMovie,
        thumbnailStoredAt: file,
        uniqueId: generatedUniqueId,
      });
    }
  };

  const handleAdUrlChange = (e) => {
    setAdUrl(e.target.value);
  };

  const handleAdNameChange = (e) => {
    setAdName(e.target.value);
  };

  const handleHomeAdUpload = async (e) => {
    e.preventDefault();

    try {
      const response = await HomePageAdsCreateMutation({
        variables: {
          adsPoster: poster,
          adsUrl: adUrl,
          verticalPoster: posterMovie,
          brandName: adName,
        },
      });

      const data = response.data.HomePageAdsCreate;

      if (!_.isEmpty(data)) {
        toast({
          title: "Form Submitted",
          description: "Data saved successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setAdUrl("");
        setPoster("");
        setPosterMovie("");
      }
    } catch (error) {
      // Show error toast
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  const handleAdDelete = async () => {
    try {
      const response = await HomePageAdsDeleteMutation({
        variables: {
          homePageAdsId: posterDeleteId,
        },
      });

      const data = response.data.HomePageAdsDelete;

      if (!_.isEmpty(data)) {
        toast({
          title: "Successfully deleted",
          description: "Data saved successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        closeModal();
      }
    } catch (error) {
      // Show error toast
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  const handleRemoveClick = (id) => {
    setPosterDeleteId(id);
    openModal(); // Open the modal
  };

  async function HandleHomePageAdsreadRefetch() {
    await HomePageAdsreadRefetch();
  }

  useEffect(() => {
    HandleHomePageAdsreadRefetch();
  }, [loadingHomePageAdsDelete, loadingHomeAdUpload]);

  useEffect(() => {
    if (HomePageAdsreadQuery && HomePageAdsreadQuery.HomePageAdsread) {
      setAdList(HomePageAdsreadQuery?.HomePageAdsread);
    }
  }, [HomePageAdsreadQuery]);

  return (
    <Flex direction="column" pt="50px" pb="50px" align="center">
      <Flex direction="column">
        <form style={{ width: "100%" }} onSubmit={(e) => handleHomeAdUpload(e)}>
          <Flex align="end" p="20px" w="100%">
            <FormControl>
              <FormLabel style={videoBigTextStyle}>Upload Ads</FormLabel>
              <InputGroup align="center">
                <Button
                  style={videoBigTextStyle1}
                  border="1px solid #3D445A"
                  bg="transparent"
                  _hover={{ bg: "transparent" }}
                  onClick={() => {
                    // Trigger the hidden file input when the button is clicked
                    document.getElementById("thumbnailInput").click();
                  }}
                  width={{ base: "320px" }}
                  position="relative"
                >
                  <MdOutlineFileUpload
                    size="25px"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                </Button>
                {/* Hidden file input */}
                <Input
                  type="file"
                  id="thumbnailInput"
                  name="thumbnailStoredAt"
                  onChange={handleImageUpload}
                  display="none"
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel style={videoBigTextStyle}>
                Upload Vertical Ads
              </FormLabel>
              <InputGroup align="center">
                <Button
                  style={videoBigTextStyle1}
                  border="1px solid #3D445A"
                  bg="transparent"
                  _hover={{ bg: "transparent" }}
                  onClick={() => {
                    // Trigger the hidden file input when the button is clicked
                    document.getElementById("thumbnailVerticalInput").click();
                  }}
                  width={{ base: "320px" }}
                  position="relative"
                >
                  <MdOutlineFileUpload
                    size="25px"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                </Button>
                {/* Hidden file input */}
                <Input
                  type="file"
                  id="thumbnailVerticalInput"
                  name="thumbnailStoredAt"
                  onChange={handleImageVerticalUpload}
                  display="none"
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel style={videoBigTextStyle}>Ads Url</FormLabel>
              <InputGroup>
                <Input
                  width="320px"
                  borderColor="rgba(116, 116, 116, 1)"
                  color="white"
                  placeholder="Url"
                  value={adUrl}
                  onChange={handleAdUrlChange}
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel style={videoBigTextStyle}>Ads Name</FormLabel>
              <InputGroup>
                <Input
                  width="320px"
                  borderColor="rgba(116, 116, 116, 1)"
                  color="white"
                  placeholder="Url"
                  value={adName}
                  onChange={handleAdNameChange}
                />
              </InputGroup>
            </FormControl>

            <Button
              bg="blue.600"
              color="white"
              w="210px"
              h="50px"
              _hover={{ bg: "blue.500" }}
              type="submit"
            >
              {loadingHomeAdUpload ? <Spinner /> : "Save"}
            </Button>
          </Flex>
        </form>
        <Flex
          gap="20px"
          align="center"
          borderRadius="10px"
          border="1px solid #878992"
          p="20px"
        >
          <Flex>
            {poster ? (
              <Image
                src={
                  !!poster?.thumbnailStoredAt
                    ? URL.createObjectURL(poster?.thumbnailStoredAt)
                    : ""
                }
              />
            ) : (
              <Text style={videoBigTextStyle}>No poster</Text>
            )}
          </Flex>
          <Flex>
            {posterMovie ? (
              <Image
                src={
                  !!posterMovie?.thumbnailStoredAt
                    ? URL.createObjectURL(posterMovie?.thumbnailStoredAt)
                    : ""
                }
              />
            ) : (
              <Text style={videoBigTextStyle}>No poster</Text>
            )}
          </Flex>
          <Link style={videoBigTextStyle} href={adUrl} target="_blank">
            {adUrl || "No url provided"}
          </Link>
          <Text style={videoBigTextStyle}>{adName || "No name provided"}</Text>
        </Flex>
      </Flex>

      <Flex
        overflowY="auto"
        maxHeight="700px"
        p="35px 24px"
        direction="column"
        gap="3rem"
      >
        {adList?.map((data, index) => (
          <Flex
            key={data.id}
            p="15px"
            borderRadius="10px"
            bg="#000000"
            w="100%"
            gap="1rem"
            cursor="pointer"
            direction="column"
            align="center"
          >
            <Flex
              bg="white"
              w="40px"
              h="40px"
              borderRadius="50%"
              textAlign="center"
              color="white"
              justify="center"
              align="center"
            >
              <Text fontWeight="600" fontSize="20px" color="black">
                {index + 1}
              </Text>
            </Flex>
            <Flex direction="column">
              <Text style={videoBigTextStyle1}>Brand poster :</Text>
              <Image src={data?.adsPoster} />
            </Flex>
            <Flex direction="column">
              <Text style={videoBigTextStyle1}>Brand poster vertical :</Text>
              <Image src={data?.verticalAdsPoster} />
            </Flex>
            <Flex mt="1rem" w="100%" direction="column">
              <Text style={videoBigTextStyle1}>Brand url :</Text>
              <Link
                href={data?.adsUrl}
                target="_blank"
                color="blue.600"
                style={videoBigTextStyle}
              >
                {data?.adsUrl}
              </Link>
            </Flex>
            <Flex mt="1rem" w="100%" direction="column">
              <Text style={videoBigTextStyle1}>Brand name :</Text>
              <Text style={videoBigTextStyle}>{data?.brandName}</Text>
            </Flex>

            <Button
              colorScheme="red"
              onClick={() => handleRemoveClick(data?.id)}
            >
              Remove
            </Button>
            {/* Remove Modal */}
            <Modal isOpen={isModalOpen} onClose={closeModal} isCentered>
              <ModalContent>
                <ModalHeader>Confirm Removal</ModalHeader>
                <ModalBody>
                  Are you sure you want to remove this poster from our platform?
                </ModalBody>
                <ModalFooter gap="20px">
                  <Button colorScheme="red" onClick={handleAdDelete}>
                    {loadingHomePageAdsDelete ? <Spinner /> : "Yes"}
                  </Button>
                  <Button onClick={closeModal}>No</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
