/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";
import { useLazyQuery } from "@apollo/client";
import { connect } from "react-redux"; // NPM: React-redux library.
import Loader from "../LandingPage/Loader";
import Chart from "./Chart";
import _ from "underscore";
import { tabStyle , videoSmallTextStyle } from "../../../../components/Styles/index.style"


/*
 * CHAKRA IMPORTS
 */
import {
  useToast,
  Box,
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  Input,
  Avatar,
} from "@chakra-ui/react";

/*
 * QUERY
 */
import AllaccountDetails from "../__query__/index.totalUser.query";
import AccountChartDetails from "../__query__/index.userChart.query";

function Index({ account }) {
  /*
   * States
   */

  const [list, setList] = useState();
  const toast = useToast();
  const [searchQuery, setSearchQuery] = useState("");
  const [chartData, setChartData] = useState();
  const [AllaccountDetailsQuery, { loading: loadingAllaccountDetails }] =
    useLazyQuery(AllaccountDetails, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });
  const [AccountChartDetailsQuery] =
    useLazyQuery(AccountChartDetails, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  /*
   * STYLE
   */
  

  /*
   * Functions
   */
  function formatDate(dateString) {
    const date = new Date(dateString);

    const formattedDate = () =>
      date.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });

    return formattedDate();
  }

  const handleUserList = async () => {
    try {
      const response = await AllaccountDetailsQuery({
        variables: {},
      });

      const data = response.data.AllaccountDetails;

      if (!_.isEmpty(data)) {
        setList(data);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred .",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };
  const handleUserChart = async () => {
    try {
      const response = await AccountChartDetailsQuery({
        variables: {},
      });

      const data = response.data.AccountChartDetails;

      if (!_.isEmpty(data)) {
        setChartData(data);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred .",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    handleUserList();
    handleUserChart();
  }, []);

  // Filter the list based on searchQuery
  const filteredList = list?.filter((data) =>
    data.fullName.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
        w="100%"
        minHeight="100vh"
      >
        <Flex w="100%" h="100px"></Flex>

        <Flex mt="1rem">
          <Flex overflow="scroll" direction="column" w="100%">
            <Tabs padding="0px" width="100%">
              <TabList
                gap="3rem"
                padding="0px"
                style={tabStyle}
                borderColor="none"
                borderBottom="1px solid #505050"
              >
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Users List
                </Tab>
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Chart
                </Tab>
              </TabList>
              <TabIndicator
                mt="-2px"
                height="3px"
                bg="#FFFFFF"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel w="100%" padding="0px">
                  <Flex mt="1rem" alignItems="center" justify="center">
                    <Input
                      color="white"
                      placeholder="Search"
                      width="420px"
                      value={searchQuery} // Value of the input field is controlled by searchQuery state
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </Flex>
                  {loadingAllaccountDetails ? (
                    <Flex align="center" justify="center" w="100%">
                      <Loader />
                    </Flex>
                  ) : (
                    <Flex
                      overflowY="auto"
                      maxHeight="700px"
                      p="35px 24px"
                      direction="column"
                      gap="1rem"
                    >
                      {filteredList?.map((data, index) => (
                        <Flex
                          p="15px"
                          key={data.id}
                          borderRadius="10px"
                          bg="#000000"
                          align="center"
                          w="100%"
                          h="190px"
                          gap="1rem"
                          cursor="pointer"
                          justify="space-between"
                          _hover={{ bg: "rgba(25, 25, 25, 1)" }}
                        >
                          <Flex gap="10px" align="center">
                            <Flex color="white">{index + 1}</Flex>
                            <Avatar
                              name={data.fullName}
                              src="https://bit.ly/broken-link"
                            />

                            <Text
                              textTransform="capitalize"
                              style={videoSmallTextStyle}
                            >
                              {data.fullName}
                            </Text>
                          </Flex>

                          <Flex mr="80px" gap="50px">
                            <Flex align="center">
                              <Text color="#FFFFFF">
                                Email: <span>{data?.email}</span>
                              </Text>
                            </Flex>
                            <Flex align="center">
                              <Text color="#FFFFFF">
                                On our platform since :{" "}
                                <span>{formatDate(data?.createdAt)}</span>
                              </Text>
                            </Flex>
                          </Flex>
                        </Flex>
                      ))}
                    </Flex>
                  )}
                </TabPanel>
                <TabPanel w="100%" padding="0px">
                  <Chart data={chartData} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}
/*
 * EXPORT
 */

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
