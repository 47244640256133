/* eslint-disable no-shadow */
/* eslint-disable max-lines-per-function */
/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import Navbar from "../LandingNavbar/index";
import Footer from "../LandingFooter/index";
import _ from "underscore";
import { useMutation, useLazyQuery } from "@apollo/client";

import SpecificUserfetch from "./__mutation__/index.creatorVideo.mutation";
import { connect } from "react-redux"; // NPM: React-redux library.
import ModalOpen from "./Modal";
import { useLocation } from "react-router-dom";
import {
  tabStyle,
  videoSmallTextStyle,
  videoBigTextStyle,
  videoBigTextStyle1,
} from "../../components/Styles/index.style.js";

/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  Image,
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  useToast,
} from "@chakra-ui/react";
import ChartTabPanelCreator from "./CreatorChart";

import Loader from "../../Views/User/website/LandingPage/Loader";

/*
 * QUERY
 */
import AccountCreatorList from "../../Views/User/website/__query__/index.creatorList.query";
import SuperAdminStatusCheck from "../../Views/User/website/__query__/index.adminStatusCheck.query";

function VideoPage({ account }) {
  /*
   * States
   */

  const [creatorVideos, setCreatorVideos] = useState();

  const [expandedSummary, setExpandedSummary] = useState({});
  const [contentStatus, setContentStatus] = useState("");

  const toast = useToast();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [totalFollower, setTotalFollower] = useState();
  const [totalFollowing, setTotalFollowing] = useState();
  const [totalVideos, setTotalVideos] = useState();
  const id = searchParams.get("id");
  const [list, setList] = useState();
  const [adminStatus, setAdminStatus] = useState();

  const [AccountCreatorListQuery] = useLazyQuery(AccountCreatorList, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  const [SuperAdminStatusCheckQuery] = useLazyQuery(SuperAdminStatusCheck, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  /*
   * FUNCTION
   */

  const handleStatusCheck = async () => {
    try {
      const response = await SuperAdminStatusCheckQuery({
        variables: {},
      });

      const data = response.data.SuperAdminStatusCheck;

      if (!_.isEmpty(data)) {
        setAdminStatus(data?.status);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred .",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  const handleCreatorList = async () => {
    try {
      const response = await AccountCreatorListQuery({
        variables: {},
      });

      const data = response.data.AccountCreatorList;

      if (!_.isEmpty(data)) {
        // Replace '123' with the actual ID you want to match
        const idToMatch = id;

        // Filter the data array to find the creator with the matching ID
        const matchedCreator = data.find((creator) => creator.id === idToMatch);

        if (matchedCreator) {
          // Store the matched creator in myList

          setList(matchedCreator);
          setTotalFollower(
            matchedCreator?.Follow_Follow_Account__followerIdToAccount?.length
          );
          setTotalFollowing(
            matchedCreator?.Follow_Follow_Account__followingIdToAccount?.length
          );
          setTotalVideos(matchedCreator?.ContentSummary?.length);
        }
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  // eslint-disable-next-line no-shadow
  const toggleSummary = (id) => {
    setExpandedSummary((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const [SpecificUserfetchMutation, { loading: loadingCreatorVideos }] =
    useMutation(SpecificUserfetch, {
      context: {
        headers: { "l-authorization": account.token },
      },
    });

  /*
   * STYLE
   */

  /*
   * Functions
   */

  const handleCreatorVideos = async (id) => {
    try {
      const response = await SpecificUserfetchMutation({
        variables: {
          accountId: id,
        },
      });

      const data = response.data.SpecificUserfetch;

      if (!_.isEmpty(data)) {
        setCreatorVideos(data);
        setContentStatus(data[0].status);
      }
    } catch (error) {
      /* Empty */
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    const formattedDate = () =>
      date.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });

    return formattedDate();
  }

  function formatDuration(durationInMinutes) {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.floor(durationInMinutes % 60);
    const seconds = Math.floor((durationInMinutes % 1) * 60);

    // eslint-disable-next-line init-declarations
    let formattedDuration = "";

    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }

    if (minutes > 0) {
      formattedDuration += `${minutes}m `;
    }

    if (seconds > 0) {
      formattedDuration += `${seconds}s`;
    }

    return formattedDuration.trim(); // Remove any leading/trailing whitespace
  }


  useEffect(() => {
    handleCreatorVideos(id);
  }, []);

  useEffect(() => {
    handleCreatorList();
    handleStatusCheck();
  }, [id]);

  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
        w="100%"
        minHeight="100vh"
      >
        <Flex w="100%" h="100px"></Flex>

        <Flex mt="1rem">
          <Flex overflow="scroll" direction="column" w="100%">
            <Tabs width="100%">
              <TabList
                gap="3rem"
                padding="0px"
                style={tabStyle}
                borderColor="none"
                borderBottom="1px solid #505050"
              >
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Videos
                </Tab>
                <Tab
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Analytics
                </Tab>
                <Tab
                  isDisabled={adminStatus !== "SUPER_LEVEL_ADMIN"}
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Creator Info
                </Tab>
              </TabList>
              <TabIndicator
                mt="-2px"
                height="3px"
                bg="#FFFFFF"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel w="100%" padding="0px">
                  {loadingCreatorVideos ? (
                    <Flex align="center" justify="center" w="100%">
                      <Loader />
                    </Flex>
                  ) : contentStatus === "NO_CONTENT" ? (
                    <Flex
                      mt="5rem"
                      justify="center"
                      w="100%"
                      color="white"
                      fontSize={{ base: "20px", md: "50px" }}
                    >
                      No content available.
                    </Flex>
                  ) : (
                    <Flex
                      overflowY="auto"
                      maxHeight="700px"
                      p="35px 24px"
                      direction="column"
                      gap="1rem"
                    >
                      {creatorVideos?.map((data, index) => (
                        <Flex
                          p="15px"
                          key={data.lowerThumbnailLink}
                          borderRadius="10px"
                          bg="#000000"
                          w="100%"
                          gap="1rem"
                          cursor="pointer"
                        >
                          <Flex color="white">{index + 1}</Flex>
                          <Flex w="256px" h="158px">
                            <Image src={data?.lowerThumbnailLink} />
                          </Flex>
                          <Flex gap=".5rem" direction="column">
                            <Text style={videoBigTextStyle}>
                              {data?.ContentSummary?.contentTitle}
                            </Text>

                            <Text w="850px" style={videoSmallTextStyle}>
                              {expandedSummary[index] ? (
                                <span>
                                  {data?.plotSummary}
                                  <Text
                                    color="rgba(0, 129, 223, 1)"
                                    cursor="pointer"
                                    onClick={() => toggleSummary(index)}
                                  >
                                    read less
                                  </Text>
                                </span>
                              ) : (
                                <span>
                                  {data?.plotSummary
                                    ?.split(" ")
                                    ?.slice(0, 20)
                                    ?.join(" ")}
                                  {data?.plotSummary?.split(" ").length >
                                    20 && (
                                    <Text
                                      color="rgba(0, 129, 223, 1)"
                                      cursor="pointer"
                                      onClick={() => toggleSummary(index)}
                                    >
                                      read more
                                    </Text>
                                  )}
                                </span>
                              )}
                            </Text>
                            <Flex gap=".5rem" direction="column">
                              <Flex align="center" gap="1rem">
                                <Text
                                  color="rgba(69, 211, 105, 1)"
                                  fontFamily="Roboto"
                                  fontSize="19px"
                                  fontWeight="700"
                                  lineHeight="21.54px"
                                  letterSpacing="-0.36px"
                                >
                                  {new Date(data.createdAt).getFullYear()}
                                </Text>
                                <Text
                                  color="rgba(186, 186, 186, 1)"
                                  fontFamily="Roboto"
                                  fontSize="19px"
                                  fontWeight="400"
                                  lineHeight="21.54px"
                                  letterSpacing="-0.36px"
                                >
                                  {formatDuration(data?.duration)}
                                </Text>
                                <Flex
                                  borderRadius="3px"
                                  justify="center"
                                  align="center"
                                  border="1px solid rgba(116, 116, 116, 1)"
                                  color="rgba(255, 255, 255, 1)"
                                  fontFamily="Roboto"
                                  fontWeight="400"
                                  fontSize="12px"
                                  lineHeight="21.54px"
                                >
                                  HD
                                </Flex>
                              </Flex>
                              <Flex align="center" gap="1rem">
                                <Flex
                                  color="white"
                                  w="70px"
                                  h="20px"
                                  justify="center"
                                  align="center"
                                  border="1px solid rgba(116, 116, 116, 1)"
                                >
                                  {`${
                                    data?.ContentSummary?.ageRating === "18"
                                      ? "A"
                                      : "U/A"
                                  }${data?.ContentSummary?.ageRating}+`}
                                </Flex>
                                <Text style={videoSmallTextStyle}>
                                  <span style={{ textTransform: "capitalize" }}>
                                    {data?.ContentSummary?.genre}
                                  </span>
                                </Text>
                              </Flex>
                            </Flex>
                          </Flex>
                          <Flex
                            justify="space-evenly"
                            align="center"
                            direction="column"
                          >
                            <Text style={videoBigTextStyle}>
                              Uploaded on : {formatDate(data?.createdAt)}
                            </Text>
                            <ModalOpen data={data} />
                          </Flex>
                        </Flex>
                      ))}
                    </Flex>
                  )}
                </TabPanel>
                <TabPanel w="100%" padding="0px">
                  <Flex mt="3rem" justify="center">
                    {list ? (
                      <ChartTabPanelCreator
                        totalFollower={totalFollower}
                        totalFollowing={totalFollowing}
                        totalVideos={totalVideos}
                      />
                    ) : (
                      "NO Data Available"
                    )}
                  </Flex>
                </TabPanel>
                <TabPanel w="100%" padding="0px">
                  <Flex direction="column" pt="50px" pb="50px" align="center">
                    <Flex
                      borderRadius="20px"
                      w="80%"
                      h="100%"
                      border="2px solid purple"
                      p="40px"
                      gap="50px"
                      justify="space-evenly"
                      direction="column"
                    >
                      <Flex w="100%" gap="50px" justify="space-evenly">
                        <Flex
                          gap="50px"
                          style={videoBigTextStyle}
                          direction="column"
                        >
                          <Text>
                            Name of creator :{" "}
                            <span style={videoBigTextStyle1}>
                              {list?.fullName}
                            </span>
                          </Text>
                          <Text>
                            Email id :{" "}
                            <span style={videoBigTextStyle1}>
                              {list?.email}
                            </span>
                          </Text>
                          <Text>
                            User name :{" "}
                            <span style={videoBigTextStyle1}>
                              {`@${list?.userName}`}
                            </span>
                          </Text>
                          <Text>
                            Total Videos :{" "}
                            <span style={videoBigTextStyle1}>
                              {list?.ContentSummary?.length}
                            </span>
                          </Text>
                        </Flex>
                        <Flex
                          gap="50px"
                          style={videoBigTextStyle}
                          direction="column"
                        >
                          <Text>
                            Total Followers :{" "}
                            <span style={videoBigTextStyle1}>
                              {
                                list?.Follow_Follow_Account__followerIdToAccount
                                  ?.length
                              }
                            </span>
                          </Text>
                          <Text>
                            Total Following :{" "}
                            <span style={videoBigTextStyle1}>
                              {
                                list
                                  ?.Follow_Follow_Account__followingIdToAccount
                                  ?.length
                              }
                            </span>
                          </Text>
                          <Text>
                            Contact number :{" "}
                            <span style={videoBigTextStyle1}>
                              {list?.phone}
                            </span>
                          </Text>
                          <Text>
                            On our platform since :{" "}
                            <span style={videoBigTextStyle1}>
                              {formatDate(list?.createdAt)}
                            </span>
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex></Flex>
                    </Flex>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(VideoPage);
