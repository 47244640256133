/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable max-lines-per-function */
/*
 * IMPORT
 */
import React, { useState, useCallback } from "react";
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";
import { connect } from "react-redux"; // NPM: React-redux library.
import { useMutation, useQuery } from "@apollo/client";
import _ from "underscore";
import {
  tabStyle,
  videoBigTextStyle,
  videoBigTextStyle1,
} from "../../../../components/Styles/index.style";
import Loader from "../LandingPage/Loader";
import Debounce from "lodash/debounce"; // Npm: lodash library.

/*
 * CHAKRA IMPORTS
 */
import {
  Flex,
  Box,
  Text,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  useToast,
  Spinner,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";

/*
 * MUTATAION
 */
import FreeSubscriptionExtension from "./__mutation__/index.extendTimer.mutation";
/*
 * QUERY
 */
import SubscribedAccountSearch from "./__query__/index.freeSubscribeRead.query";

/*
 * OBJECT
 */
function Index({ account }) {
  /*
   * States
   */
  const toast = useToast();
  const [extenedTime, setExtenedTime] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const _SubscribedAccountSearch = useQuery(SubscribedAccountSearch, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
    variables: {
      search: searchQuery,
    },
  });

  const [FreeSubscriptionExtensionMutation, { loadingFreeSubscriptionExtension },] = useMutation(FreeSubscriptionExtension, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  const handleSearchInput = useCallback(
    Debounce(() => _SubscribedAccountSearch.refetch(), 1000),
    []
  );

  /*
   * STYLE
   */

  /*
   * Functions
   */

  function getDifferenceInMinutes(createdAt, expiry) {
    const createdAtDate = new Date(createdAt);
    const expiryDate = new Date(expiry);
    const differenceInMilliseconds = expiryDate - createdAtDate;
    const differenceInMinutes = Math.floor(
      differenceInMilliseconds / (1000 * 60)
    );

    return differenceInMinutes;
  }

  const handleExtendTimer = async (id) => {
    try {
      const response = await FreeSubscriptionExtensionMutation({
        variables: {
          accountId: id,
          time: parseInt(extenedTime),
        },
      });

      const data = response.data.FreeSubscriptionExtension;

      if (!_.isEmpty(data) && data.status === "SUBSCRIPTION_PLAN_UPDATED") {
        toast({
          title: "Form Submitted",
          description: "Data saved successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setExtenedTime("");
        _SubscribedAccountSearch.refetch();
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
        w="100%"
        minHeight="100vh"
      >
        <Flex w="100%" h="100px"></Flex>

        <Flex mt="1rem">
          <Flex overflow="scroll" direction="column" w="100%">
            <Tabs width="100%">
              <TabList
                gap="3rem"
                padding="0px"
                style={tabStyle}
                borderColor="none"
                borderBottom="1px solid #505050"
              >
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Logs
                </Tab>
              </TabList>
              <TabIndicator
                mt="-2px"
                height="3px"
                bg="#FFFFFF"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel w="100%" padding="0px">
                  <Flex mt="1rem" alignItems="center" justify="center">
                    <Input
                      color="white"
                      placeholder="Search"
                      width="420px"
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        handleSearchInput();
                      }}
                    />
                  </Flex>
                  {_SubscribedAccountSearch?.loading ? (
                    <Flex align="center" justify="center" w="100%">
                      <Loader />
                    </Flex>
                  ) : (
                    <Flex direction="column" pt="50px" pb="50px" align="center">
                      <Flex
                        borderRadius="20px"
                        w="100%"
                        h="100%"
                        border="2px solid purple"
                        p="40px"
                        gap="50px"
                        justify="space-evenly"
                        direction="column"
                      >
                        {_SubscribedAccountSearch?.data?.SubscribedAccountSearch?.map(
                          (data, index) => (
                            <Flex
                              gap="40px"
                              align="center"
                              key={index}
                              w="100%"
                            >
                              <Flex
                                w="40px"
                                h="40px"
                                borderRadius="50%"
                                bg="white"
                                justify="center"
                                align="center"
                                fontWeight="600"
                              >
                                {index + 1}
                              </Flex>
                              <Flex gap="20px" w="40%" direction="column">
                                <Text style={videoBigTextStyle}>
                                  Name of user :{" "}
                                  <span style={videoBigTextStyle1}>
                                    {data?.fullName}
                                  </span>
                                </Text>
                                <Text style={videoBigTextStyle}>
                                  Email :{" "}
                                  <span style={videoBigTextStyle1}>
                                    {data?.email}
                                  </span>
                                </Text>
                              </Flex>
                              <Flex gap="20px" w="20%" direction="column">
                                <Text style={videoBigTextStyle}>
                                  Expiry time:{" "}
                                  <span style={videoBigTextStyle1}>
                                    {`${getDifferenceInMinutes(
                                      data?.expiry,
                                      data?.createdAt
                                    )} mins`}
                                  </span>
                                </Text>
                              </Flex>
                              <Flex w="21%">
                                <FormControl>
                                  <FormLabel
                                    style={videoBigTextStyle}
                                    htmlFor="username"
                                  >
                                    Add extended time in minutes:
                                  </FormLabel>
                                  <Input
                                    w="100%"
                                    h="50px"
                                    borderColor="rgba(116, 116, 116, 1)"
                                    placeholder="Add extended time in minutes"
                                    color="white"
                                    type="number"
                                    id="time"
                                    name="time"
                                    onChange={(e) =>
                                      setExtenedTime(e.target.value)
                                    }
                                  />
                                </FormControl>
                              </Flex>

                              <Button
                                colorScheme="green"
                                isDisabled={!extenedTime}
                                onClick={() => handleExtendTimer(data?.id)}
                              >
                                {loadingFreeSubscriptionExtension ? (
                                  <Spinner />
                                ) : (
                                  "Add"
                                )}
                              </Button>
                            </Flex>
                          )
                        )}
                      </Flex>
                    </Flex>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
