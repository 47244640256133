/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation($brandNameId: ID!, $brandSVG: JSON) {
    BrandSVGUpload(brandNameId: $brandNameId, brandSVG: $brandSVG) {
      status
      message
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
