/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation($BrandVideoId: ID) {
    BrandVideoRemove(BrandVideoId: $BrandVideoId) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
