/* eslint-disable react/prop-types */
/*
 * IMPORTS
 */
import React, { useState, useRef } from "react";
import { connect } from "react-redux"; // NPM: React-redux library.
import MovieDetail from "./MovieDetail";

/*
 * CHAKRA IMPORTS
 */
import { Flex, Text, Image } from "@chakra-ui/react";

/*
 * MUTATION
 */

/*
 * QUERY
 */

/*
 * OBJECT
 */
function CardComponent({ item }) {
  /*
   * STATE
   */
  const cardRef = useRef(null);
  const [showModal, setShowModal] = useState(false);

  const titleStyle = {
    color: "#FFFFFF",
    fontWeight: "700",
    fontFamily: "Montserrat",
    fontSize: "20px",
  };
  const subTitleStyle = {
    color: "#878992",
    fontWeight: "600",
    fontFamily: "Montserrat",
    fontSize: "16px",
    letterSpacing: "-0.32px",
  };

  /*
   * FUNCTION
   */

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <Flex
      ref={cardRef}
      borderRadius="15px"
      position="relative"
      transition="all 0.3s ease"
      onClick={toggleModal}
      cursor="pointer"
      direction="column"
    >
      <Flex h="350px" w="500px" className="card_image_div" borderRadius="15px">
        <Image
          src={item?.SellForm?.thumbnailStoredAt}
          borderRadius="15px"
          className="movie_thumbnail"
          alt="image"
          w="100%"
          h="100%"
          objectFit="fill"
        />
      </Flex>
      <Flex p="10px" w="100%" direction="column">
        <Text style={titleStyle}>{item?.SellForm?.contentTitle}</Text>

        <Text style={subTitleStyle}>
          {new Date(
            item?.SellForm?.createdAt
          ).getFullYear()}{" "}
           |<span style={{ textTransform: "capitalize" }}>{item?.SellForm?.genre}</span>
        </Text>
      </Flex>

      <MovieDetail isOpen={showModal} onClose={toggleModal} item={item} />
    </Flex>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
  LogoUpdate: (u) => __dispatch({ type: "LOGO_INIT", Logo: u }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
  logo: state.Logo,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(CardComponent);
