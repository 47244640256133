/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import Navbar from "../LandingNavbar/index";
import Footer from "../LandingFooter/index";
import { connect } from "react-redux"; // NPM: React-redux library.
import { useMutation, useLazyQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import _ from "underscore";
import { FiEdit3 } from "react-icons/fi";

/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  useToast,
  Flex,
  Text,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  FormControl,
  FormLabel,
  Select,
  Spinner,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
  Textarea,
  Input,
} from "@chakra-ui/react";

/*
 * QUERY
 */
import CategoryRead from "./__query__/index.categoryFetch.query";
import ContentAprovedAdminDetails from "./__query__/index.adminRead.query";
import FetchSpecificVideo from "./__query__/index.formRead.query";

/*
 * MUTATION
 */
import ContentApprove from "./__mutation__/index.approveDecline.mutation";
import ContentDeclinedUpdated from "./__mutation__/index.declineContent.mutation";
import EditContentByAdmin from "./__mutation__/index.editForm.mutation";

/*
 * OBJECT
 */
function Index({ account }) {
  /*
   * States
   */
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const webSeries = searchParams.get("webSeries");
  const [formData, setFormData] = useState();
  const [options, setOptions] = useState();
  const [contentDetailID, setContentDetailID] = useState();
  const [approvedStatus, setApprovedStatus] = useState(false);
  const [isApproveButtonDisabled, setIsApproveButtonDisabled] = useState(false);
  const [isDeclineButtonDisabled, setIsDeclineButtonDisabled] = useState(false);
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState("");
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  const [adminDetail, setAdminDetail] = useState();
  const openApproveModal = () => setIsApproveModalOpen(true);
  const closeApproveModal = () => setIsApproveModalOpen(false);
  const openDeclineModal = () => setIsDeclineModalOpen(true);
  const closeDeclineModal = () => setIsDeclineModalOpen(false);

  const {
    isOpen: isOpenPlotSummary,
    onOpen: onOpenPlotSummary,
    onClose: onClosePlotSummary,
  } = useDisclosure();
  const {
    isOpen: isOpenContentTitle,
    onOpen: onOpenContentTitle,
    onClose: onCloseContentTitle,
  } = useDisclosure();
  const {
    isOpen: isOpenDirector,
    onOpen: onOpenDirector,
    onClose: onCloseDirector,
  } = useDisclosure();
  const {
    isOpen: isOpenProducer,
    onOpen: onOpenProducer,
    onClose: onCloseProducer,
  } = useDisclosure();
  const {
    isOpen: isOpenCast,
    onOpen: onOpenCast,
    onClose: onCloseCast,
  } = useDisclosure();

  const toast = useToast();
  const [editedPlotSummary, setEditedPlotSummary] = useState("");
  const [editedContentTitle, setEditedContentTitle] = useState("");
  const [editedDirector, setEditedDirector] = useState("");
  const [editedProducer, setEditedProducer] = useState("");
  const [editedCast, setEditedCast] = useState();
  const [FetchSpecificVideoQuery, { refetch: refetchFetchSpecificVideo }] =
    useLazyQuery(FetchSpecificVideo, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const [ContentAprovedAdminDetailsQuery] = useLazyQuery(
    ContentAprovedAdminDetails,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );
  const [CategoryReadQuery] = useLazyQuery(CategoryRead, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });
  const [EditContentByAdminMutation, { loading: editLoading }] = useMutation(
    EditContentByAdmin,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );
  const [ContentApproveMutation, { loading: approveLoading }] = useMutation(
    ContentApprove,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );
  const [ContentDeclinedUpdatedMutation, { loading: ContentDeclinedLoading }] =
    useMutation(ContentDeclinedUpdated, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const sellbutton = {
    width: "170px",
    height: "60px",
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "31.74px",
    letterSpacing: "-0.2px",

    color: "#D9D9D9",
    cursor: "pointer",
  };
  const tabStyle = {
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "55.62px",
    letterSpacing: "0.32px",
  };
  const videoTextStyle = {
    color: "#7D798E",
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "17.04px",
    borderColor: "blue.500",
  };
  const videoBigTextStyle = {
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0.2px",
  };
  const videoBigTextStyle1 = {
    color: "rgba(116, 116, 116, 1)",
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0.2px",
  };

  /*
   * Functions
   */

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  function handleApproveClick() {
    setApprovedStatus(true);

    if (selectedOption) {
      openApproveModal();

      // Disable the "Decline" button
      setIsDeclineButtonDisabled(true);
    }
  }

  function handleDeclineClick() {
    setApprovedStatus(false);
    openDeclineModal();

    if (selectedOption) {
      // Disable the "Approve" button
      setIsApproveButtonDisabled(true);
    }
  }

  const handleCategory = async () => {
    try {
      const response = await CategoryReadQuery({
        variables: {},
      });

      const data = response.data.CategoryRead;

      if (!_.isEmpty(data)) {
        if (webSeries === "true") {
          const webSeriesData = data.filter(
            (item) => item?.name === "Web Series"
          );
          setOptions(webSeriesData);
          setContentDetailID(webSeriesData[0]?.id);
        } else if (webSeries === "false") {
          setOptions(data);
          setContentDetailID(data?.id);
        }
      }
    } catch (error) {
      /* Empty */
    }
  };

  const handleAdminDetail = async () => {
    try {
      const response = await ContentAprovedAdminDetailsQuery({
        variables: {
          contentDetailId: id,
        },
      });

      const data = response.data.ContentAprovedAdminDetails;

      if (!_.isEmpty(data)) {
        setAdminDetail(data);
      }
    } catch (error) {
      /* Empty */
    }
  };

  const handleFormData = async () => {
    try {
      const response = await FetchSpecificVideoQuery({
        variables: {
          contentDetailId: id,
        },
      });

      const data = response.data.FetchSpecificVideo;
      if (!_.isEmpty(data)) {
        setFormData(data[0]);
      }
    } catch (error) {
      /* Empty */
    }
  };

  function formatDuration(durationInMinutes) {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.floor(durationInMinutes % 60);
    const seconds = Math.floor((durationInMinutes % 1) * 60);
  
    // eslint-disable-next-line init-declarations
    let formattedDuration = "";
  
    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }
  
    if (minutes > 0) {
      formattedDuration += `${minutes}m `;
    }
  
    if (seconds > 0) {
      formattedDuration += `${seconds}s`;
    }
  
    return formattedDuration.trim(); // Remove any leading/trailing whitespace
  }

  const handleDecline = async () => {
    try {
      const response = await ContentDeclinedUpdatedMutation({
        variables: {
          contentDetailId: id,
        },
      });

      const data = response.data.ContentDeclinedUpdated;

      if (!_.isEmpty(data)) {
        if (data?.status === "DECLINED_SUCCESSFULLY") {
          toast({
            title: "Form Submitted",
            description: "Data saved successfully!",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
        history.push("/");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  const handleApproveRequest = async () => {
    try {
      const response = await ContentApproveMutation({
        variables: {
          categoryId: selectedOption,
          contentDetailId: id,
          contentSummaryId: formData?.ContentSummary?.id,
          isApprove: approvedStatus,
        },
      });

      const data = response.data.ContentApprove;

      if (!_.isEmpty(data)) {
        if (data?.status === "CATEGORY_APPROVED_SUCCESSFULLY") {
          toast({
            title: "Form Submitted",
            description: "Data saved successfully!",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          history.push("/");
        }
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  const handleEditRequest = async () => {
    try {
      const response = await EditContentByAdminMutation({
        variables: {
          cast: editedCast,
          contentSummaryId: formData?.ContentSummary?.id,
          contentDetailId: id,
          contentTitle: editedContentTitle,
          dop: editedDirector,
          plotSummary: editedPlotSummary,
          producer: editedProducer,
        },
      });

      const data = response.data.EditContentByAdmim;

      if (!_.isEmpty(response.data.EditContentByAdmin)) {
        if (
          response.data.EditContentByAdmin.status ===
          "CONTENT_UPDATE_SUCCESSFUL"
        ) {
          toast({
            title: "Form Details Updated",
            description: "Data saved successfully!",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          refetchFetchSpecificVideo({
            contentDetailId: id,
          });
          onCloseCast();
          onCloseContentTitle();
          onCloseDirector();
          onCloseProducer();
          onClosePlotSummary();
          window.location.reload();
        }
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  function handleSave() {
    onClosePlotSummary();
    setFormData({ ...formData, plotSummary: editedPlotSummary });
  }

  useEffect(() => {
    handleFormData();
    handleCategory();
    handleAdminDetail();
  }, [id, editLoading]);

  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
        w="100%"
        minHeight="100vh"
      >
        <Flex w="100%" h="100px"></Flex>

        <Flex mt="1rem">
          <Flex overflow="scroll" direction="column" w="100%">
            <Tabs width="100%">
              <TabList
                gap="3rem"
                padding="0px"
                style={tabStyle}
                borderColor="none"
                borderBottom="1px solid #505050"
              >
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Approval Form
                </Tab>
              </TabList>
              <TabIndicator
                mt="-2px"
                height="3px"
                bg="#FFFFFF"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel w="100%" padding="0px">
                  <Flex direction="column" pt="50px" pb="50px" align="center">
                    <Flex
                      borderRadius="20px"
                      w="80%"
                      h="100%"
                      border="2px solid purple"
                      p="40px"
                      gap="50px"
                      justify="space-evenly"
                      direction="column"
                    >
                      <Flex w="100%" gap="50px" justify="space-evenly">
                        <Flex
                          gap="50px"
                          style={videoBigTextStyle}
                          direction="column"
                        >
                          <Text>
                            User name :{" "}
                            <span style={videoBigTextStyle1}>
                              {`${formData?.ContentSummary?.Account?.userName}`}
                            </span>
                          </Text>
                          <Text textTransform="capitalize">
                            Type of content :{" "}
                            <span style={videoBigTextStyle1}>
                              {formData?.ContentSummary?.contentType}
                            </span>
                          </Text>
                          <Text textTransform="capitalize">
                            Content language :{" "}
                            <span style={videoBigTextStyle1}>
                              {formData?.ContentSummary?.contentLanguage}
                            </span>
                          </Text>
                          <Text textTransform="capitalize">
                            Genre :{" "}
                            <span style={videoBigTextStyle1}>
                              {formData?.ContentSummary?.genre}
                            </span>
                          </Text>
                          <Text textTransform="capitalize">
                            Cast :{" "}
                            {formData?.ContentSummary?.cast?.map((item) => (
                              <span key={item.id} style={videoBigTextStyle1}>
                                {`${item.cast} - ${item.name}, `}
                              </span>
                            ))}
                            {/* <FiEdit3
                              cursor="pointer"
                              onClick={() => {
                                onOpenCast();
                                setEditedCast(formData?.ContentSummary?.cast);
                              }}
                            /> */}
                          </Text>
                          <Modal
                            isCentered
                            isOpen={isOpenCast}
                            onClose={onCloseCast}
                          >
                            <ModalOverlay />
                            <ModalContent>
                              <ModalHeader>Edit Cast</ModalHeader>
                              <ModalCloseButton />
                              <ModalBody>
                                <Flex direction="column" gap="8px">
                                  {editedCast?.map((item, index) => (
                                    <Flex gap="8px" align="center" key={index}>
                                      <Text>{item.cast}</Text>
                                      <Input
                                        value={item.name}
                                        onChange={(e) =>
                                          setEditedCast(e.target.value)
                                        }
                                        placeholder="Enter cast..."
                                      />
                                    </Flex>
                                  ))}
                                </Flex>
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  colorScheme="blue"
                                  mr={3}
                                  onClick={handleSave}
                                >
                                  Save
                                </Button>
                                <Button onClick={onCloseCast}>Cancel</Button>
                              </ModalFooter>
                            </ModalContent>
                          </Modal>
                          <Text>
                            Duration :{" "}
                            <span style={videoBigTextStyle1}>
                              {formatDuration(formData?.duration)}
                            </span>
                          </Text>
                        </Flex>
                        <Flex
                          gap="50px"
                          style={videoBigTextStyle}
                          direction="column"
                        >
                          <Flex
                            align="center"
                            gap="8px"
                            textTransform="capitalize"
                          >
                            Content title :{" "}
                            <span style={videoBigTextStyle1}>
                              {formData?.ContentSummary?.contentTitle}
                            </span>
                            <FiEdit3
                              cursor="pointer"
                              onClick={() => {
                                onOpenContentTitle();
                                setEditedContentTitle(
                                  formData?.ContentSummary?.contentTitle
                                );
                              }}
                            />
                          </Flex>
                          <Modal
                            isCentered
                            isOpen={isOpenContentTitle}
                            onClose={onCloseContentTitle}
                          >
                            <ModalOverlay />
                            <ModalContent>
                              <ModalHeader>Edit Content Title</ModalHeader>
                              <ModalCloseButton />
                              <ModalBody>
                                <Textarea
                                  value={editedContentTitle}
                                  onChange={(e) =>
                                    setEditedContentTitle(e.target.value)
                                  }
                                  placeholder="Enter content title..."
                                />
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  colorScheme="blue"
                                  mr={3}
                                  onClick={() => handleEditRequest()}
                                >
                                  {editLoading ? <Spinner /> : "Save"}
                                </Button>
                                <Button onClick={onCloseContentTitle}>
                                  Cancel
                                </Button>
                              </ModalFooter>
                            </ModalContent>
                          </Modal>
                          <Text>
                            Age rating :{" "}
                            <span style={videoBigTextStyle1}>
                              {`${formData?.ContentSummary?.ageRating}+`}
                            </span>
                          </Text>
                          <Flex
                            align="center"
                            gap="8px"
                            textTransform="capitalize"
                          >
                            Director :{" "}
                            <span style={videoBigTextStyle1}>
                              {formData?.ContentSummary?.dop}
                            </span>
                            <FiEdit3
                              cursor="pointer"
                              onClick={() => {
                                onOpenDirector();
                                setEditedDirector(
                                  formData?.ContentSummary?.dop
                                );
                              }}
                            />
                          </Flex>
                          <Modal
                            isCentered
                            isOpen={isOpenDirector}
                            onClose={onCloseDirector}
                          >
                            <ModalOverlay />
                            <ModalContent>
                              <ModalHeader>Edit Director</ModalHeader>
                              <ModalCloseButton />
                              <ModalBody>
                                <Textarea
                                  value={editedDirector}
                                  onChange={(e) =>
                                    setEditedDirector(e.target.value)
                                  }
                                  placeholder="Enter director..."
                                />
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  colorScheme="blue"
                                  mr={3}
                                  onClick={() => handleEditRequest()}
                                >
                                  {editLoading ? <Spinner /> : "Save"}
                                </Button>
                                <Button onClick={onCloseDirector}>
                                  Cancel
                                </Button>
                              </ModalFooter>
                            </ModalContent>
                          </Modal>
                          <Flex
                            align="center"
                            gap="8px"
                            textTransform="capitalize"
                          >
                            Producer :{" "}
                            <span style={videoBigTextStyle1}>
                              {formData?.ContentSummary?.producer}
                            </span>
                            <FiEdit3
                              cursor="pointer"
                              onClick={() => {
                                onOpenProducer();
                                setEditedProducer(
                                  formData?.ContentSummary?.producer
                                );
                              }}
                            />
                          </Flex>
                          <Modal
                            isCentered
                            isOpen={isOpenProducer}
                            onClose={onCloseProducer}
                          >
                            <ModalOverlay />
                            <ModalContent>
                              <ModalHeader>Edit Producer</ModalHeader>
                              <ModalCloseButton />
                              <ModalBody>
                                <Textarea
                                  value={editedProducer}
                                  onChange={(e) =>
                                    setEditedProducer(e.target.value)
                                  }
                                  placeholder="Enter producer..."
                                />
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  colorScheme="blue"
                                  mr={3}
                                  onClick={() => handleEditRequest()}
                                >
                                  {editLoading ? <Spinner /> : "Save"}
                                </Button>
                                <Button onClick={onCloseProducer}>
                                  Cancel
                                </Button>
                              </ModalFooter>
                            </ModalContent>
                          </Modal>
                          <Text>
                            Content link:
                            <Text
                              width="400px"
                              overflow="auto"
                              color="blue.400"
                            >
                              <a
                                href={formData?.contentLink}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {formData?.contentLink}
                              </a>
                            </Text>
                          </Text>
                          <Flex gap="10px">
                            Subtitles:
                            <Text style={videoBigTextStyle1}>
                              {formData?.subtitleDetails?.length > 0
                                ? "Available"
                                : "Not Available"}
                            </Text>
                          </Flex>
                        </Flex>
                      </Flex>
                      <Flex>
                        <Flex
                          align="center"
                          justify="center"
                          gap="10px"
                          direction="column"
                          w="100%"
                        >
                          <Flex
                            align="center"
                            gap="8px"
                            style={videoBigTextStyle}
                          >
                            Plot Summary
                            <FiEdit3
                              cursor="pointer"
                              onClick={() => {
                                onOpenPlotSummary();
                                setEditedPlotSummary(formData?.plotSummary);
                              }}
                            />
                          </Flex>
                          <Text
                            textAlign="center"
                            w="80%"
                            style={videoBigTextStyle1}
                          >
                            {formData?.plotSummary}
                          </Text>
                        </Flex>
                        {/* Modal for editing plot summary */}
                        <Modal
                          isCentered
                          isOpen={isOpenPlotSummary}
                          onClose={onClosePlotSummary}
                        >
                          <ModalOverlay />
                          <ModalContent>
                            <ModalHeader>Edit Plot Summary</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                              <Textarea
                                value={editedPlotSummary}
                                onChange={(e) =>
                                  setEditedPlotSummary(e.target.value)
                                }
                                placeholder="Enter plot summary..."
                              />
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                colorScheme="blue"
                                mr={3}
                                onClick={() => handleEditRequest()}
                              >
                                {editLoading ? <Spinner /> : "Save"}
                              </Button>
                              <Button onClick={onClosePlotSummary}>
                                Cancel
                              </Button>
                            </ModalFooter>
                          </ModalContent>
                        </Modal>
                      </Flex>
                      <Flex justify="center">
                        <Flex gap="10px" direction="column">
                          <Text textAlign="center" style={videoBigTextStyle}>
                            Horizontal poster
                          </Text>
                          <img src={formData?.thumbnailLink} alt="thumbnail" />
                        </Flex>
                      </Flex>
                      <Flex justify="center">
                        <Flex gap="10px" direction="column">
                          <Text textAlign="center" style={videoBigTextStyle}>
                            Vertical poster
                          </Text>
                          <img
                            src={formData?.verticalThumbnailStoredAt}
                            alt="vertical thumbnail"
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex justify="center" w="80%">
                      <Flex p="20px" gap="50px" w="100%" align="end">
                        <FormControl>
                          <FormLabel style={videoBigTextStyle}>
                            Choose in which tray this video will go
                          </FormLabel>
                          <Select
                            bg="white"
                            style={videoTextStyle}
                            value={selectedOption}
                            onChange={handleSelectChange}
                            placeholder="Choose video section"
                          >
                            {options?.map((option, index) => (
                              <option key={index} value={option?.id}>
                                {option?.name}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </Flex>
                    </Flex>
                    <Flex mt="50px" w="80%" justify="flex-end" gap="30px">
                      <Button
                        style={sellbutton}
                        bg="red"
                        _hover={{ bg: "red" }}
                        onClick={handleDeclineClick}
                      >
                        Decline
                      </Button>
                      <Button
                        style={sellbutton}
                        bg="green"
                        _hover={{ bg: "green" }}
                        onClick={() => handleApproveClick()}
                        isDisabled={
                          isApproveButtonDisabled ||
                          !selectedOption ||
                          approveLoading
                        }
                      >
                        Approve
                      </Button>
                      {/* Approve Modal */}
                      <Modal
                        isCentered
                        isOpen={isApproveModalOpen}
                        onClose={closeApproveModal}
                      >
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>Approve Form</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            <p>Do you want to approve this form?</p>
                          </ModalBody>

                          <ModalFooter>
                            <Button
                              colorScheme="green"
                              mr={3}
                              onClick={handleApproveRequest}
                            >
                              {approveLoading ? <Spinner /> : "Yes"}
                            </Button>
                            <Button onClick={closeApproveModal}>No</Button>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>

                      {/* Decline Modal */}
                      <Modal
                        isCentered
                        isOpen={isDeclineModalOpen}
                        onClose={closeDeclineModal}
                      >
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>Decline Form</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            <p>Do you want to decline this form?</p>
                          </ModalBody>

                          <ModalFooter>
                            <Button
                              colorScheme="red"
                              mr={3}
                              onClick={handleDecline}
                            >
                              {ContentDeclinedLoading ? <Spinner /> : "Yes"}
                            </Button>
                            <Button onClick={closeDeclineModal}>No</Button>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                    </Flex>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
