/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
mutation MyMutation($adminId: ID, $password: String) {
  UpdatePassword(adminId: $adminId, password: $password) {
    status
    message
  }
}
`;

/*
 * EXPORTS
 */
export default Index;
