/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React, { useState } from "react";
import Navbar from "../LandingNavbar/index";
import Footer from "../LandingFooter/index";
import { connect } from "react-redux"; // NPM: React-redux library.
import { useLocation , useHistory } from "react-router-dom";
import _ from "underscore";

/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  Flex,
  Text,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Spinner,
} from "@chakra-ui/react";

/*
 * MUTATAION
 */
import BrandSubscriptionDetailsCreate from "./__mutation__/index.subscriptionDuration.mutation";
import { useMutation } from "@apollo/client";
/*
 * QUERY
 */

import AdPoster from "./adPoster";
import AdVideo from "./adVideo";
import AdWatermark from "./adWatermark";

function Index({ account }) {
  /*
   * States
   */
  const toast = useToast();
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get("name");
  const id = searchParams.get("id");
  const brandDuration = searchParams.get("duration");
  const [status, setStatus] = useState("ACTIVE");
  const [brandName, setBrandName] = useState(name);
  const [duration, setDuration] = useState(brandDuration);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [BrandSubscriptionDetailsCreateMutation, { loading: loadingBrandSubscriptionDetailsCreate },] = useMutation(BrandSubscriptionDetailsCreate, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });
  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  /*
   * STYLE
   */
  const tabStyle = {
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "55.62px",
    letterSpacing: "0.32px",
  };

  const videoBigTextStyle = {
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0.2px",
  };

  const videoBigTextStyle1 = {
    color: "rgba(116, 116, 116, 1)",
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0.2px",
  };

  /*
   * Functions
   */

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmation = () => {
    setStatus(status === "ACTIVE" ? "DISABLED" : "ACTIVE");
    closeModal(); // Close the modal after handling the confirmation
  };

  const handleSubscriptionDuration = async () => {
    try {
      const response = await BrandSubscriptionDetailsCreateMutation({
        variables: {
          brandNameId: id,
          duration: parseInt(duration),
        },
      });

      const data = response.data.BrandSubscriptionDetailsCreate;

      if (!_.isEmpty(data) && data.status === "BRAND_SUBSCRIPTION_CREATED") {
        toast({
          title: "Subscription Duration Updated",
          description: "Data saved successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setDuration("");
        history.push("/manage-ads");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
        w="100%"
        minHeight="100vh"
      >
        <Flex w="100%" h="100px"></Flex>

        <Flex mt="1rem">
          <Flex overflow="scroll" direction="column" w="100%">
            <Tabs width="100%">
              <TabList
                gap="3rem"
                padding="0px"
                style={tabStyle}
                borderColor="none"
                borderBottom="1px solid #505050"
              >
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Posters
                </Tab>
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Videos
                </Tab>
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Watermark
                </Tab>

                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Subscription Duration
                </Tab>

                {/* <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Manage brand
                </Tab> */}
              </TabList>
              <TabIndicator
                mt="-2px"
                height="3px"
                bg="#FFFFFF"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel w="100%" padding="0px">
                  <AdPoster />
                </TabPanel>
                <TabPanel w="100%" padding="0px">
                  <AdVideo />
                </TabPanel>
                <TabPanel w="100%" padding="0px">
                  <AdWatermark />
                </TabPanel>
                <TabPanel w="100%" padding="0px">
                  <Flex direction="column" pt="50px" pb="50px" align="center">
                    <Flex
                      borderRadius="20px"
                      w="35%"
                      h="100%"
                      border="2px solid purple"
                      p="40px"
                      gap="50px"
                      justify="space-evenly"
                      direction="column"
                    >
                      <FormControl>
                        <FormLabel
                          style={videoBigTextStyle}
                          htmlFor="brandName"
                        >
                          Brand Name:
                        </FormLabel>
                        <Input
                          w="100%"
                          h="50px"
                          borderColor="rgba(116, 116, 116, 1)"
                          placeholder="Brand name"
                          color="white"
                          type="text"
                          id="brandName"
                          name="brandName"
                          value={brandName}
                          disabled={true}
                        />
                      </FormControl>

                      <FormControl>
                        <FormLabel style={videoBigTextStyle} htmlFor="duration">
                          Duration:
                        </FormLabel>
                        <Input
                          w="100%"
                          h="50px"
                          borderColor="rgba(116, 116, 116, 1)"
                          placeholder="Duration"
                          color="white"
                          type="number"
                          id="duration"
                          name="duration"
                          value={duration}
                          onChange={(e) => setDuration(e.target.value)}
                          required
                        />
                      </FormControl>

                      <Button
                        onClick={handleSubscriptionDuration}
                        mt={4}
                        colorScheme="teal"
                      >
                        {loadingBrandSubscriptionDetailsCreate ? (
                          <Spinner />
                        ) : (
                          "Create"
                        )}
                      </Button>
                    </Flex>
                  </Flex>
                </TabPanel>

                {/* <TabPanel w="100%" padding="0px">
                  <Flex direction="column" pt="50px" pb="50px" align="center">
                    <Flex
                      borderRadius="20px"
                      w="80%"
                      h="100%"
                      border="2px solid purple"
                      p="40px"
                      gap="50px"
                      justify="space-evenly"
                      direction="column"
                    >
                      <Flex w="100%" gap="50px" justify="space-evenly">
                        <Flex
                          gap="50px"
                          style={videoBigTextStyle}
                          direction="column"
                        >
                          <Text>
                            Created on :{" "}
                            <span style={videoBigTextStyle1}>27-12-2023</span>
                          </Text>
                          <Text>
                            Partnership till :{" "}
                            <span style={videoBigTextStyle1}>29-12-2023</span>
                          </Text>
                        </Flex>
                        <Flex
                          gap="50px"
                          style={videoBigTextStyle}
                          direction="column"
                        >
                          <Text>
                            Status :{" "}
                            <span style={videoBigTextStyle1}>{status}</span>
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex direction="column">
                      <Flex align="end" p="20px" w="100%">
                        <Button
                          bg={status === "ACTIVE" ? "red" : "green"}
                          color="white"
                          w="210px"
                          h="50px"
                          _hover={status === "ACTIVE" ? "red" : "green"}
                          onClick={openModal}
                        >
                          {status === "ACTIVE"
                            ? "Disable this brand QR"
                            : "Enable this brand QR"}
                        </Button>
                        <Modal
                          isCentered
                          isOpen={isModalOpen}
                          onClose={closeModal}
                        >
                          <ModalOverlay />
                          <ModalContent>
                            <ModalHeader>Confirmation</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                              Do you really want to{" "}
                              {status === "ACTIVE" ? "disable" : "enable"} this
                              brand's QR?
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                colorScheme="red"
                                mr={3}
                                onClick={handleConfirmation}
                              >
                                Yes
                              </Button>
                              <Button colorScheme="gray" onClick={closeModal}>
                                No
                              </Button>
                            </ModalFooter>
                          </ModalContent>
                        </Modal>
                      </Flex>
                    </Flex>
                  </Flex>
                </TabPanel> */}
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
