/* eslint-disable default-param-last */
/*
 * PACKAGES
 */
export const _initialState = {
    isChatBotOpen: false,
  };
  
  /*
   * OBJECTS
   */
  const _Local = (__prevState = _initialState, __action) => {
    /*
     * Switch case for handling actions on
     * account store.
     */
    switch (__action.type) {
      case "LOCAL_UPDATE":
        // Return combined state.
        return {
          ...__prevState,
          ...__action.Local,
        };
      case "ACCOUNT_CLEAR":
        // Return combined state.
        return {
          ..._initialState,
        };
      default:
        // Return old state.
        return __prevState;
    }
  }
  
  /*
   * EXPORTS
   */
  export default _Local;
