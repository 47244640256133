/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery($BrandNameId: ID) {
    BrandDetailsRead(BrandNameId: $BrandNameId) {
      brandName
      id
      message
      status
      brandSVG
      BrandVideos {
        brandVideo
        id
      }
      BrandDetails {
        brandCarousel
        brandPoster
        brandUrl
        id
      }
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
