/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React, { useEffect, useState } from "react";
import Navbar from "../LandingNavbar/index";
import Footer from "../LandingFooter/index";
import { connect } from "react-redux"; // NPM: React-redux library.
import { useMutation, useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import _ from "underscore";

/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  Image,
  Flex,
  Text,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  useDisclosure,
  useToast,
  Spinner,
} from "@chakra-ui/react";

/*
 * QUERY
 */
import SpecificReadBlog from "./__query__/index.specificBlog.query";
import Component from "./yourComponent";

/*
 * MUTATION
 */
import DeleteBlogs from "./__mutation__/index.deleteBlog.mutation";

/*
 * OBJECT
 */
function Index({ account }) {
  /*
   * States
   */
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const toast = useToast();
  const history = useHistory();
  const [dataBlog, setDataBlog] = useState(null);

  const _SpecificReadBlog = useQuery(SpecificReadBlog, {
    variables: {
      blogId: id,
    },
  });

  const [DeleteBlogsMutation, { loading: deleteLoading }] = useMutation(
    DeleteBlogs,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );

  const sellbutton = {
    width: "170px",
    height: "60px",
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "31.74px",
    letterSpacing: "-0.2px",
    color: "#D9D9D9",
    cursor: "pointer",
  };
  const tabStyle = {
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "55.62px",
    letterSpacing: "0.32px",
  };
  const videoBigTextStyle = {
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "30px",
    lineHeight: "30px",
    letterSpacing: "0.6px",
  };

  /*
   * Functions
   */

  const handleDeleteRequest = async () => {
    try {
      const response = await DeleteBlogsMutation({
        variables: {
          blogId: id,
        },
      });

      const data = response.data.DeleteBlogs;

      if (data.status === "DATA_DELETED_") {
        history.push("/");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };

    return date.toLocaleDateString("en-US", options);
  };

  /*
   * Use Effects
   */
  useEffect(() => {
    if (_SpecificReadBlog?.data?.SpecificReadBlog[0]?.content) {
      setDataBlog(_SpecificReadBlog?.data?.SpecificReadBlog[0]?.content);
    }
  }, [_SpecificReadBlog.loading]);

  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
        w="100%"
        minHeight="100vh"
      >
        <Flex w="100%" h="100px"></Flex>

        <Flex mt="1rem">
          <Flex overflow="scroll" direction="column" w="100%">
            <Tabs width="100%">
              <TabList
                gap="3rem"
                padding="0px"
                style={tabStyle}
                borderColor="none"
                borderBottom="1px solid #505050"
              >
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Blog page
                </Tab>
              </TabList>
              <TabIndicator
                mt="-2px"
                height="3px"
                bg="#FFFFFF"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel w="100%" padding="0px">
                  <Flex direction="column" pt="50px" pb="50px" align="center">
                    <Flex
                      w="90%"
                      h="100%"
                      p="40px"
                      justify="space-evenly"
                      direction="column"
                      gap="20px"
                      bg="white"
                    >
                      <Flex direction="column" w="100%">
                        <Text
                          fontFamily="Poppins"
                          fontSize="30px"
                          fontWeight="700"
                          color="black"
                        >
                          {
                            _SpecificReadBlog?.data?.SpecificReadBlog[0]
                              ?.heading
                          }
                        </Text>
                        <Text
                          fontFamily="Poppins"
                          fontSize="20px"
                          fontWeight="400"
                          color="black"
                        >
                          {_SpecificReadBlog?.data?.SpecificReadBlog[0]?.title}
                        </Text>
                        <Flex gap="10px">
                          <Text>
                            By{" "}
                            <span style={{ color: "red" }}>
                              {
                                _SpecificReadBlog?.data?.SpecificReadBlog[0]
                                  ?.author
                              }
                            </span>
                          </Text>
                          <Text>
                            {formatDate(
                              _SpecificReadBlog?.data?.SpecificReadBlog[0]
                                .createdAt
                            )}
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex h="550px" w="60%">
                        <Image
                          w="100%"
                          h="100%"
                          objectFit="fill"
                          src={
                            _SpecificReadBlog?.data?.SpecificReadBlog[0]
                              ?.horizontalImage
                          }
                          alt="image"
                        />
                      </Flex>

                      <Flex>
                        <div dangerouslySetInnerHTML={{ __html: dataBlog }} />
                      </Flex>
                    </Flex>
                    <Flex mt="50px" w="80%" justify="flex-end" gap="30px">
                      <Button
                        style={sellbutton}
                        bg="red"
                        _hover={{ bg: "red" }}
                        onClick={handleDeleteRequest}
                      >
                        {deleteLoading ? <Spinner /> : "Delete"}
                      </Button>
                    </Flex>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
