/* eslint-disable max-lines */
/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable array-element-newline */
/* eslint-disable array-bracket-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable max-lines-per-function */
/*
 * IMPORT
 */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux"; // NPM: React-redux library.
import { useMutation, useQuery } from "@apollo/client";
import _ from "underscore";
import { IoClose, IoCheckmarkDoneSharp } from "react-icons/io5";
import { MdOutlineFileUpload, MdErrorOutline } from "react-icons/md";
import { FaPlus , FaAsterisk } from "react-icons/fa6";
import { v4 as uuidv4 } from "uuid";
import TimeInput from "../../../../components/timeInput/timeInput";
import { videoBigTextStyle } from "../../../../components/Styles/index.style";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client, S3 } from "@aws-sdk/client-s3";

/*
 * CHAKRA IMPORTS
 */
import {
  Flex,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Spinner,
  InputGroup,
} from "@chakra-ui/react";

/*
 * MUTATAION
 */
import PodcastCreate from "./__mutation__/index.podcastCreate.mutation";

/*
 * QUERY
 */
import PodcastRead from "./__query__/index.podcastRead.query";

/*
 * OBJECT
 */

const TimeWrapper = ({ onDataFromChild }) => {
  const [timeDuration, setTimeDuration] = useState("");

  const onTimeChangeHandler = (val) => {
    const dur = parseInt(val.split(":")[0]) * 60 + parseInt(val.split(":")[1]);

    setTimeDuration(dur);
    onDataFromChild(dur);
  };

  return (
    <TimeInput
      name="duration"
      borderColor="none"
      className="s-input -time"
      onTimeChange={onTimeChangeHandler}
      placeholder="HH:MM" // Set the placeholder text
    />
  );
};

function Index({ account }) {
  /*
   * States
   */
  const toast = useToast();
  const [horizontalImageUploadStatus, setHorizontalImageUploadStatus] =
    useState("");
  const [verticalImageUploadStatus, setVerticalImageUploadStatus] =
    useState("");
  const [audioUploadStatus, setAudioUploadStatus] = useState("");
  const [videoLoading, setVideoLoading] = useState(false);
  const [podcastState, setPodcastState] = useState([]);
  const [hostState, setHostState] = useState([]);
  const [guestState, setGuestState] = useState([]);
  const [formData, setFormData] = useState({
    description: "",
    contentTitle: "",
    hostName: "",
    guestName: "",
    producedBy: "",
    directedBy: "",
    verticalImageStoredAt: "",
    horizontalImageStoredAt: "",
    podCastDescription: "",
    podCastTitle: "",
    audioFile: "",
    duration: "",
    episodeNumber: "",
  });

  const _getPodcast = useQuery(PodcastRead);
  const [PodcastCreateMutation, { loading: loadingPodcast }] = useMutation(
    PodcastCreate,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );

  /*
   * STYLE
   */

  /*
   * Functions
   */

  // Function to confirm leaving the page
  const confirmLeave = (e) => {
    const confirmationMessage =
      "Your upload is in progress. Are you sure you want to leave?";
    e.returnValue = confirmationMessage;

    return confirmationMessage;
  };

  const handleVerticalImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        verticalImageStoredAt: file,
      }));
      setVerticalImageUploadStatus("success");
    } else {
      setVerticalImageUploadStatus("error");
    }
  };

  const handleHorizontalImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        horizontalImageStoredAt: file,
      }));
      setHorizontalImageUploadStatus("success");
    } else {
      setHorizontalImageUploadStatus("error");
    }
  };

  const handleAudioUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        audioFile: file,
      }));
      setAudioUploadStatus("success");
    } else {
      setAudioUploadStatus("error");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePodCastCreate = async () => {
    try {
      const response = await PodcastCreateMutation({
        variables: {
          content: podcastState,
          horizontalPoster: formData.horizontalImageStoredAt,
          PodcastDescription: formData.description,
          podcastTitle: formData.contentTitle,
          verticalPoster: formData.verticalImageStoredAt,
          producedBy: formData.producedBy,
          directedBy: formData.directedBy,
          host: hostState,
          guest: guestState,
        },
      });

      const data = response.data.PodcastCreate;

      if (!_.isEmpty(data) && data.status === "FORM_SUBMIT") {
        toast({
          title: "Form Submitted",
          description: "Data saved successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setFormData({
          description: "",
          contentTitle: "",
          hostName: "",
          guestName: "",
          verticalImageStoredAt: "",
          horizontalImageStoredAt: "",
          podCastDescription: "",
          podCastTitle: "",
          directedBy: "",
          producedBy: "",
          audioFile: "",
          episodeNumber: "",
        });
        setHorizontalImageUploadStatus("");
        setPodcastState([]);
        setHostState([]);
        setGuestState([]);
        setVerticalImageUploadStatus("");
        setAudioUploadStatus("");
        _getPodcast?.refetch();
      }
    } catch (error) {
      // Show error toast
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (podcastState.length > 0) {
      // Check if there are files to upload
      setVideoLoading(true);

      // Prevent page refresh or tab close
      window.addEventListener("beforeunload", confirmLeave);

      try {
        const s3Client = new S3Client({
          forcePathStyle: false, // Configures to use subdomain/virtual calling format.
          endpoint: process.env.REACT_APP_S3_ENDPOINT,
          region: "blr1",
          credentials: {
            accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
          },
        });

        // Array to store promises for each upload
        const uploadPromises = [];

        // Iterate over each object in linkStateMultiple
        podcastState.forEach((item, index) => {
          const id = uuidv4();
          const parallelUploads3 = new Upload({
            client: s3Client,
            params: {
              Bucket: "shucaeimage",
              Key: `shucaeContent/${id}.mp4`,
              Body: item.audioFile,
              ACL: "public-read",
            },
            queueSize: 4, // Optional concurrency configuration
            partSize: 1024 * 1024 * 5, // Optional size of each part, in bytes, at least 5MB
            leavePartsOnError: false, // Optional manually handle dropped parts
          });

          // Add event listener for progress
          parallelUploads3.on("httpUploadProgress", (progress) => {
            const percentUploaded = Math.round(
              (progress.loaded / progress.total) * 100
            );
          });

          // Push promise to array
          uploadPromises.push(
            parallelUploads3.done().then((_data) => {
              // Store location in linkStateMultiple
              if (_data.Location) {
                if (!_data.Location.startsWith("https://")) {
                  _data.Location = `https://${_data.Location}`;
                }
                podcastState[index].audioFile = _data.Location;
              }
            })
          );
        });

        // Wait for all uploads to complete
        await Promise.all(uploadPromises);
        handlePodCastCreate();
        setVideoLoading(false);

        // Now linkStateMultiple has updated contentVideoLink with _data.Location
      } catch (error) {
        setVideoLoading(false);
      } finally {
        window.removeEventListener("beforeunload", confirmLeave);
      }
    }
  };

  const handlePodcastState = () => {
    // Check if contentLink and summary are not empty

    if (
      formData.audioFile &&
      formData.podCastTitle &&
      formData.podCastDescription &&
      formData.episodeNumber
    ) {
      // Create a new object with values from formData
      const newLink = {
        id: uuidv4(),
        description: formData.podCastDescription,
        title: formData.podCastTitle,
        episodeNumber: formData.episodeNumber,
        audioFile: formData.audioFile,
      };

      // Update castState with the new object
      setPodcastState((prevLinkState) => [...prevLinkState, newLink]);

      // Clear the cast and name fields in formData
      setFormData((prevFormData) => ({
        ...prevFormData,
        podCastDescription: "",
        podCastTitle: "",
        episodeNumber: "",
        audioFile: "",
      }));
      setAudioUploadStatus("");
    } else {
      // If any of the fields are empty, show toaster
      toast({
        title: "Error",
        description: "Please fill all required fields.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleHostState = () => {
    if (formData.hostName) {
      const newLink = {
        id: uuidv4(),
        name: formData.hostName,
      };
      setHostState((prevLinkState) => [...prevLinkState, newLink]);

      setFormData((prevFormData) => ({
        ...prevFormData,
        hostName: "",
      }));
    } else {
      toast({
        title: "Error",
        description: "Please fill Host Name.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleGuestState = () => {
    if (formData.guestName) {
      const newLink = {
        id: uuidv4(),
        name: formData.guestName,
      };
      setGuestState((prevLinkState) => [...prevLinkState, newLink]);

      setFormData((prevFormData) => ({
        ...prevFormData,
        guestName: "",
      }));
    } else {
      toast({
        title: "Error",
        description: "Please fill Guest Name.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteCastState = (id) => {
    // Filter out the item with the specified id
    const updatedCastState = podcastState.filter((item) => item.id !== id);

    // Update the state with the modified array
    setPodcastState(updatedCastState);
  };

  const handleDeleteHostState = (id) => {
    // Filter out the item with the specified id
    const updatedCastState = hostState.filter((item) => item.id !== id);

    // Update the state with the modified array
    setHostState(updatedCastState);
  };

  const handleDeleteGuestState = (id) => {
    // Filter out the item with the specified id
    const updatedCastState = guestState.filter((item) => item.id !== id);

    // Update the state with the modified array
    setGuestState(updatedCastState);
  };

  const handleDuration = (data) => {
    // Handle the data received from the child component
    const form = { ...formData, duration: data };
    setFormData((prevLinkState) => ({
      ...prevLinkState,
      duration: data,
    }));
    setFormData(form);
  };

  /*
   * UseEffect
   */

  return (
    <Flex direction="column" pt="50px" pb="50px" align="center">
      <form style={{ width: "80%" }} onSubmit={(e) => handleSubmit(e)}>
        <Flex
          borderRadius="20px"
          w="100%"
          h="100%"
          border="2px solid purple"
          p="40px"
          gap="50px"
          justify="space-evenly"
          direction="column"
        >
          <Flex gap="20px" align="center">
            <FormControl isRequired>
              <FormLabel style={videoBigTextStyle} htmlFor="contentTitle">
                Title:
              </FormLabel>
              <Input
                w="100%"
                h="50px"
                borderColor="rgba(116, 116, 116, 1)"
                placeholder="Content Title"
                color="white"
                type="text"
                id="contentTitle"
                name="contentTitle"
                value={formData.contentTitle}
                onChange={handleChange}
                required
              />
            </FormControl>
            <FormControl isRequired>
              <Flex align="center">
                <FormLabel style={videoBigTextStyle}>
                  Upload Vertical Image
                </FormLabel>
                {verticalImageUploadStatus === "success" ? (
                  <IoCheckmarkDoneSharp color="white" />
                ) : verticalImageUploadStatus === "error" ? (
                  <MdErrorOutline color="white" />
                ) : (
                  ""
                )}
              </Flex>

              <InputGroup align="center">
                <Button
                  border="1px solid #3D445A"
                  w="100%"
                  h="50px"
                  borderColor="rgba(116, 116, 116, 1)"
                  bg="transparent"
                  _hover={{ bg: "grey" }}
                  onClick={() => {
                    // Trigger the hidden file input when the button is clicked
                    document.getElementById("imageUpload").click();
                  }}
                  position="relative"
                >
                  <MdOutlineFileUpload
                    size="25px"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      _hover: { color: "black" },
                    }}
                  />
                </Button>
                {/* Hidden file input */}
                <Input
                  type="file"
                  id="imageUpload"
                  name="verticalImageStoredAt"
                  onChange={handleVerticalImageUpload}
                  display="none"
                />
              </InputGroup>
            </FormControl>
          </Flex>
          <Flex gap="20px" align="center">
            <FormControl isRequired>
              <Flex align="center">
                <FormLabel style={videoBigTextStyle}>
                  Upload Horizontal Image
                </FormLabel>
                {horizontalImageUploadStatus === "success" ? (
                  <IoCheckmarkDoneSharp color="white" />
                ) : horizontalImageUploadStatus === "error" ? (
                  <MdErrorOutline color="white" />
                ) : (
                  ""
                )}
              </Flex>

              <InputGroup align="center">
                <Button
                  border="1px solid #3D445A"
                  w="100%"
                  h="50px"
                  borderColor="rgba(116, 116, 116, 1)"
                  bg="transparent"
                  _hover={{ bg: "grey" }}
                  onClick={() => {
                    // Trigger the hidden file input when the button is clicked
                    document.getElementById("horizontalImageUpload").click();
                  }}
                  position="relative"
                >
                  <MdOutlineFileUpload
                    size="25px"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      _hover: { color: "black" },
                    }}
                  />
                </Button>
                {/* Hidden file input */}
                <Input
                  type="file"
                  id="horizontalImageUpload"
                  name="horizontalImageStoredAt"
                  onChange={handleHorizontalImageUpload}
                  display="none"
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel style={videoBigTextStyle} htmlFor="directedBy">
                Directed By:
              </FormLabel>
              <Input
                w="100%"
                h="50px"
                borderColor="rgba(116, 116, 116, 1)"
                placeholder="Directed By"
                color="white"
                type="text"
                id="directedBy"
                name="directedBy"
                value={formData.directedBy}
                onChange={handleChange}
              />
            </FormControl>
          </Flex>
          <FormControl>
            <FormLabel style={videoBigTextStyle} htmlFor="producedBy">
              Produced By:
            </FormLabel>
            <Input
              w="48%"
              h="50px"
              borderColor="rgba(116, 116, 116, 1)"
              placeholder="Produced By"
              color="white"
              type="text"
              id="producedBy"
              name="producedBy"
              value={formData.producedBy}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel style={videoBigTextStyle} htmlFor="content">
              Podcast Description:
            </FormLabel>
            <Input
              w="100%"
              h="100px"
              borderColor="rgba(116, 116, 116, 1)"
              placeholder="Description"
              color="white"
              type="text"
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            />
          </FormControl>

          <Flex
            p="1rem 10px"
            align="center"
            direction="column"
            bg="#1E202D"
            w={{ base: "106%", md: "100%" }}
            ml={{ base: "-0.4rem", md: "0" }}
          >
            <Flex
              align="center"
              flexWrap="wrap"
              p="10px 10px 10px 30px"
              w="100%"
              gap="1rem"
            >
              {hostState.map((item) => (
                <Flex
                  p="20px"
                  borderRadius="5px"
                  bg="#D9D9D9"
                  key={item.id}
                  align="top"
                  gap="1rem"
                  w="100%"
                >
                  <Flex w="100%" gap=".5rem" color="#666874" direction="column">
                    <Text>
                      Host Name:{" "}
                      <span style={{ color: "#1E202D" }}>{item.name}</span>
                    </Text>
                  </Flex>
                  <Flex>
                    <IoClose
                      size="20px"
                      cursor="pointer"
                      color="#7551FF"
                      onClick={() => handleDeleteHostState(item.id)}
                    />
                  </Flex>
                </Flex>
              ))}
              <Flex align="end" gap="20px" w="100%">
                <FormControl width="500px">
                  <FormLabel style={videoBigTextStyle} htmlFor="podCastTitle">
                    Host Name:
                  </FormLabel>
                  <Input
                    w="500px"
                    h="50px"
                    borderColor="rgba(116, 116, 116, 1)"
                    placeholder="Host Name"
                    color="white"
                    type="text"
                    id="hostName"
                    name="hostName"
                    value={formData.hostName}
                    onChange={handleChange}
                  />
                </FormControl>
                <Flex
                  w="54px"
                  h="50px"
                  border="1px solid white"
                  borderRadius="12px"
                  justify="center"
                  align="center"
                  cursor="pointer"
                  onClick={() => handleHostState()}
                >
                  <FaPlus color="white" />
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            p="1rem 10px"
            align="center"
            direction="column"
            bg="#1E202D"
            w={{ base: "106%", md: "100%" }}
            ml={{ base: "-0.4rem", md: "0" }}
          >
            <Flex
              align="center"
              flexWrap="wrap"
              p="10px 10px 10px 30px"
              w="100%"
              gap="1rem"
            >
              {guestState.map((item) => (
                <Flex
                  p="20px"
                  borderRadius="5px"
                  bg="#D9D9D9"
                  key={item.id}
                  align="top"
                  gap="1rem"
                  w="100%"
                >
                  <Flex w="100%" gap=".5rem" color="#666874" direction="column">
                    <Text>
                      Guest Name:{" "}
                      <span style={{ color: "#1E202D" }}>{item.name}</span>
                    </Text>
                  </Flex>
                  <Flex>
                    <IoClose
                      size="20px"
                      cursor="pointer"
                      color="#7551FF"
                      onClick={() => handleDeleteGuestState(item.id)}
                    />
                  </Flex>
                </Flex>
              ))}
              <Flex align="end" gap="20px" w="100%">
                <FormControl width="500px">
                  <FormLabel style={videoBigTextStyle} htmlFor="podCastTitle">
                    Guest Name:
                  </FormLabel>
                  <Input
                    w="500px"
                    h="50px"
                    borderColor="rgba(116, 116, 116, 1)"
                    placeholder="Guest Name"
                    color="white"
                    type="text"
                    id="guestName"
                    name="guestName"
                    value={formData.guestName}
                    onChange={handleChange}
                  />
                </FormControl>
                <Flex
                  w="54px"
                  h="50px"
                  border="1px solid white"
                  borderRadius="12px"
                  justify="center"
                  align="center"
                  cursor="pointer"
                  onClick={() => handleGuestState()}
                >
                  <FaPlus color="white" />
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            p="1rem 10px"
            align="center"
            direction="column"
            bg="#1E202D"
            w={{ base: "106%", md: "100%" }}
            ml={{ base: "-0.4rem", md: "0" }}
          >
            <Flex
              align="center"
              flexWrap="wrap"
              p="10px 10px 10px 30px"
              w="100%"
              gap="1rem"
            >
              {podcastState.map((item) => (
                <Flex
                  p="20px"
                  borderRadius="5px"
                  bg="#D9D9D9"
                  key={item.id}
                  align="top"
                  gap="1rem"
                  w="100%"
                >
                  <Flex w="100%" gap=".5rem" color="#666874" direction="column">
                    <Text>
                      Description:{" "}
                      <span style={{ color: "#1E202D" }}>
                        {item.description}
                      </span>
                    </Text>
                    <Text>
                      Title:{" "}
                      <span style={{ color: "#1E202D" }}>{item.title}</span>
                    </Text>
                    <Text>
                      Episode Number:{" "}
                      <span style={{ color: "#1E202D" }}>
                        {item.episodeNumber}
                      </span>
                    </Text>
                    <Text>
                      Audi File Name:{" "}
                      <span style={{ color: "#1E202D" }}>
                        {item.audioFile.name}
                      </span>
                    </Text>
                  </Flex>
                  <Flex>
                    <IoClose
                      size="20px"
                      cursor="pointer"
                      color="#7551FF"
                      onClick={() => handleDeleteCastState(item.id)}
                    />
                  </Flex>
                </Flex>
              ))}
            </Flex>

            <Flex w={{ base: "100%", md: "92%" }}>
              <Flex direction="column" w="100%" gap="20px">
                <Flex gap="20px" w="100%">
                  <FormControl>
                    <Flex align="center">
                      <FormLabel style={videoBigTextStyle}>
                        <Flex gap="3px">
                          Upload Audio File
                          <FaAsterisk size="10px" color="#e1584d" />
                        </Flex>
                      </FormLabel>
                      {audioUploadStatus === "success" ? (
                        <IoCheckmarkDoneSharp color="white" />
                      ) : audioUploadStatus === "error" ? (
                        <MdErrorOutline color="white" />
                      ) : (
                        ""
                      )}
                    </Flex>

                    <InputGroup align="center">
                      <Button
                        border="1px solid #3D445A"
                        w="100%"
                        h="50px"
                        borderColor="rgba(116, 116, 116, 1)"
                        bg="transparent"
                        _hover={{ bg: "grey" }}
                        onClick={() => {
                          // Trigger the hidden file input when the button is clicked
                          document.getElementById("audioUpload").click();
                        }}
                        position="relative"
                      >
                        <MdOutlineFileUpload
                          size="25px"
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            color: "white",
                            _hover: { color: "black" },
                          }}
                        />
                      </Button>
                      {/* Hidden file input */}
                      <Input
                        type="file"
                        id="audioUpload"
                        name="audioStoredAt"
                        accept="audio/*"
                        onChange={handleAudioUpload}
                        display="none"
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel style={videoBigTextStyle} htmlFor="podCastTitle">
                      <Flex gap="3px">
                        Podcast Title:
                        <FaAsterisk size="10px" color="#e1584d" />
                      </Flex>
                    </FormLabel>
                    <Input
                      w="100%"
                      h="50px"
                      borderColor="rgba(116, 116, 116, 1)"
                      placeholder="Content Title"
                      color="white"
                      type="text"
                      id="podCastTitle"
                      name="podCastTitle"
                      value={formData.podCastTitle}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Flex>

                <Flex gap="20px" w="100%">
                  <FormControl>
                    <FormLabel
                      style={videoBigTextStyle}
                      htmlFor="podCastDescription"
                    >
                      <Flex gap="3px">
                        Podcast Description:
                        <FaAsterisk size="10px" color="#e1584d" />
                      </Flex>
                    </FormLabel>
                    <Input
                      w="100%"
                      h="50px"
                      borderColor="rgba(116, 116, 116, 1)"
                      placeholder="Podcast Description"
                      color="white"
                      type="text"
                      id="podCastDescription"
                      name="podCastDescription"
                      value={formData.podCastDescription}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      style={videoBigTextStyle}
                      htmlFor="episodeNumber"
                    >
                      <Flex gap="3px">
                        Episode Number:
                        <FaAsterisk size="10px" color="#e1584d" />
                      </Flex>
                    </FormLabel>
                    <Input
                      w="100%"
                      h="50px"
                      borderColor="rgba(116, 116, 116, 1)"
                      placeholder="Episode number"
                      color="white"
                      type="number"
                      id="episodeNumber"
                      name="episodeNumber"
                      value={formData.episodeNumber}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Flex>

                {/* <FormControl>
                  <FormLabel style={videoBigTextStyle}>Duration</FormLabel>
                  <TimeWrapper onDataFromChild={handleDuration} />
                </FormControl> */}

                <Flex
                  w="54px"
                  h="50px"
                  border="1px solid white"
                  borderRadius="12px"
                  justify="center"
                  align="center"
                  cursor="pointer"
                  onClick={() => handlePodcastState()}
                >
                  <FaPlus color="white" />
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Button type="submit" mt={4} colorScheme="teal">
            {loadingPodcast || videoLoading ? <Spinner /> : "Create"}
          </Button>
        </Flex>
      </form>
    </Flex>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
