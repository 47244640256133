/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation($adminId: ID, $level: Int) {
    EditAdminLevel(adminId: $adminId, level: $level) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
