/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/*
 * IMPORT
 */

/*
 * CHAKRA UI IMPORT
 */
import {
  Box,
  Flex,
  Button,
  useMediaQuery,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Image,
  useDisclosure
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { connect } from "react-redux"; // NPM: React-redux library.

import { NavLink, useLocation } from "react-router-dom";
import "./../../index.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { logo1, logo3 } from "../../Assets/images/shucae/export";

const Navbar = ({ account, AccountLogout }) => {
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const [isLargerThan900] = useMediaQuery("(min-width: 1000px)");
  const [openNavbar, setOpenNavbar] = React.useState(false);

  const firstField = React.useRef();

  function _handleLogout() {
    AccountLogout({
      isUserLoggedIn: false,
    });
    history.push("/signin");
  }

  const navbarLinksData = [{ name: "Home", takeTo: "/" }];

  function _handleNavbar() {
    setOpenNavbar(!openNavbar);
  }

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  const [hasShadow, setHasShadow] = React.useState(false);
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setHasShadow(true);
    } else {
      setHasShadow(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* <Bonus />  */}
      <Flex
        fontFamily="Montserrat"
        id="navbar"
        as="nav"
        align="center"
        justify="space-between"
        h="100px"
        w="100%"
        color="white"
        bg="linear-gradient(180deg, #120B25 0%, rgba(9, 6, 19, 0.86) 30.6%, rgba(0, 0, 0, 0) 70.12%)"
        zIndex="5"
        position="fixed"
      >
        {/* HL ---------------------------------- web navbar ------------------------------------------- */}
        <Flex
          display={!isLargerThan900 ? "none" : "flex"}
          w="95%"
          mx="auto"
          alignItems="center"
          justifyContent="space-between"
        >
          <NavLink to="/">
            <Flex alignItems="center" gap="10px">
              <Image width="200px" src={logo3} />
            </Flex>
          </NavLink>

          <Flex
            px="30px"
            justifyContent="space-evenly"

            width="100%"
          >
            {navbarLinksData?.map((item) => (
              <Flex
                key={item.id}
                fontWeight="500"
                color={location.pathname === item.takeTo ? "white" : "white"}
                alignItems="center"
              >
                <NavLink to={item.takeTo}>{item.name}</NavLink>
              </Flex>
            ))}
          </Flex>

          <Flex color="white" gap="10px" alignItems="center">
            {!account.isUserLoggedIn ? (
              <Button
                onClick={() => {
                  history.push("/signin");
                }}
                variant="unstyled"
                color="white"
              >
                Login
              </Button>
            ) : (
              <Flex
                color="white"
                fontFamily="Roboto"
                fontWeight="400"
                fontSize="15px"
                lineHeight="32.83px"
                letterSpacing="0.12px"
                gap=".5rem"
                align="center"
                cursor="pointer"
                onClick={() => _handleLogout()}
              >
                Logout
              </Flex>
            )}
          </Flex>
        </Flex>

        {/* HL ---------------------------------- mobile navbar ------------------------------------------- */}

        <Flex
          display={isLargerThan900 ? "none" : "flex"}
          w="95%"
          mx="auto"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <NavLink to="/">
              <Image width="45px" src={logo1} alt="" />
            </NavLink>
          </Box>

          <Flex gap="10px">
            <Button
              w="3rem"
              h="3rem"
              p="0"
              aspectRadio="1"
              borderRadius="50%"
              bg="none"
              onClick={() => _handleNavbar()}
            >
              <HamburgerIcon fontSize="30px" />
            </Button>
          </Flex>
        </Flex>

        <Drawer
          isOpen={openNavbar}
          placement="right"
          initialFocusRef={firstField}
          onClose={onClose}
          width="80vw"
        >
          <DrawerOverlay />
          <DrawerContent bg="#313131">
            <DrawerHeader
              py="30px"
              px="10px"
              display="flex"
              color="white"
              justifyContent="space-between"
              alignItems="center"
            >
              Menu{" "}
              <Button
                color="white"
                onClick={() => _handleNavbar()}
                bg="none"
                mr="8px"
                w="2.5rem"
                h="2.5rem"
                borderRadius="50%"
                border="none"
                outline="none"
              >
                <CloseIcon style={{ fontSize: "16px" }} />
              </Button>
            </DrawerHeader>

            <DrawerBody>
              <Flex color="white" flexDirection="column" gap="2rem">
                {navbarLinksData?.map((item) => (
                  <Flex
                  key={item.id}
                    _hover={{ color: "#ff896c" }}
                    fontWeight="500"
                    alignItems="center"
                  >
                    <NavLink
                      color={
                        location.pathname === item.takeTo
                          ? "#ff896c"
                          : "#263238"
                      }
                      to={item.takeTo}
                    >
                      {item.name}
                    </NavLink>
                  </Flex>
                ))}
              </Flex>
            </DrawerBody>

            <DrawerFooter borderTopWidth="1px"></DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Flex>
    </>
  );
};

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Navbar);
