/* eslint-disable radix */
/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";
import Movies from "./MoviesPage/index";
import { useMutation, useQuery } from "@apollo/client";
import { connect } from "react-redux"; // NPM: React-redux library.
import _ from "underscore";
import { tabStyle } from "../../../../components/Styles/index.style"

/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  Flex,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  InputGroup,
  Select,
  useToast,
  Spinner,
} from "@chakra-ui/react";

/*
 * QUERY
 */
import AvailablePriority from "../__query__/index.availablePriority.query";

/*
 * MUTATAION
 */
import CategoryNameCreate from "../__mutation__/index.categoryCreate.mutation";
import MoviePoster from "./MoviePoster";
import AdsPoster from "./AdsPoster";

function Index({ account }) {
  /*
   * States
   */

  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [selectedOption, setSelectedOption] = useState();

  const [options, setOptions] = useState();

  const [CategoryNameCreateMutation, { loading: loadingCategory }] =
    useMutation(CategoryNameCreate, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const { refetch: AvailablePriorityRefetch, data: AvailablePriorityQuery } =
    useQuery(AvailablePriority, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  /*
   * STYLE
   */

  /*
   * Functions
   */

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setInputValue("");
    setSelectedOption("");
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleCategoryCreate = async () => {
    try {
      const response = await CategoryNameCreateMutation({
        variables: {
          name: inputValue,
          priority: parseInt(selectedOption),
        },
      });

      const data = response.data.CategoryNameCreate;

      if (!_.isEmpty(data)) {
        toast({
          title: "Form Submitted",
          description: "Data saved successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setInputValue("");
        setSelectedOption("");
        closeModal();
      }
    } catch (error) {
      // Show error toast
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  async function HandleAvailablePriorityRefetch() {
    await AvailablePriorityRefetch();
  }

  useEffect(() => {
    HandleAvailablePriorityRefetch();
  }, []);

  useEffect(() => {
    if (AvailablePriorityQuery && AvailablePriorityQuery.AvailablePriority) {
      setOptions(AvailablePriorityQuery?.AvailablePriority);
    }
  }, [AvailablePriorityQuery]);
  const handleCreate = () => {
    handleCategoryCreate();
  };

  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
        w="100%"
        minHeight="100vh"
      >
        <Flex w="100%" h="100px"></Flex>

        <Flex mt="1rem">
          <Flex direction="column" w="100%">
            <Tabs width="100%">
              <TabList
                gap="3rem"
                padding="0px"
                style={tabStyle}
                borderColor="none"
                borderBottom="1px solid #505050"
              >
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Home page
                </Tab>
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Home page main section for movie
                </Tab>
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Home page main section for ads
                </Tab>
              </TabList>
              <TabIndicator
                mt="-2px"
                height="3px"
                bg="#FFFFFF"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel w="100%" padding="0px">
                  <Flex direction="column" pt="50px" pb="50px" align="center">
                    <Flex
                      borderRadius="20px"
                      w="80%"
                      h="100%"
                      p="40px"
                      gap="50px"
                      justify="space-evenly"
                      direction="column"
                    >
                      <Button onClick={openModal}>Add new section</Button>

                      <Modal isCentered isOpen={isOpen} onClose={closeModal}>
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>Create a New Section</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            <Flex direction="column" gap="20px">
                              <InputGroup>
                                <Input
                                  placeholder="Section name"
                                  value={inputValue}
                                  onChange={handleInputChange}
                                />
                              </InputGroup>

                              <Select
                                placeholder="Select tray priority"
                                value={selectedOption}
                                onChange={handleSelectChange}
                              >
                                {options?.map((option, index) => (
                                  <option key={index} value={option?.priority}>
                                    {option?.priority}
                                  </option>
                                ))}
                              </Select>
                            </Flex>
                          </ModalBody>

                          <ModalFooter>
                            <Button
                              colorScheme="blue"
                              mr={3}
                              onClick={handleCreate}
                            >
                              {loadingCategory ? <Spinner /> : "Create"}
                            </Button>
                            <Button variant="ghost" onClick={closeModal}>
                              Cancel
                            </Button>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                    </Flex>

                    <Movies loadingCategory={loadingCategory} />
                  </Flex>
                </TabPanel>
                <TabPanel w="100%" padding="0px">
                  <MoviePoster />
                </TabPanel>
                <TabPanel w="100%" padding="0px">
                  <AdsPoster />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
