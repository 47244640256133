/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery {
    HomePageMovieRead {
      movieUrl
      verticalMoviePoster
      moviePoster
      message
      status
      id
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
