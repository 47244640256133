/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation(
    $moviePoster: JSON
    $movieUrl: String
    $verticalPoster: JSON
  ) {
    HomePageMovieCreate(
      moviePoster: $moviePoster
      movieUrl: $movieUrl
      verticalPoster: $verticalPoster
    ) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
