/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-lines-per-function */
/* eslint-disable multiline-comment-style */
/* eslint-disable sort-imports */
/*
 * IMPORT
 */
import React, { useState } from 'react'
import Navbar from '../LandingNavbar/index'
import Footer from '../LandingFooter/index'
import thumbnail from '../../Assets/images/indra.jpeg'
import { MdOutlineFileUpload } from 'react-icons/md'
import { v4 as uuidv4 } from 'uuid'
import { connect } from 'react-redux' // NPM: React-redux library.

/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  Link,
  Image,
  Flex,
  Text,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalBody,
  useToast,
  ModalCloseButton
} from '@chakra-ui/react'

/*
 * MUTATAION
 */


/*
 * QUERY
 */

function Index() {
  /*
   * States
   */
  const [poster, setPoster] = useState()
  const [video, setVideo] = useState()
  const [inputValue, setInputValue] = useState('')

  const [status, setStatus] = useState('ACTIVE')
  const [selectedOption, setSelectedOption] = useState('')
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toast = useToast()

  /*
   * STYLE
   */
  const tabStyle = {
    fontFamily: 'Poppins',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '55.62px',
    letterSpacing: '0.32px'
  }

  const videoBigTextStyle = {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '30px',
    letterSpacing: '0.2px'
  }

  const videoBigTextStyle1 = {
    color: 'rgba(116, 116, 116, 1)',
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '30px',
    letterSpacing: '0.2px'
  }

  /*
   * Functions
   */

  const handleSubmit = e => {
    e.preventDefault()

    setPoster()
    setInputValue('')

    setSelectedOption('')

    toast({
      title: 'Form Submitted',
      description: 'Data saved successfully!',
      status: 'success',
      duration: 5000,
      isClosable: true
    })
  }

  function handleVideoSubmit(e) {
    e.preventDefault()
    setVideo()

    toast({
      title: 'Form Submitted',
      description: 'Data saved successfully!',
      status: 'success',
      duration: 5000,
      isClosable: true
    })
  }

  const openModal = () => {
    setIsModalOpen(true)
  }
  const closeModal = () => {
    setIsModalOpen(false)
  }
  const handleConfirmRemove = () => {
    setIsRemoveModalOpen(false)
  }
  const handleRemoveClick = () => {
    setIsRemoveModalOpen(true)
  }
  const handleCloseRemoveModal = () => {
    setIsRemoveModalOpen(false)
  }

  const handleConfirmation = () => {
    // Implement your logic to disable/ena
    setStatus('ACTIVE' === status ? 'DISABLED' : 'ACTIVE')
    closeModal()
  }
  const handleInputChange = e => {
    setInputValue(e.target.value)
  }

  const handleImageUpload = e => {
    const file = e.target.files[0]

    if (file) {
      const generatedUniqueId = uuidv4()


      setPoster({
        ...poster,
        thumbnailStoredAt: file,
        uniqueId: generatedUniqueId
      })
    }
  }
  const handleVideoUpload = e => {
    const file = e.target.files[0]

    // Check if a file is selected
    if (file) {
      const generatedUniqueId = uuidv4()


      setVideo({
        ...video,
        videoStoredAt: file,
        uniqueIdVideo: generatedUniqueId
      })
    }
  }

  const handleSelectChange = e => {
    setSelectedOption(e.target.value)
  }

  return (
    <Box bg='#00020A'>
      <Navbar />
      <Box
        bg='linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)'
        w='100%'
        minHeight='100vh'
      >
        <Flex w='100%' h='100px'></Flex>

        <Flex mt='1rem'>
          <Flex overflow='scroll' direction='column' w='100%'>
            <Tabs width='100%'>
              <TabList
                gap='3rem'
                padding='0px'
                style={tabStyle}
                borderColor='none'
                borderBottom='1px solid #505050'
              >
                <Tab
                  ml='3rem'
                  padding='0px'
                  _selected={{ color: '#FFFFFF' }}
                  color='#878992'
                  _focus={{ boxShadow: 'none' }}
                >
                  Posters
                </Tab>
                <Tab
                  ml='3rem'
                  padding='0px'
                  _selected={{ color: '#FFFFFF' }}
                  color='#878992'
                  _focus={{ boxShadow: 'none' }}
                >
                  Videos
                </Tab>
                <Tab
                  ml='3rem'
                  padding='0px'
                  _selected={{ color: '#FFFFFF' }}
                  color='#878992'
                  _focus={{ boxShadow: 'none' }}
                >
                  Manage brand
                </Tab>
              </TabList>
              <TabIndicator
                mt='-2px'
                height='3px'
                bg='#FFFFFF'
                borderRadius='1px'
              />
              <TabPanels>
                <TabPanel w='100%' padding='0px'>
                  <Flex direction='column' pt='50px' pb='50px' align='center'>
                    <Flex direction='column'>
                      <form style={{ width: '100%' }} onSubmit={handleSubmit}>
                        <Flex align='end' p='20px' w='100%'>
                          <FormControl>
                            <FormLabel style={videoBigTextStyle}>
                              Upload brand poster
                            </FormLabel>
                            <InputGroup align='center'>
                              <Button
                                style={videoBigTextStyle1}
                                border='1px solid #3D445A'
                                bg='transparent'
                                _hover={{ bg: 'transparent' }}
                                onClick={() => {
                                  // Trigger the hidden file input when the button is clicked
                                  document
                                    .getElementById('thumbnailInput')
                                    .click()
                                }}
                                width={{ base: '320px' }}
                                position='relative'
                              >
                                <MdOutlineFileUpload
                                  size='25px'
                                  style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)'
                                  }}
                                />
                              </Button>
                              {/* Hidden file input */}
                              <Input
                                type='file'
                                id='thumbnailInput'
                                name='thumbnailStoredAt'
                                onChange={handleImageUpload}
                                display='none'
                              />
                            </InputGroup>
                          </FormControl>
                          <FormControl>
                            <FormLabel style={videoBigTextStyle}>
                              Brand Url
                            </FormLabel>
                            <InputGroup>
                              <Input
                                width='320px'
                                borderColor='rgba(116, 116, 116, 1)'
                                color='white'
                                placeholder='Url'
                                value={inputValue}
                                onChange={handleInputChange}
                              />
                            </InputGroup>
                          </FormControl>
                          <FormControl>
                            <FormLabel style={videoBigTextStyle}>
                              Choose poster carousel
                            </FormLabel>
                            <Select
                              width='320px'
                              borderColor='rgba(116, 116, 116, 1)'
                              color='white'
                              value={selectedOption}
                              onChange={handleSelectChange}
                              placeholder='Choose poster carousel'
                            >
                              <option value='option1'>Option 1</option>
                              <option value='option2'>Option 2</option>
                              <option value='option3'>Option 3</option>
                            </Select>
                          </FormControl>
                          <Button
                            bg='blue.600'
                            color='white'
                            w='210px'
                            h='50px'
                            _hover={{ bg: 'blue.500' }}
                            type='submit'
                          >
                            Save
                          </Button>
                        </Flex>
                      </form>
                      <Flex
                        gap='20px'
                        align='center'
                        borderRadius='10px'
                        border='1px solid #878992'
                        p='20px'
                      >
                        <Flex>
                          {poster ? (
                            <Image
                              src={
                                poster?.thumbnailStoredAt ? URL.createObjectURL(
                                  poster?.thumbnailStoredAt
                                ) : ''
                              }
                            />
                          ) : (
                            <Text style={videoBigTextStyle}>No poster</Text>
                          )}
                        </Flex>
                        <Link
                          style={videoBigTextStyle}
                          href={inputValue}
                          target='_blank'
                        >
                          {inputValue || 'No url provided'}
                        </Link>
                        <Text style={videoBigTextStyle}>
                          {selectedOption || 'No carousel provided'}
                        </Text>
                      </Flex>
                    </Flex>

                    <Flex
                      overflowY='auto'
                      maxHeight='700px'
                      p='35px 24px'
                      direction='column'
                      gap='3rem'
                    >
                      {[1, 2, 3, 4].map((data, index) => (
                        <Flex
                        key={data.id}
                          p='15px'
                          borderRadius='10px'
                          bg='#000000'
                          w='100%'
                          gap='1rem'
                          cursor='pointer'
                          direction='column'
                          align='center'
                        >
                          <Flex
                            bg='white'
                            w='40px'
                            h='40px'
                            borderRadius='50%'
                            textAlign='center'
                            color='white'
                            justify='center'
                            align='center'
                          >
                            <Text
                              fontWeight='600'
                              fontSize='20px'
                              color='black'
                            >
                              {index + 1}
                            </Text>
                          </Flex>
                          <Flex direction='column'>
                            <Text style={videoBigTextStyle1}>
                              Brand poster :
                            </Text>
                            <Image src={thumbnail} />
                          </Flex>
                          <Flex mt='1rem' w='100%' direction='column'>
                            <Text style={videoBigTextStyle1}>Brand url :</Text>
                            <Link
                              href={inputValue}
                              target='_blank'
                              color='blue.600'
                              style={videoBigTextStyle}
                            >
                              {inputValue}
                            </Link>
                          </Flex>
                          <Flex mt='1rem' w='100%' direction='column'>
                            <Text style={videoBigTextStyle1}>
                              Brand poster carousel :
                            </Text>
                            <Text color='blue.600' style={videoBigTextStyle}>
                              {selectedOption}
                            </Text>
                          </Flex>
                          <Button colorScheme='red' onClick={handleRemoveClick}>
                            Remove
                          </Button>
                          {/* Remove Modal */}
                          <Modal
                            isCentered
                            isOpen={isRemoveModalOpen}
                            onClose={handleCloseRemoveModal}
                          >
                            <ModalContent>
                              <ModalHeader>Confirm Removal</ModalHeader>
                              <ModalBody>
                                Are you sure you want to remove this poster from
                                our platform?
                              </ModalBody>
                              <ModalFooter gap='20px'>
                                <Button
                                  colorScheme='red'
                                  onClick={handleConfirmRemove}
                                >
                                  Yes
                                </Button>
                                <Button onClick={handleCloseRemoveModal}>
                                  No
                                </Button>
                              </ModalFooter>
                            </ModalContent>
                          </Modal>
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                </TabPanel>
                <TabPanel w='100%' padding='0px'>
                  <Flex direction='column' pt='50px' pb='50px' align='center'>
                    <Flex direction='column'>
                      <form
                        style={{ width: '100%' }}
                        onSubmit={handleVideoSubmit}
                      >
                        <Flex align='end' p='20px' w='100%'>
                          <FormControl>
                            <FormLabel style={videoBigTextStyle}>
                              Upload brand video
                            </FormLabel>
                            <InputGroup align='center'>
                              <Button
                                style={videoBigTextStyle1}
                                border='1px solid #3D445A'
                                bg='transparent'
                                _hover={{ bg: 'transparent' }}
                                onClick={() => {
                                  // Trigger the hidden file input when the button is clicked
                                  document.getElementById('videoInput').click()
                                }}
                                width={{ base: '320px' }}
                                position='relative'
                              >
                                <MdOutlineFileUpload
                                  size='25px'
                                  style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)'
                                  }}
                                />
                              </Button>
                              {/* Hidden file input */}
                              <Input
                                type='file'
                                id='videoInput'
                                accept='video/*'
                                name='videoStoredAt'
                                onChange={e => handleVideoUpload(e)}
                                display='none'
                              />
                            </InputGroup>
                          </FormControl>

                          <Button
                            bg='blue.600'
                            color='white'
                            w='210px'
                            h='50px'
                            _hover={{ bg: 'blue.500' }}
                            type='submit'
                          >
                            Save
                          </Button>
                        </Flex>
                      </form>
                      <Flex
                        gap='20px'
                        align='center'
                        borderRadius='10px'
                        border='1px solid #878992'
                        p='20px'
                      >
                        <Flex>
                          {video ? (
                            <Text style={videoBigTextStyle}>
                              You have uploaded a video named :{' '}
                              {video?.videoStoredAt?.name}
                            </Text>
                          ) : (
                            // <Image
                            //   Src={
                            //     !!video?.videoStoredAt
                            //       ? URL.createObjectURL(video?.videoStoredAt)
                            //       : ''
                            //   }
                            // />
                            <Text style={videoBigTextStyle}>No video</Text>
                          )}
                        </Flex>
                      </Flex>
                    </Flex>

                    <Flex
                      overflowY='auto'
                      maxHeight='700px'
                      p='35px 24px'
                      direction='column'
                      gap='3rem'
                    >
                      {[1, 2, 3, 4].map((data, index) => (
                        <Flex
                        key={data.id}
                          p='15px'
                          borderRadius='10px'
                          bg='#000000'
                          w='100%'
                          gap='1rem'
                          cursor='pointer'
                          direction='column'
                          align='center'
                        >
                          <Flex
                            bg='white'
                            w='40px'
                            h='40px'
                            borderRadius='50%'
                            textAlign='center'
                            color='white'
                            justify='center'
                            align='center'
                          >
                            <Text
                              fontWeight='600'
                              fontSize='20px'
                              color='black'
                            >
                              {index + 1}
                            </Text>
                          </Flex>
                          <Flex direction='column'>
                            <Text style={videoBigTextStyle1}>
                              Brand video :
                            </Text>
                            <Image src={thumbnail} />
                          </Flex>
                          <Button colorScheme='red' onClick={handleRemoveClick}>
                            Remove
                          </Button>
                          {/* Remove Modal */}
                          <Modal
                            isCentered
                            isOpen={isRemoveModalOpen}
                            onClose={handleCloseRemoveModal}
                          >
                            <ModalContent>
                              <ModalHeader>Confirm Removal</ModalHeader>
                              <ModalBody>
                                Are you sure you want to remove this video from
                                our platform?
                              </ModalBody>
                              <ModalFooter gap='20px'>
                                <Button
                                  colorScheme='red'
                                  onClick={handleConfirmRemove}
                                >
                                  Yes
                                </Button>
                                <Button onClick={handleCloseRemoveModal}>
                                  No
                                </Button>
                              </ModalFooter>
                            </ModalContent>
                          </Modal>
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                </TabPanel>
                <TabPanel w='100%' padding='0px'>
                  <Flex direction='column' pt='50px' pb='50px' align='center'>
                    <Flex
                      borderRadius='20px'
                      w='80%'
                      h='100%'
                      border='2px solid purple'
                      p='40px'
                      gap='50px'
                      justify='space-evenly'
                      direction='column'
                    >
                      <Flex w='100%' gap='50px' justify='space-evenly'>
                        <Flex
                          gap='50px'
                          style={videoBigTextStyle}
                          direction='column'
                        >
                          <Text>
                            Created on :{' '}
                            <span style={videoBigTextStyle1}>27-12-2023</span>
                          </Text>
                          <Text>
                            Partnership till :{' '}
                            <span style={videoBigTextStyle1}>29-12-2023</span>
                          </Text>
                        </Flex>
                        <Flex
                          gap='50px'
                          style={videoBigTextStyle}
                          direction='column'
                        >
                          <Text>
                            Status :{' '}
                            <span style={videoBigTextStyle1}>{status}</span>
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex direction='column'>
                      <Flex align='end' p='20px' w='100%'>
                        <Button
                          bg={'ACTIVE' === status ? 'red' : 'green'}
                          color='white'
                          w='210px'
                          h='50px'
                          _hover={'ACTIVE' === status ? 'red' : 'green'}
                          onClick={openModal}
                        >
                          {'ACTIVE' === status ? 'Disable this brand QR' : 'Enable this brand QR'}
                        </Button>
                        <Modal
                          isCentered
                          isOpen={isModalOpen}
                          onClose={closeModal}
                        >
                          <ModalOverlay />
                          <ModalContent>
                            <ModalHeader>Confirmation</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                              Do you really want to{' '}
                              {'ACTIVE' === status ? 'disable' : 'enable'} this
                              brand's QR?
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                colorScheme='red'
                                mr={3}
                                onClick={handleConfirmation}
                              >
                                Yes
                              </Button>
                              <Button colorScheme='gray' onClick={closeModal}>
                                No
                              </Button>
                            </ModalFooter>
                          </ModalContent>
                        </Modal>
                      </Flex>
                    </Flex>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  )
}

const _MapDispatchToProps = __dispatch => ({
  AccountUpdate: u => __dispatch({ type: 'ACCOUNT_UPDATE', Account: u }),
  AccountLogout: () => __dispatch({ type: 'ACCOUNT_CLEAR' })
})

const _MapStateToProps = state => ({
  account: state.Account
})

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index)
