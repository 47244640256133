/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React, { useRef, useState, useEffect } from "react";
import "./index.css";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import { connect } from "react-redux"; // NPM: React-redux library.
import _ from "underscore";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// Import required modules
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import MovieDetail from "./MovieDetail";
import premium from "../../../../../../Assets/premiumSvg.svg";

/*
 * CHAKRA IMPORTS
 */
import {
  Flex,
  Button,
  Text,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  InputGroup,
  InputRightElement,
  FormLabel,
  FormControl,
  useToast,
  Spinner,
  Select,
} from "@chakra-ui/react";

/*
 * MUTATION
 */
import VideoCategoryEdit from "../../../__mutation__/index.trayUpdate.mutation";

/*
 * QUERY
 */
import AvailableCategoryName from "../../../__query__/index.verifyCategoryName.query";
import AvailablePriority from "../../../__query__/index.availablePriority.query";

/*
 * OBJECT
 */
function Index({ props, account, setTrayEditLoading }) {
  const { trayData, title, trayId } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(title);
  const [selectedOption, setSelectedOption] = useState("");
  const [options, setOptions] = useState();
  const toast = useToast();
  const margin = 10; // Margin between cards

  const [AvailableCategoryNameQuery, { loading: loadingVerifyQuery }] =
    useLazyQuery(AvailableCategoryName, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const { refetch: AvailablePriorityRefetch, data: AvailablePriorityQuery } =
    useQuery(AvailablePriority, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const [VideoCategoryEditMutation, { loading: loadingTrayUpdate }] =
    useMutation(VideoCategoryEdit, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleTrayUpdate = async () => {
    try {
      const response = await VideoCategoryEditMutation({
        variables: {
          categoryId: trayId,
          categoryName: inputValue,
          priority: parseInt(selectedOption),
        },
      });

      const data = response.data.VideoCategoryEdit;

      if (!_.isEmpty(data)) {
        if (data.status === "CATEGORY_EDITED_SUCCESSFULLY") {
          setTrayEditLoading(true);
          toast({
            title: "Form Submitted",
            description: "Data saved successfully!",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          closeModal();
        }
      }
    } catch (error) {
      // Show error toast
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  async function HandleAvailablePriorityRefetch() {
    await AvailablePriorityRefetch();
  }

  const handleTrayNameVerify = async () => {
    try {
      const response = await AvailableCategoryNameQuery({
        variables: {
          name: inputValue,
        },
      });

      const data = response.data.AvailableCategoryName;

      if (!_.isEmpty(data)) {
        if (data.status === "AVAILABLE_CATEGORY_NAME") {
          toast({
            title: "Success",
            description: "You can assign this name to the tray.",
            status: "success",
            duration: 5000, // Milliseconds
            isClosable: true,
          });
        } else if (data.status === "CATEGORY_NAME_EXISTS") {
          toast({
            title: "Error",
            description: "This name has already been taken .",
            status: "error",
            duration: 5000, // Milliseconds
            isClosable: true,
          });
        }
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred .",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    HandleAvailablePriorityRefetch();
  }, []);

  useEffect(() => {
    if (AvailablePriorityQuery && AvailablePriorityQuery.AvailablePriority) {
      setOptions(AvailablePriorityQuery?.AvailablePriority);
    }
  }, [AvailablePriorityQuery]);

  return (
    <Flex py="2rem" bg="transparent" flexDir="column">
      <Flex align="center" gap="10px">
        <Text pb="5px" pl="30px" color="white" fontSize="28px">
          {title}
        </Text>

        <Button onClick={openModal}>Edit tray title</Button>

        <Modal isCentered isOpen={isOpen} onClose={closeModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit tray </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <FormLabel>Tray title</FormLabel>
                <InputGroup>
                  <Input
                    placeholder="Section Name"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      onClick={handleTrayNameVerify}
                      h="1.75rem"
                      size="sm"
                    >
                      {loadingVerifyQuery ? <Spinner /> : "Verify"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl mt="1rem">
                <FormLabel>Tray position</FormLabel>
                <Select
                  placeholder="Select tray priority"
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  {options?.map((option, index) => (
                    <option key={index} value={option?.priority}>
                      {option?.priority}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleTrayUpdate}>
                {loadingTrayUpdate ? <Spinner /> : "Save"}
              </Button>
              <Button variant="ghost" onClick={closeModal}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>

      <Flex ml="10px" mt="20px" position="relative" maxW="100%">
        {trayData?.length > 0 ? (
          <Flex w="100%">
            <Swiper
              slidesPerView={5}
              spaceBetween={margin}
              centeredSlides={false}
              modules={[Pagination, Navigation]}
              className="mySwiper"
              style={{
                width: "100%",
                alignItems: "center",
                overflow: "visible",
              }}
            >
              {trayData?.map((movie, index) => (
                <SwiperSlide
                  key={index}
                  style={{
                    width: "100px",
                  }}
                >
                  <Flex w="100%" justify="center" align="center" h="100%">
                    <CardComp
                      account={account}
                      key={index}
                      item={movie}
                      title={title}
                    />
                  </Flex>
                </SwiperSlide>
              ))}
            </Swiper>
          </Flex>
        ) : (
          <Flex
            w="100%"
            justify="center"
            align="center"
            h="300px"
            fontWeight="bold"
          >
            <Text color="white" fontSize="40px">
              NO VIDEOS IN THIS TRAY
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

/*
 * COMPONENT
 */
function CardComp({ account, item, title }) {
  const cardRef = useRef(null);
  const [showModal, setShowModal] = useState(false);

  const titleStyle = {
    color: "#FFFFFF",
    fontWeight: "700",
    fontFamily: "Montserrat",
    fontSize: "20px",
  };
  const subTitleStyle = {
    color: "#878992",
    fontWeight: "600",
    fontFamily: "Montserrat",
    fontSize: "16px",
    letterSpacing: "-0.32px",
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  function truncateAfterTenLetters(text) {
    if (!text) return "";
    if (text.length <= 20) return text;

    return `${text.substring(0, 20)}...`;
  }

  return (
    <Flex
      ref={cardRef}
      mx="10px"
      borderRadius="15px"
      position="relative"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      onClick={toggleModal}
      cursor="pointer"

    >
      <Flex
        w="320px"
        h="200px"
        className="card_image_div"
        borderRadius="15px"
        position="relative"
      >
        <Image
          src={item?.ContentSummary?.ContentDetail[0]?.lowerThumbnailLink}
          borderRadius="15px"
          className="movie_thumbnail"
          alt="image"
          w="100%"
          h="100%"
          objectFit="fill"
        />
        {item?.ContentSummary?.ContentDetail?.[0]?.isPremium === "PREMIUM" ? (
          <Flex>
            <Image
              src={premium}
              alt="premium"
              w="20px"
              h="20px"
              position="absolute"
              top="10px"
              left="10px"
            />
          </Flex>
        ) : null}
      </Flex>
      <Flex p="10px" w="100%" direction="column">
        <Text style={titleStyle}>{truncateAfterTenLetters(item?.ContentSummary?.contentTitle)}</Text>

        <Text style={subTitleStyle}>
          {new Date(
            item?.ContentSummary?.ContentDetail[0]?.createdAt
          ).getFullYear()}{" "}
          |
          <span style={{ textTransform: "capitalize" }}>
            {item?.ContentSummary?.genre}
          </span>
        </Text>
      </Flex>

      <MovieDetail
        account={account}
        isOpen={showModal}
        onClose={toggleModal}
        movieDetails={item}
        title={title}
      />
    </Flex>
  );
}
