
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import _ from "underscore";
import { MdOutlineFileUpload } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux"; // NPM: React-redux library.
import { useMutation, useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import ReactPlayer from "react-player";

import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";

/*
 * CHAKRA IMPORTS
 */
import {
  Flex,
  Text,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalBody,
  useToast,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";

/*
 * MUTATAION
 */

import BrandVideoUpload from "./__mutation__/index.brandVideoUpload.mutation";

import BrandVideoRemove from "./__mutation__/index.brandVideoRemove.mutation";

/*
 * QUERY
 */
import BrandDetailsRead from "./__query__/index.brandDetailRead.query";

function Index({ account }) {
  /*
   * States
   */

  const [video, setVideo] = useState({
    videoStoredAt: null,
    uniqueIdVideo: null,
  });

 const [videoLoading , setVideoLoading] = useState(false);

  const [adRemoveId, setAdRemoveId] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [adDetail, setAdDetail] = useState();
  const id = searchParams.get("id");
  const toast = useToast();

  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure();

  const {
    refetch: BrandDetailsReadRefetch,
    data: BrandDetailsReadQuery,

  } = useQuery(BrandDetailsRead, {
    variables: { BrandNameId: id },
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  const [BrandVideoUploadMutation, { loading: loadingVideoUpload }] =
    useMutation(BrandVideoUpload, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const [BrandVideoRemoveMutation, { loading: loadingBrandVideoRemove }] =
    useMutation(BrandVideoRemove, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  /*
   * STYLE
   */

  const videoBigTextStyle = {
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0.2px",
  };

  const videoBigTextStyle1 = {
    color: "rgba(116, 116, 116, 1)",
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0.2px",
  };

  /*
   * Functions
   */

  const handleAdRemove = async () => {
    try {
      const response = await BrandVideoRemoveMutation({
        variables: {
          BrandVideoId: adRemoveId,
        },
      });

      const data = response.data.BrandVideoRemove;

      if (!_.isEmpty(data)) {
        toast({
          title: "Form Submitted",
          description: "Data saved successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        closeModal();
      }
    } catch (error) {
      // Show error toast
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  const handleBrandVideoUpload = async (videoLink) => {
    try {
      const response = await BrandVideoUploadMutation({
        variables: {
          brandNameId: id,
          brandVideoLink: videoLink,
        },
      });

      const data = response.data.BrandVideoUpload;

      if (!_.isEmpty(data)) {
        toast({
          title: "Form Submitted",
          description: "Data saved successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setVideo({
          videoStoredAt: null,
          uniqueIdVideo: null,
        });
      }
    } catch (error) {
      // Show error toast
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  const _uploadBrandVideo = async (e) => {
    e.preventDefault();
    if (video?.videoStoredAt) {
      setVideoLoading(true);
      const id = uuidv4();

      try {
        const s3Client = new S3Client({
          forcePathStyle: false,
          endpoint: process.env.REACT_APP_S3_ENDPOINT,
          region: "blr1",
          credentials: {
            accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
          },
        });

        const parallelUploads3 = new Upload({
          client: s3Client,
          params: {
            Bucket: "shucaeimage",
            Key: `shucaeContent/${id}.mp4`,
            Body: video?.videoStoredAt,
            ACL: "public-read",
          },
          queueSize: 4, // Optional concurrency configuration
          partSize: 1024 * 1024 * 5, // Optional size of each part, in bytes
          leavePartsOnError: false, // Optional manually handle dropped parts
        });

        const _data = await parallelUploads3.done();
        setVideoLoading(false);

        if (_data.Location) {
          if (!_data.Location.startsWith("https://")) {
            _data.Location = `https://${_data.Location}`;
          }
          handleBrandVideoUpload(_data.Location);
        }
      } catch (e) {
        setVideoLoading(false);
      } 
    }
  };

  async function HandleBrandDetailsReadRefetch() {
    await BrandDetailsReadRefetch();
    // Your code to execute after the refetch is complete
  }

  useEffect(() => {
    if (BrandDetailsReadQuery && BrandDetailsReadQuery.BrandDetailsRead) {
      setAdDetail(BrandDetailsReadQuery.BrandDetailsRead[0]?.BrandVideos);
    }
  }, [BrandDetailsReadQuery]);

  useEffect(() => {
    HandleBrandDetailsReadRefetch();
  }, [loadingVideoUpload, loadingBrandVideoRemove]);

  const handleRemoveClick = (id) => {
    setAdRemoveId(id);
    openModal();
  };

  const handleVideoUpload = (e) => {
    const file = e.target.files[0];

    // Check if a file is selected
    if (file) {
      const generatedUniqueId = uuidv4();

      setVideo({
        ...video,
        videoStoredAt: file,
        uniqueIdVideo: generatedUniqueId,
      });
    }
  };

  return (
    <Flex direction="column" pt="50px" pb="50px" align="center">
      <Flex direction="column">
        <form
          style={{ width: "100%" }}
          onSubmit={(e) => _uploadBrandVideo(e)}
        >
          <Flex align="end" p="20px" w="100%">
            <FormControl>
              <FormLabel style={videoBigTextStyle}>
                Upload brand video
              </FormLabel>
              <InputGroup align="center">
                <Button
                  style={videoBigTextStyle1}
                  border="1px solid #3D445A"
                  bg="transparent"
                  _hover={{ bg: "transparent" }}
                  onClick={() => {
                    // Trigger the hidden file input when the button is clicked
                    document.getElementById("videoInput").click();
                  }}
                  width={{ base: "320px" }}
                  position="relative"
                >
                  <MdOutlineFileUpload
                    size="25px"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                </Button>
                {/* Hidden file input */}
                <Input
                  type="file"
                  id="videoInput"
                  accept="video/*"
                  name="videoStoredAt"
                  onChange={(e) => handleVideoUpload(e)}
                  display="none"
                />
              </InputGroup>
            </FormControl>

            <Button
              bg="blue.600"
              color="white"
              w="210px"
              h="50px"
              _hover={{ bg: "blue.500" }}
              type="submit"
            >
              {loadingVideoUpload || videoLoading ? <Spinner /> : "Save"}
            </Button>
          </Flex>
        </form>
        <Flex
          gap="20px"
          align="center"
          borderRadius="10px"
          border="1px solid #878992"
          p="20px"
        >
          <Flex>
            {video ? (
              <Text style={videoBigTextStyle}>
                You have uploaded a video named : {video?.videoStoredAt?.name}
              </Text>
            ) : (
              
              <Text style={videoBigTextStyle}>No video</Text>
            )}
          </Flex>
        </Flex>
      </Flex>

      <Flex
        overflowY="auto"
        maxHeight="700px"
        p="35px 24px"
        direction="column"
        gap="3rem"
      >
        {adDetail?.map((data, index) => (
          <Flex
          key={data.id}
            p="15px"
            borderRadius="10px"
            bg="#000000"
            w="100%"
            gap="1rem"
            cursor="pointer"
            direction="column"
            align="center"
          >
            <Flex
              bg="white"
              w="40px"
              h="40px"
              borderRadius="50%"
              textAlign="center"
              color="white"
              justify="center"
              align="center"
            >
              <Text fontWeight="600" fontSize="20px" color="black">
                {index + 1}
              </Text>
            </Flex>
            <Flex direction="column">
              <Text style={videoBigTextStyle1}>Brand video :</Text>
              <ReactPlayer
                url={data?.brandVideo}
                width="100%"
                height="100%"
                controls
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
              />
            </Flex>
            <Button
              colorScheme="red"
              onClick={() => handleRemoveClick(data?.id)}
            >
              Remove
            </Button>
            {/* Remove Modal */}
            <Modal isCentered isOpen={isModalOpen} onClose={closeModal}>
              <ModalContent>
                <ModalHeader>Confirm Removal</ModalHeader>
                <ModalBody>
                  Are you sure you want to remove this video from our platform?
                </ModalBody>
                <ModalFooter gap="20px">
                  <Button colorScheme="red" onClick={handleAdRemove}>
                    {loadingBrandVideoRemove ? <Spinner /> : "Yes"}
                  </Button>
                  <Button onClick={closeModal}>No</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
