/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // Npm: Apollo client for handling graphql request.

/*
 * GRAPHS
 */
const Index = gql`
  query MyQuery($sellerFormId: ID) {
    SellerFormReadAll(sellerFormId: $sellerFormId) {
      ageRating
      agreeTerms
      airborneRight
      allRights
      cast
      country
      contentLink
      contentTitle
      contentType
      createdAt
      digitalRight
      directorName
      duration
      email
      exclusive
      firstName
      genre
      haveCopyrights
      id
      imdbRating
      language
      lastName
      message
      music
      nonExclusive
      phone
      plotSummary
      producer
      satelliteRight
      status
      storyWriter
      territory
      territorySelect
      thumbnailStoredAt
      updatedAt
      verticalThumbnailStoredAt
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
