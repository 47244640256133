/* eslint-disable react/react-in-jsx-scope */
/*
 * IMPORT
 */

import "./loader.css";

/*
 * CHAKRA IMPORTS
 */

/*
 * OBJECT
 */
function Loader() {
  /*
   * STATE
   */

  return (
    <div className="contain">
      <svg className="load" height="80" viewBox="-30 0 255 80" width="255">
        <ellipse cx="25" cy="20" fill="none" rx="10" ry="10"></ellipse>
      </svg>
      <svg className="load" height="80" viewBox="-30 0 255 80" width="255">
        <ellipse cx="25" cy="20" fill="none" rx="10" ry="10"></ellipse>
      </svg>
      <svg className="load" height="80" viewBox="-30 0 255 80" width="255">
        <ellipse cx="25" cy="20" fill="none" rx="10" ry="10"></ellipse>
      </svg>
      <svg className="load" height="80" viewBox="-30 0 255 80" width="255">
        <ellipse cx="25" cy="20" fill="none" rx="10" ry="10"></ellipse>
      </svg>
      <svg className="load" height="80" viewBox="-30 0 255 80" width="255">
        <ellipse cx="25" cy="20" fill="none" rx="10" ry="10"></ellipse>
      </svg>
      <svg className="load" height="80" viewBox="-30 0 255 80" width="255">
        <ellipse cx="25" cy="20" fill="none" rx="10" ry="10"></ellipse>
      </svg>
      <svg className="load" height="80" viewBox="-30 0 255 80" width="255">
        <ellipse cx="25" cy="20" fill="none" rx="10" ry="10"></ellipse>
      </svg>
      <svg className="load" height="80" viewBox="-30 0 255 80" width="255">
        <ellipse cx="25" cy="20" fill="none" rx="10" ry="10"></ellipse>
      </svg>
      <svg className="load" height="80" viewBox="-30 0 255 80" width="255">
        <ellipse cx="25" cy="20" fill="none" rx="10" ry="10"></ellipse>
      </svg>
      <svg className="load" height="80" viewBox="-30 0 255 80" width="255">
        <ellipse cx="25" cy="20" fill="none" rx="10" ry="10"></ellipse>
      </svg>
    </div>
  );
}

/*
 * EXPORT
 */
export default Loader;
