/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation(
    $adsPoster: JSON
    $adsUrl: String
    $brandName: String
    $verticalPoster: JSON
  ) {
    HomePageAdsCreate(
      adsPoster: $adsPoster
      adsUrl: $adsUrl
      brandName: $brandName
      verticalPoster: $verticalPoster
    ) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
