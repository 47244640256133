/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation(
    $email: String!
    $level: Int!
    $name: String!
    $password: String!
    $brandname: String
  ) {
    AdminCreate(
      email: $email
      level: $level
      name: $name
      password: $password
      brandname: $brandname
    ) {
      status
      message
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
