/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query FetchAllContentDetail {
    FetchAllContentDetail {
      id
      plotSummary
      lowerThumbnailLink
      duration
      approved
      declined
      likes
      createdAt
      watchTime
      ContentSummary {
        id
        ageRating
        contentTitle
        contentLanguage
        genre
        contentType
        cast
        Account {
          id
          fullName
          displayName
          userName
          Follow_Follow_Account__followerIdToAccount {
            id
          }
        }
      }
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
