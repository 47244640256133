/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation(
    $content: [JSON]
    $horizontalPoster: JSON
    $PodcastDescription: String
    $podcastTitle: String
    $verticalPoster: JSON
    $producedBy: String
    $directedBy: String
    $host: [JSON]
    $guest: [JSON]
  ) {
    PodcastCreate(
      content: $content
      horizontalPoster: $horizontalPoster
      PodcastDescription: $PodcastDescription
      podcastTitle: $podcastTitle
      verticalPoster: $verticalPoster
      producedBy: $producedBy
      directedBy: $directedBy
      host: $host
      guest: $guest
    ) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
