/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query AccountCreatorList {
    AccountCreatorList {
      message
      id
      createdAt
      fullName
      userName
      email
      phone
      totalVideoCount
      Follow_Follow_Account__followerIdToAccount {
        id
      }
      Follow_Follow_Account__followingIdToAccount {
        id
      }
      ContentSummary {
        id
        ageRating
        ContentDetail {
          id
          plotSummary
          contentLink
          approved
        }
      }
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
