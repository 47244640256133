/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery {
    DashBoardNumbers {
      TotalCreator
      totalAds
      approvedVideos
      totalVideos
      unApprovedvideos
      totalAccountsNumber
      totalDeveloperAccounts
      status
      message
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
