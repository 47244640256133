/* eslint-disable react/jsx-key */
/* eslint-disable no-bitwise */
/* eslint-disable max-lines */
/* eslint-disable init-declarations */
/* eslint-disable max-lines-per-function */
/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";
import { connect } from "react-redux"; // NPM: React-redux library.
import Loader from "../LandingPage/Loader";
import ChartTabPanelTotal from "./TotalVideoChart";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import DatePicker from "react-datepicker";
import { parseISO } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  Button,
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  useToast,
} from "@chakra-ui/react";

/*
 * MUTATION
 */

/*
 * QUERY
 */
import FetchAllContentDetail from "../__query__/index.totalVideos.query";
import { useLazyQuery } from "@apollo/client";
import _ from "underscore";
import InProgress from "./InProgress";
import Approved from "./Approved";
import Declined from "./Declined";
import { tabStyle } from "../../../../components/Styles/index.style";

function Index({ account }) {
  /*
   * States
   */
  const toast = useToast();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [approved, setApproved] = useState();
  const [declined, setDeclined] = useState();
  const [inProcess, setInProcess] = useState();

  const [FetchAllContentDetailQuery, { loading: loadingVideoList }] =
    useLazyQuery(FetchAllContentDetail, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  /*
   * STYLE
   */

  /*
   * Functions
   */

  const handleCreatorList = async () => {
    try {
      const response = await FetchAllContentDetailQuery({
        variables: {},
      });

      const data = response.data.FetchAllContentDetail;

      if (!_.isEmpty(data)) {
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;
        if (end) {
          end.setDate(end.getDate() + 1);
        }
        const filteredData = data.filter((item) => {
          const createdAt = parseISO(item.createdAt);

          return (!start || createdAt >= start) && (!end || createdAt <= end);
        });

        const approvedData = filteredData.filter(
          (item) => item.approved === true
        );
        const declinedData = filteredData.filter(
          (item) => item.declined === true
        );
        const inProcessData = filteredData.filter(
          (item) => item.approved === false && item.declined === false
        );

        setApproved(approvedData);
        setDeclined(declinedData);
        setInProcess(inProcessData);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred .",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  const formatDate = (dateString) => {
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const date = new Date(dateString);

    return date.toLocaleString("en-US", options);
  };

  function formatDuration(durationInMinutes) {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.floor(durationInMinutes % 60);
    const seconds = Math.floor((durationInMinutes % 1) * 60);

    let formattedDuration = "";

    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }

    if (minutes > 0) {
      formattedDuration += `${minutes}m `;
    }

    if (seconds > 0) {
      formattedDuration += `${seconds}s`;
    }

    return formattedDuration.trim(); // Remove any leading/trailing whitespace
  }

  const exportToExcel = (data) => {
    data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    const ws = XLSX.utils.json_to_sheet(
      data.map((item, index) => ({
        "S.No.": index + 1,
        "Video Name": item?.ContentSummary?.contentTitle,
        "Type of Content": item?.ContentSummary?.contentType,
        Cast: item?.ContentSummary?.cast
          ?.map((cat) => `${cat.cast} - ${cat.name}`)
          .join(", "),
        Genre: item?.ContentSummary?.genre?.[0],
        'Content Language': item?.ContentSummary?.contentLanguage,
        "Age rating": `${item?.ContentSummary?.ageRating}+`,
        Duration: `${formatDuration(item?.duration)}`,
        "Watch Time": `${Math.round(item?.watchTime / 60)} mins`,
        "Uploaded On": formatDate(item.createdAt),
      })),
      {
        header: [
          "S.No.",
          "Video Name",
          "Type of Content",
          "Cast",
          "Genre",
          'Content Language',
          "Age rating",
          "Duration",
          "Watch Time",
          "Uploaded On",
        ],
      }
    );

    ws["!cols"] = [{ width: 5 }, { width: 25 }, { width: 20 }];
    ws["!rows"] = [{}, { hpx: 20 }];
    ws.A1.s = {
      font: { bold: true },
      fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFFF00" } },
    };
    ws.B1.s = {
      font: { bold: true },
      fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFFF00" } },
    };
    ws.C1.s = {
      font: { bold: true },
      fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFFF00" } },
    };
    ws.D1.s = {
      font: { bold: true },
      fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFFF00" } },
    };
    ws.E1.s = {
      font: { bold: true },
      fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFFF00" } },
    };
    ws.F1.s = {
      font: { bold: true },
      fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFFF00" } },
    };
    ws.G1.s = {
      font: { bold: true },
      fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFFF00" } },
    };

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);

      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;

      return buf;
    };

    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      "data.xlsx"
    );
  };

  useEffect(() => {
    handleCreatorList();
  }, []);

  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
        w="100%"
        minHeight="100vh"
      >
        <Flex w="100%" h="100px"></Flex>
        <Flex mt="1rem">
          <Flex overflow="scroll" direction="column" w="100%">
            <Tabs width="100%">
              <TabList
                gap="3rem"
                padding="0px"
                style={tabStyle}
                borderColor="none"
                borderBottom="1px solid #505050"
              >
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Approved Videos
                </Tab>
                <Tab
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  In Process Videos
                </Tab>
                <Tab
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Declined Videos
                </Tab>
                <Tab
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Analytics
                </Tab>
              </TabList>

              <TabIndicator
                mt="-2px"
                height="3px"
                bg="#FFFFFF"
                borderRadius="1px"
              />
              <Flex justify="space-between" p="20px">
                <Flex gap="20px" w="50%">
                  <Flex align="center" gap="10px">
                    <Text fontSize="20px" color="white">
                      Start Date :-
                    </Text>
                    <Box
                      as={DatePicker}
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select Start Date"
                      css={{
                        width: "200px",
                        padding: "10px",
                        fontSize: "16px",
                        color: "white",
                        backgroundColor: "black",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        "&:focus": {
                          borderColor: "#3182ce", // Chakra UI primary blue color
                          boxShadow: "0 0 5px rgba(49, 130, 206, 0.5)",
                          outline: "none",
                        },
                      }}
                    />
                  </Flex>
                  <Flex align="center" gap="10px">
                    <Text fontSize="20px" color="white">
                      End Date :-
                    </Text>
                    <Box
                      as={DatePicker}
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select End Date"
                      css={{
                        width: "200px",
                        padding: "10px",
                        fontSize: "16px",
                        color: "white",
                        backgroundColor: "black",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        "&:focus": {
                          borderColor: "#3182ce",
                          boxShadow: "0 0 5px rgba(49, 130, 206, 0.5)",
                          outline: "none",
                        },
                      }}
                    />
                  </Flex>
                  <Button
                    bg="green"
                    h="50px"
                    _hover={{ bg: "green.600" }}
                    color="white"
                    isDisabled={!startDate || !endDate}
                    onClick={() => handleCreatorList()}
                  >
                    Apply
                  </Button>
                </Flex>

                <Button
                  bg="blue"
                  _hover={{ bg: "blue.500" }}
                  color="white"
                  onClick={() => {
                    exportToExcel(approved);
                  }}
                >
                  Export all logs
                </Button>
              </Flex>
              <TabPanels>
                <TabPanel w="100%" padding="0px">
                  {loadingVideoList ? (
                    <Flex align="center" justify="center" w="100%">
                      <Loader />
                    </Flex>
                  ) : (
                    <Approved approved={approved} />
                  )}
                </TabPanel>
                <TabPanel w="100%" padding="0px">
                  <InProgress inProcess={inProcess} />
                </TabPanel>
                <TabPanel w="100%" padding="0px">
                  <Declined declined={declined} />
                </TabPanel>
                <TabPanel w="100%" padding="0px">
                  <Flex mt="3rem" justify="center">
                    <ChartTabPanelTotal
                      approved={approved?.length}
                      declined={declined?.length}
                      inProcess={inProcess?.length}
                    />
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
