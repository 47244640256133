/* eslint-disable react/prop-types */
/*
 * IMPORTS
 */
import React from "react";
import Navbar from "../LandingNavbar/index";
import Footer from "../LandingFooter/index";
import { connect } from "react-redux"; // NPM: React-redux library.
import CardComponent from "./CardComponent";
import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
/*
 * CHAKRA IMPORTS
 */
import { Flex, Box } from "@chakra-ui/react";

/*
 * QUERY
 */
import BuyFormCartInfo from "./__query__/index.cartRead.query";

/*
 * OBJECT
 */
function Index({ account }) {
  /*
   * STATE
   */
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");


  const _BuyFormCartInfo = useQuery(BuyFormCartInfo, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
    variables: {
      buyformId: id,
    },
  });

  /*
   * FUNCTION
   */

  /*
   * USEEFFECT
   */

  return (
    <>
      <Box bg="#00020A">
        <Navbar />

        <Box
          bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
          pt={{ base: "90px", md: "70px", lg: "30px" }}
          fontFamily="Poppins"
          fontSize={["clamp(12px, 1.5vw, 15px)"]}
          fontWeight="500"
          color="#263238"
          overflow="hidden"
        >
          <Flex
            ml={{ base: "1rem", md: "3rem" }}
            color="white"
            fontSize={{ base: "20px", md: "40px" }}
            pt="5rem"
          >
            {`Your Cart`}
          </Flex>
          <Flex
            p="1rem"
            h="90vh"
            gap="30px"
            flexWrap="wrap"
            justify="center"
            overflow="scroll"
          >
            {_BuyFormCartInfo?.data?.BuyFormCartInfo?.data?.[0]?.map((item ,index) => (
              <CardComponent item={item} key={index} />
            ))}
          </Flex>
        </Box>
        <Footer />
      </Box>
    </>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
  LogoUpdate: (u) => __dispatch({ type: "LOGO_INIT", Logo: u }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
  logo: state.Logo,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
