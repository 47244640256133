/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import Navbar from "../LandingNavbar/index";
import Footer from "../LandingFooter/index";
import { connect } from "react-redux"; // NPM: React-redux library.
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import _ from "underscore";
import { FiEdit3 } from "react-icons/fi";
import {
  videoBigTextStyle,
  videoBigTextStyle1,
  tabStyle,
  sellbutton,
} from "../Styles/index.style.js";

/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  useToast,
  Flex,
  Text,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  FormControl,
  FormLabel,
  Select,
  Spinner,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
  Textarea,
  Input,
} from "@chakra-ui/react";

/*
 * QUERY
 */
import SellerFormReadAll from "./__query__/index.sellerFormApprove.query";

/*
 * MUTATION
 */
import SellerFormApprove from "./__mutation__/index.approveSellerForm.mutation";
import SellerFormDelete from "./__mutation__/index.declineSellerForm.mutation";
import ReactPlayer from "react-player";

/*
 * OBJECT
 */
function Index({ account }) {
  /*
   * States
   */
  const toast = useToast();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [approvedStatus, setApprovedStatus] = useState(false);
  const [isApproveButtonDisabled, setIsApproveButtonDisabled] = useState(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  const openApproveModal = () => setIsApproveModalOpen(true);
  const closeApproveModal = () => setIsApproveModalOpen(false);
  const openDeclineModal = () => setIsDeclineModalOpen(true);
  const closeDeclineModal = () => setIsDeclineModalOpen(false);

  const _SellerFormReadAll = useQuery(SellerFormReadAll, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },

    variables: {
      sellerFormId: id,
    },
  });

  const [SellerFormApproveMutation, { loading: SellerFormApproveLoading }] =
    useMutation(SellerFormApprove, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const [SellerFormDeleteMutation, { loading: SellerFormDeleteLoading }] =
    useMutation(SellerFormDelete, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  

  /*
   * Functions
   */

  function formatDuration(durationInMinutes) {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.floor(durationInMinutes % 60);
    const seconds = Math.floor((durationInMinutes % 1) * 60);

    // eslint-disable-next-line init-declarations
    let formattedDuration = "";

    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }

    if (minutes > 0) {
      formattedDuration += `${minutes}m `;
    }

    if (seconds > 0) {
      formattedDuration += `${seconds}s`;
    }

    return formattedDuration.trim(); // Remove any leading/trailing whitespace
  }

  function handleApproveClick() {
    setApprovedStatus(true);

    openApproveModal();
  }

  function handleDeclineClick() {
    setApprovedStatus(false);
    openDeclineModal();

    // Disable the "Approve" button
    setIsApproveButtonDisabled(true);
  }

  const handleApproveRequest = async () => {
    try {
      const response = await SellerFormApproveMutation({
        variables: {
          sellerFormId: _SellerFormReadAll?.data?.SellerFormReadAll?.[0]?.id,
        },
      });

      const data = response.data.SellerFormApprove;

      if (!_.isEmpty(data)) {
        if (data?.status === "APPROVED_SUCCESSFUL") {
          toast({
            title: "Form Submitted",
            description: "Data saved successfully!",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          history.push("/");
        }
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };
  const handleDecline = async () => {
    try {
      const response = await SellerFormDeleteMutation({
        variables: {
          sellerFormId: _SellerFormReadAll?.data?.SellerFormReadAll?.[0]?.id,
        },
      });

      const data = response.data.SellerFormDelete;

      if (!_.isEmpty(data)) {
        if (data?.status === "DELETE_SUCCESSFUL") {
          toast({
            title: "Form Submitted",
            description: "Data saved successfully!",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          history.push("/");
        }
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
        w="100%"
        minHeight="100vh"
      >
        <Flex w="100%" h="100px"></Flex>

        <Flex mt="1rem">
          <Flex overflow="scroll" direction="column" w="100%">
            <Tabs width="100%">
              <TabList
                gap="3rem"
                padding="0px"
                style={tabStyle}
                borderColor="none"
                borderBottom="1px solid #505050"
              >
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Seller Form Approval
                </Tab>
              </TabList>
              <TabIndicator
                mt="-2px"
                height="3px"
                bg="#FFFFFF"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel w="100%" padding="0px">
                  <Flex direction="column" pt="50px" pb="50px" align="center">
                    <Flex
                      borderRadius="20px"
                      w="80%"
                      h="100%"
                      border="2px solid purple"
                      p="40px"
                      gap="50px"
                      justify="space-evenly"
                      direction="column"
                    >
                      <Flex w="100%" gap="50px" justify="space-evenly">
                        <Flex
                          gap="50px"
                          style={videoBigTextStyle}
                          direction="column"
                        >
                          <Text>
                            User name :{" "}
                            <span
                              style={videoBigTextStyle1}
                            >{`${_SellerFormReadAll?.data?.SellerFormReadAll?.[0]?.firstName} ${_SellerFormReadAll?.data?.SellerFormReadAll?.[0]?.lastName}`}</span>
                          </Text>
                          <Text textTransform="capitalize">
                            Email :{" "}
                            <span style={videoBigTextStyle1}>
                              {
                                _SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                                  ?.email
                              }
                            </span>
                          </Text>
                          <Text textTransform="capitalize">
                            Type of content :{" "}
                            <span style={videoBigTextStyle1}>
                              {
                                _SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                                  ?.contentType
                              }
                            </span>
                          </Text>
                          <Text textTransform="capitalize">
                            Producer / Production House :{" "}
                            <span style={videoBigTextStyle1}>
                              {
                                _SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                                  ?.producer
                              }
                            </span>
                          </Text>
                          <Text textTransform="capitalize">
                            Genre :{" "}
                            <span style={videoBigTextStyle1}>
                              {
                                _SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                                  ?.genre
                              }
                            </span>
                          </Text>
                          <Text textTransform="capitalize">
                            IMDB Rating :{" "}
                            <span style={videoBigTextStyle1}>
                              {
                                _SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                                  ?.imdbRating
                              }
                              +
                            </span>
                          </Text>
                          <Text textTransform="capitalize">
                            Music :{" "}
                            <span style={videoBigTextStyle1}>
                              {
                                _SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                                  ?.music
                              }
                            </span>
                          </Text>
                          <Text textTransform="capitalize">
                            Cast :{" "}
                            {_SellerFormReadAll?.data?.SellerFormReadAll?.[0]?.cast?.map(
                              (item) => (
                                <span style={videoBigTextStyle1} key={item?.id}>
                                  {item.name},{" "}
                                </span>
                              )
                            )}
                          </Text>
                          <Text textTransform="capitalize">
                            Exclusive :{" "}
                            <span style={videoBigTextStyle1}>
                              {_SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                                ?.exclusive
                                ? "Yes"
                                : "No"}
                            </span>
                          </Text>
                          <Text textTransform="capitalize">
                            All Rights :{" "}
                            <span style={videoBigTextStyle1}>
                              {_SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                                ?.allRights
                                ? "Yes"
                                : "No"}
                            </span>
                          </Text>
                          <Text textTransform="capitalize">
                            Satellite Rights :{" "}
                            <span style={videoBigTextStyle1}>
                              {_SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                                ?.satelliteRights
                                ? "Yes"
                                : "No"}
                            </span>
                          </Text>
                        </Flex>
                        <Flex
                          gap="50px"
                          style={videoBigTextStyle}
                          direction="column"
                        >
                          <Flex
                            align="center"
                            gap="8px"
                            textTransform="capitalize"
                          >
                            Content title :{" "}
                            <span style={videoBigTextStyle1}>
                              {
                                _SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                                  ?.contentTitle
                              }
                            </span>
                          </Flex>
                          <Text>
                            Contact Number :{" "}
                            <span style={videoBigTextStyle1}>
                              {
                                _SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                                  ?.phone
                              }
                            </span>
                          </Text>
                          <Text>
                            Director Name :{" "}
                            <span style={videoBigTextStyle1}>
                              {
                                _SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                                  ?.directorName
                              }
                            </span>
                          </Text>
                          <Text>
                            Content Language :{" "}
                            <span style={videoBigTextStyle1}>
                              {
                                _SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                                  ?.language
                              }
                            </span>
                          </Text>
                          <Text>
                            Age rating :{" "}
                            <span style={videoBigTextStyle1}>
                              {
                                _SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                                  ?.ageRating
                              }
                            </span>
                          </Text>
                          <Text>
                            Story Writer :{" "}
                            <span style={videoBigTextStyle1}>
                              {
                                _SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                                  ?.storyWriter
                              }
                            </span>
                          </Text>
                          <Text>
                            Duration :{" "}
                            <span style={videoBigTextStyle1}>
                              {formatDuration(
                                _SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                                  ?.duration
                              )}
                            </span>
                          </Text>
                          <Text textTransform="capitalize">
                            Digital Rights :{" "}
                            <span style={videoBigTextStyle1}>
                              {_SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                                ?.digitalRights
                                ? "Yes"
                                : "No"}
                            </span>
                          </Text>
                          <Text textTransform="capitalize">
                            Airborne Rights :{" "}
                            <span style={videoBigTextStyle1}>
                              {_SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                                ?.airborneRights
                                ? "Yes"
                                : "No"}
                            </span>
                          </Text>
                          <Text textTransform="capitalize">
                            Territory :{" "}
                            <span style={videoBigTextStyle1}>
                              {_SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                                ?.territory
                                ? "Yes"
                                : "No"}
                            </span>
                          </Text>
                          <Text textTransform="capitalize">
                            Specific Territory :{" "}
                            <span style={videoBigTextStyle1}>
                              {
                                _SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                                  ?.territorySelect
                              }
                            </span>
                          </Text>
                          <Text textTransform="capitalize">
                            Country :{" "}
                            <span style={videoBigTextStyle1}>
                              {
                                _SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                                  ?.country
                              }
                            </span>
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex>
                        <Flex
                          align="center"
                          justify="center"
                          gap="10px"
                          direction="column"
                          w="100%"
                        >
                          <Flex
                            align="center"
                            gap="8px"
                            style={videoBigTextStyle}
                          >
                            Plot Summary
                          </Flex>
                          <Text
                            textAlign="center"
                            w="80%"
                            style={videoBigTextStyle1}
                          >
                            {
                              _SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                                ?.plotSummary
                            }
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex w="100%" justify="center">
                        <ReactPlayer
                          light={
                            _SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                              ?.thumbnailStoredAt
                          }
                          url={
                            _SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                              ?.contentLink
                          }
                          // eslint-disable-next-line no-console
                          onError={(e) => console.log(e, "error")}
                          h="350px"
                          w="600px"
                          controls
                          playing
                          config={{
                            file: {
                              attributes: {
                                controlsList: "nodownload",
                              },
                            },
                            youtube: {
                              playerVars: {
                                controls: 1,
                                modestbranding: 1,
                                loop: 1,
                              },
                            },
                          }}
                          style={{ objectFit: "cover" }}
                        />
                      </Flex>
                      <Flex justify="center">
                        <Flex gap="10px" direction="column">
                          <Text textAlign="center" style={videoBigTextStyle}>
                            Horizontal poster
                          </Text>
                          <img
                            src={
                              _SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                                ?.thumbnailStoredAt
                            }
                            alt="thumbnail"
                          />
                        </Flex>
                      </Flex>
                      <Flex justify="center">
                        <Flex gap="10px" direction="column">
                          <Text textAlign="center" style={videoBigTextStyle}>
                            Vertical poster
                          </Text>
                          <img
                            src={
                              _SellerFormReadAll?.data?.SellerFormReadAll?.[0]
                                ?.verticalThumbnailStoredAt
                            }
                            alt="vertical thumbnail"
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex mt="50px" w="80%" justify="flex-end" gap="30px">
                      <Button
                        style={sellbutton}
                        width="170px"
                        height="60px"
                        bg="red"
                        _hover={{ bg: "red" }}
                        onClick={handleDeclineClick}
                      >
                        Decline
                      </Button>
                      <Button
                        style={sellbutton}
                        width="170px"
                        height="60px"
                        bg="green"
                        _hover={{ bg: "green" }}
                        onClick={() => handleApproveClick()}
                      >
                        Approve
                      </Button>
                      {/* Approve Modal */}
                      <Modal
                        isCentered
                        isOpen={isApproveModalOpen}
                        onClose={closeApproveModal}
                      >
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>Approve Form</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            <p>Do you want to approve this form?</p>
                          </ModalBody>

                          <ModalFooter>
                            <Button
                              colorScheme="green"
                              mr={3}
                              onClick={handleApproveRequest}
                            >
                              {SellerFormApproveLoading ? <Spinner /> : "Yes"}
                            </Button>
                            <Button onClick={closeApproveModal}>No</Button>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>

                      {/* Decline Modal */}
                      <Modal
                        isCentered
                        isOpen={isDeclineModalOpen}
                        onClose={closeDeclineModal}
                      >
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>Decline Form</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            <p>Do you want to decline this form?</p>
                          </ModalBody>

                          <ModalFooter>
                            <Button
                              colorScheme="red"
                              mr={3}
                              onClick={handleDecline}
                            >
                              {SellerFormDeleteLoading ? <Spinner /> : "Yes"}
                            </Button>
                            <Button onClick={closeDeclineModal}>No</Button>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                    </Flex>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
