/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation($content: [JSON], $ContentSummaryId: ID) {
    AddMoreEpisodeByAdmin(
      content: $content
      ContentSummaryId: $ContentSummaryId
    ) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
