/* eslint-disable default-param-last */
/*
 * PACKAGES
 */
export const _initialState = {
    isLogo: false,
  };
  
  
  /*
   * OBJECTS
   */
  const _AccountReducer = (__prevState = _initialState, __action) => {
    /*
     * Switch case for handling actions on
     * account store.
     */
    switch (__action.type) {
      case "LOGO_INIT":
        // Return combined state.
        return {
          isLogo: true,
          ...__action.Logo,
        };
      case "LOGO_DEINIT":
        // Return combined state.
        return {
          ..._initialState
        };
      default:
        // Return old state.
        return __prevState;
    }
  }
  
  /*
   * EXPORTS
   */
  export default _AccountReducer;
