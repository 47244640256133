/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable max-lines-per-function */
/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";
import { connect } from "react-redux"; // NPM: React-redux library.
import { useMutation, useQuery } from "@apollo/client";
import _ from "underscore";
import {
  tabStyle,
  videoBigTextStyle,
  videoBigTextStyle1,
} from "../../../../components/Styles/index.style";

/*
 * CHAKRA IMPORTS
 */
import {
  Flex,
  Box,
  Text,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  Modal,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Select,
  Input,
  useToast,
  InputGroup,
  InputRightElement,
  Spinner,
} from "@chakra-ui/react";

/*
 * MUTATAION
 */
import DeleteAdmin from "./__mutation__/index.adminRemove.mutation";
import AdminCreate from "./__mutation__/index.userAuth.mutation";
import UpdatePassword from "./__mutation__/index.adminPasswordUpdate.mutation";
import EditAdminLevel from "./__mutation__/index.editAdminLevel.mutation";

/*
 * QUERY
 */
import AdminAccountRead from "./__query__/index.adminRead.query";

/*
 * OBJECT
 */
function Index({ account }) {
  /*
   * States
   */
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [authorityLevel, setAuthorityLevel] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [adminRead, setAdminRead] = useState();
  const [adminId, setAdminId] = useState();
  const [newPassword, setNewPassword] = useState();

  const toast = useToast();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [formData, setFormData] = useState({
    username: "",
    brandName: "",
    email: "",
    password: "",
    selectOption: "", // Default option
  });

  const [AdminCreateMutation, { loading: loadingUserAuth }] = useMutation(
    AdminCreate,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );

  const [EditAdminLevelMutation, { loading: loadingEditLevel }] = useMutation(
    EditAdminLevel,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );

  const [UpdatePasswordMutation, { loading: loadingUpdatePassword }] =
    useMutation(UpdatePassword, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const [DeleteAdminMutation, { loading: loadingDeleteAdmin }] = useMutation(
    DeleteAdmin,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );

  const { refetch: AdminAccountReadRefetch, data: AdminAccountReadQuery } =
    useQuery(AdminAccountRead, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  /*
   * STYLE
   */

  /*
   * Functions
   */
  async function HandleAdminAccountReadRefetch() {
    await AdminAccountReadRefetch();
  }

  const handleDeleteAdmin = async () => {
    try {
      const response = await DeleteAdminMutation({
        variables: {
          adminId: adminId,
        },
      });

      const data = response.data.DeleteAdmin;

      if (!_.isEmpty(data)) {
        toast({
          title: "Form Submitted",
          description: "Data saved successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setIsRemoveModalOpen(false); // Close the remove modal after confirmation
      }
    } catch (error) {
      // Show error toast
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  const handleEditLevel = async () => {
    try {
      const response = await EditAdminLevelMutation({
        variables: {
          adminId: adminId,
          level: parseInt(authorityLevel),
        },
      });

      const data = response.data.EditAdminLevel;

      if (!_.isEmpty(data)) {
        toast({
          title: "Form Submitted",
          description: "Data saved successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setIsEditModalOpen(false); // Close the remove modal after confirmation
      }
    } catch (error) {
      // Show error toast
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  const handleUpdatePassowrd = async () => {
    try {
      const response = await UpdatePasswordMutation({
        variables: {
          adminId: adminId,
          password: newPassword,
        },
      });

      const data = response.data.UpdatePassword;

      if (!_.isEmpty(data)) {
        toast({
          title: "Form Submitted",
          description: "Data saved successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setIsUpdateModalOpen(false); // Close the remove modal after confirmation
      }
    } catch (error) {
      // Show error toast
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleUserCreate = async () => {
    try {
      const response = await AdminCreateMutation({
        variables: {
          email: formData.email,
          level: parseInt(formData.selectOption),
          name: formData.username,
          password: formData.password,
          brandname: formData.brandName,
        },
      });

      const data = response.data.AdminCreate;

      if (!_.isEmpty(data)) {
        toast({
          title: "Form Submitted",
          description: "Data saved successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setFormData({
          username: "",
          brandName: "",
          email: "",
          password: "",
          selectOption: "",
        });
      }
    } catch (error) {
      // Show error toast
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  const handleRemoveClick = (id) => {
    setAdminId(id);
    setIsRemoveModalOpen(true);
  };

  const handleEditClick = (id) => {
    setAdminId(id);

    setIsEditModalOpen(true);
  };

  const handleUpdateClick = (id) => {
    setAdminId(id);

    setIsUpdateModalOpen(true);
  };

  const handleConfirmRemove = () => {
    // Implement the logic to remove the user here
    handleDeleteAdmin();
  };

  const handleConfirmEdit = () => {
    // Implement the logic to edit the user's authority level here
    handleEditLevel();
  };

  const handleConfirmUpdate = () => {
    // Implement the logic to edit the user's authority level here

    handleUpdatePassowrd();
  };

  const handleCloseRemoveModal = () => {
    setIsRemoveModalOpen(false);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleCloseUpdateModal = () => {
    setIsUpdateModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    handleUserCreate();
  };

  useEffect(() => {
    if (AdminAccountReadQuery && AdminAccountReadQuery.AdminAccountRead) {
      setAdminRead(AdminAccountReadQuery.AdminAccountRead);
    }
  }, [AdminAccountReadQuery]);

  useEffect(() => {
    HandleAdminAccountReadRefetch();
  }, [loadingDeleteAdmin, loadingUserAuth, loadingEditLevel, loadingUpdatePassword,]);

  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
        w="100%"
        minHeight="100vh"
      >
        <Flex w="100%" h="100px"></Flex>

        <Flex mt="1rem">
          <Flex overflow="scroll" direction="column" w="100%">
            <Tabs width="100%">
              <TabList
                gap="3rem"
                padding="0px"
                style={tabStyle}
                borderColor="none"
                borderBottom="1px solid #505050"
              >
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Assign authority
                </Tab>
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Authorized people
                </Tab>
              </TabList>
              <TabIndicator
                mt="-2px"
                height="3px"
                bg="#FFFFFF"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel w="100%" padding="0px">
                  <Flex direction="column" pt="50px" pb="50px" align="center">
                    <form onSubmit={handleSubmit}>
                      <Flex
                        borderRadius="20px"
                        w="100%"
                        h="100%"
                        border="2px solid purple"
                        p="40px"
                        gap="50px"
                        justify="space-evenly"
                        direction="column"
                      >
                        <FormControl>
                          <FormLabel
                            style={videoBigTextStyle}
                            htmlFor="username"
                          >
                            Username:
                          </FormLabel>
                          <Input
                            w="500px"
                            h="50px"
                            borderColor="rgba(116, 116, 116, 1)"
                            placeholder="User name"
                            color="white"
                            type="text"
                            id="username"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            required
                          />
                        </FormControl>

                        <FormControl>
                          <FormLabel style={videoBigTextStyle} htmlFor="email">
                            Email:
                          </FormLabel>
                          <Input
                            w="500px"
                            h="50px"
                            borderColor="rgba(116, 116, 116, 1)"
                            placeholder="Email"
                            color="white"
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </FormControl>

                        <FormControl>
                          <FormLabel style={videoBigTextStyle} htmlFor="email">
                            Password:
                          </FormLabel>
                          <InputGroup>
                            <Input
                              w="500px"
                              h="50px"
                              borderColor="rgba(116, 116, 116, 1)"
                              placeholder="Password"
                              color="white"
                              type={showPassword ? "text" : "password"}
                              id="password"
                              name="password"
                              value={formData.password}
                              onChange={handleChange}
                              required
                            />
                            <InputRightElement h="100%" width="5rem">
                              <Button
                                color="black"
                                bg="blue.300"
                                onClick={togglePasswordVisibility}
                              >
                                {showPassword ? "Hide" : "Show"}
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                        </FormControl>

                        <FormControl>
                          <FormLabel style={videoBigTextStyle} htmlFor="select">
                            Select level:
                          </FormLabel>
                          <Select
                            w="500px"
                            h="50px"
                            borderColor="rgba(116, 116, 116, 1)"
                            placeholder="Choose level"
                            color="white"
                            id="select"
                            name="selectOption"
                            value={formData.selectOption}
                            onChange={handleChange}
                            required
                          >
                            <option value="0">Level 0</option>
                            <option value="1">Level 1</option>
                            <option value="2">Level 2</option>
                            <option value="3">Level 3</option>
                            <option value="4">Level 4</option>
                          </Select>
                        </FormControl>

                        <FormControl>
                          <FormLabel
                            style={videoBigTextStyle}
                            htmlFor="brandName"
                          >
                            Attach Brand:
                          </FormLabel>
                          <Input
                            w="500px"
                            h="50px"
                            borderColor="rgba(116, 116, 116, 1)"
                            placeholder="Brand name"
                            color="white"
                            type="text"
                            id="brandName"
                            name="brandName"
                            value={formData.brandName}
                            onChange={handleChange}
                          />
                        </FormControl>

                        <Button type="submit" mt={4} colorScheme="teal">
                          {loadingUserAuth ? <Spinner /> : "Create"}
                        </Button>
                      </Flex>
                    </form>
                  </Flex>
                </TabPanel>
                <TabPanel w="100%" padding="0px">
                  <Flex direction="column" pt="50px" pb="50px" align="center">
                    <Flex
                      borderRadius="20px"
                      w="100%"
                      h="100%"
                      border="2px solid purple"
                      p="40px"
                      gap="50px"
                      justify="space-evenly"
                      direction="column"
                    >
                      {adminRead?.map((data, index) => (
                        <Flex gap="40px" align="center" key={index} w="100%">
                          <Flex
                            w="40px"
                            h="40px"
                            borderRadius="50%"
                            bg="white"
                            justify="center"
                            align="center"
                            fontWeight="600"
                          >
                            {index + 1}
                          </Flex>
                          <Flex gap="20px" w="30%" direction="column">
                            <Text style={videoBigTextStyle}>
                              Name of user :{" "}
                              <span style={videoBigTextStyle1}>
                                {data?.name}
                              </span>
                            </Text>
                            <Text style={videoBigTextStyle}>
                              Email :{" "}
                              <span style={videoBigTextStyle1}>
                                {data?.email}
                              </span>
                            </Text>
                          </Flex>

                          <Flex gap="20px" w="30%" direction="column">
                            <Text style={videoBigTextStyle}>
                              Attached Brand :{" "}
                              <span style={videoBigTextStyle1}>
                                {data?.brandname}
                              </span>
                            </Text>
                            <Text style={videoBigTextStyle}>
                              Authority level :{" "}
                              <span style={videoBigTextStyle1}>
                                {data?.level === 5 ? "Super" : data?.level}
                              </span>
                            </Text>
                          </Flex>

                          <Button
                            isDisabled={data?.level === 5}
                            colorScheme="red"
                            onClick={() => handleRemoveClick(data?.id)}
                          >
                            Remove
                          </Button>
                          <Button
                            isDisabled={data?.level === 5}
                            colorScheme="blue"
                            onClick={() => handleEditClick(data?.id)}
                          >
                            Edit
                          </Button>
                          <Button
                            colorScheme="blue"
                            onClick={() => handleUpdateClick(data?.id)}
                          >
                            Change password
                          </Button>
                          {/* Remove Modal */}
                          <Modal
                            isCentered
                            isOpen={isRemoveModalOpen}
                            onClose={handleCloseRemoveModal}
                          >
                            <ModalContent>
                              <ModalHeader>Confirm Removal</ModalHeader>
                              <ModalBody>
                                Are you sure you want to remove this user?
                              </ModalBody>
                              <ModalFooter gap="20px">
                                <Button
                                  colorScheme="red"
                                  onClick={handleConfirmRemove}
                                >
                                  {loadingDeleteAdmin ? <Spinner /> : "Yes"}
                                </Button>
                                <Button onClick={handleCloseRemoveModal}>
                                  No
                                </Button>
                              </ModalFooter>
                            </ModalContent>
                          </Modal>

                          {/* Edit Modal */}
                          <Modal
                            isCentered
                            isOpen={isEditModalOpen}
                            onClose={handleCloseEditModal}
                          >
                            <ModalContent>
                              <ModalHeader>Edit Authority Level</ModalHeader>
                              <ModalBody>
                                Are you sure you want to edit this user's
                                authority level?
                                <Select
                                  value={authorityLevel}
                                  onChange={(e) =>
                                    setAuthorityLevel(e.target.value)
                                  }
                                  placeholder="Select level"
                                >
                                  {/* You can add options for authority levels here */}
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  {/* Add more options as needed */}
                                </Select>
                              </ModalBody>
                              <ModalFooter gap="20px">
                                <Button
                                  colorScheme="blue"
                                  onClick={handleConfirmEdit}
                                >
                                  {loadingEditLevel ? <Spinner /> : "Yes"}
                                </Button>
                                <Button onClick={handleCloseEditModal}>
                                  No
                                </Button>
                              </ModalFooter>
                            </ModalContent>
                          </Modal>

                          {/* Update Modal */}
                          <Modal
                            isCentered
                            isOpen={isUpdateModalOpen}
                            onClose={handleCloseUpdateModal}
                          >
                            <ModalContent>
                              <ModalHeader>Edit Password</ModalHeader>
                              <ModalBody>
                                Are you sure you want to edit this user's
                                password
                                <Input
                                  type="password" // Set the input type to "password" for password masking
                                  placeholder="Enter New Password" // Placeholder text for the input field
                                  value={newPassword} // Assuming you have a state variable newPassword
                                  onChange={(e) =>
                                    setNewPassword(e.target.value)
                                  } // Update the newPassword state on input change
                                />
                              </ModalBody>
                              <ModalFooter gap="20px">
                                <Button
                                  colorScheme="blue"
                                  onClick={handleConfirmUpdate}
                                >
                                  {loadingUpdatePassword ? <Spinner /> : "Yes"}
                                </Button>
                                <Button onClick={handleCloseUpdateModal}>
                                  No
                                </Button>
                              </ModalFooter>
                            </ModalContent>
                          </Modal>
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
