/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React from "react"; // NPM: React package.

import { Route, BrowserRouter as Router, Switch } from "react-router-dom"; // NPM: React Router Dom for routing.
import { connect } from "react-redux"; // NPM: React Redux for state managment.
import { Toaster } from "react-hot-toast"; // NPM: React Hot Toast for toast messages.

/*
 * CONTEXT
 */

/*
 * COMPONENTS
 */
import SignIn from "./Views/Auth/signIn/SignInDefault.jsx";
import LandingPage from "./Views/User/website/LandingPage/index";
import CreatorPage from "./Views/User/website/CreatorPage/index";
import ApprovalRequestPage from "./Views/User/website/ApprovalRequestPage/index";
import ApprovedVideos from "./Views/User/website/ApprovedVideos/index";
import ManageAds from "./Views/User/website/ManageAds/index";
import VideoPage from "./components/VideoPage/VideoPage.js";
import Ads from "./components/Ads/index.js";
import AdminActivity from "./components/AdminActivity/index.js";
import ApprovalForm from "./components/ApprovalForm/index.js";
import ManageHomePage from "./Views/User/website/ManageHomePage/index";
import ManageAuthorization from "./Views/User/website/ManageAuth/index.js";
import TotalVideos from "./Views/User/website/TotalVideos/index";
import Blogs from "./Views/User/website/Blogs/index";
import BlogPage from "./components/BlogPage/index.js";
import Context from "./Context/index.js";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min.js";
import TotalUsers from "Views/User/website/TotalUsers/index.js";
import DevTokenApproval from "./Views/User/website/DevTokenApproval/index.js";
import PodCast from "./Views/User/website/PodCast/index.js";
import BuyandSell from "./Views/User/website/BuyandSell/index.js";
import Buyer from "./components/Buyer/index.js";
import BuyerCart from "./components/BuyerCart/index.js";
import Seller from "./components/Seller/index.js";
import UserPage from "./components/UserPage/index.js";
import SellerFormApproval from "components/SellerFormApproval/index.js";
import SubscriptionActivity from "./Views/User/website/SubscriptionActivity/index.js";
import CustomerAssociate from "./Views/User/website/CustomerAssociate/index.js";

/*
 * OJBECT
 */
const Index = ({ account }) => {
  const [context, setContext] = React.useState({});

  return (
    <Context.Provider value={{ context, setContext }}>
      <Toaster />
      <Router>
        <Switch>
          <Route exact path="/signin" component={SignIn} />
          {account.isUserLoggedIn ? (
            <>
              <Route exact path="/" component={LandingPage} />
              <Route exact path="/creators" component={CreatorPage} />
              <Route exact path="/total-user" component={TotalUsers} />
              <Route
                exact
                path="/request-page"
                component={ApprovalRequestPage}
              />
              <Route exact path="/approved-videos" component={ApprovedVideos} />
              <Route exact path="/manage-ads" component={ManageAds} />
              <Route
                exact
                path="/dev-token-approval"
                component={DevTokenApproval}
              />
              <Route
                exact
                path="/manage-home-page"
                component={ManageHomePage}
              />
              <Route exact path="/video-page" component={VideoPage} />
              <Route exact path="/buy-sell" component={BuyandSell} />
              <Route exact path="/buyers" component={Buyer} />
              <Route exact path="/seller" component={Seller} />
              <Route exact path="/buyer-cart" component={BuyerCart} />
              <Route exact path="/pod-cast" component={PodCast} />
              <Route exact path="/blogs" component={Blogs} />
              <Route exact path="/blog-page" component={BlogPage} />
              <Route exact path="/approval-form" component={ApprovalForm} />
              <Route
                exact
                path="/customer-associate"
                component={CustomerAssociate}
              />
              <Route exact path="/user-page" component={UserPage} />
              <Route
                exact
                path="/seller-form-approval"
                component={SellerFormApproval}
              />
              <Route exact path="/total-videos" component={TotalVideos} />
              <Route exact path="/ads" component={Ads} />
              <Route exact path="/admin-activity" component={AdminActivity} />
              <Route
                exact
                path="/subscription-activity"
                component={SubscriptionActivity}
              />
              <Route
                exact
                path="/manage-authorization"
                component={ManageAuthorization}
              />{" "}
            </>
          ) : (
            <Redirect exact to="/signin" />
          )}
        </Switch>
      </Router>
    </Context.Provider>
  );
};

/*
 * REDUX
 */
const _MapStateToProps = (__state) => ({ account: __state.Account });
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

/*
 * EXPORT
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
