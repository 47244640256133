/* eslint-disable init-declarations */
/* eslint-disable no-bitwise */
/* eslint-disable no-unused-vars */
/*
 * IMPORT
 */
import React, { useState } from "react";
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";
import { connect } from "react-redux"; // NPM: React-redux library.
import { useMutation, useQuery } from "@apollo/client";
import Loader from "../LandingPage/Loader";
import _ from "underscore";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import PropTypes from "prop-types"; // Npm: react.js library.

import {
  tabStyle,
  videoSmallTextStyle,
  videoBigTextStyle,
} from "../../../../components/Styles/index.style";

/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  Image,
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  Button,
  useToast,
  Spinner,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";

/*
 * MUTATION
 */
import SubscriptionPlanUpdate from "./__mutation__/index.updateSubscription.mutation";
/*
 * QUERY
 */
import PaymentLogs from "./__query__/index.paymentLogs.query";
import SubscriptionPlanRead from "./__query__/index.subscriptionPlanRead.query";
import SubscribedUser from "./__query__/index.subscribersRead.query"

function Index({ account }) {
  /*
   * States
   */
  const toast = useToast();
  const [amountEdit, setAmountEdit] = useState();
  const [planNameEdit, setPlanNameEdit] = useState("");
  const [selectedPlanID, setSelectedPlanID] = useState("");
  const [discount, setDiscount] = useState("");
  const [selectedPlan, setSelectedPlan] = useState();
  const _PaymentLogs = useQuery(PaymentLogs, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  const _SubscriptionPlanRead = useQuery(SubscriptionPlanRead, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  const _SubscribedUser = useQuery(SubscribedUser , {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  })

  const [SubscriptionPlanUpdateMutation, { loading: loadingSubscriptionPlanUpdate },] = useMutation(SubscriptionPlanUpdate, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });


  /*
   * STYLE
   */

  /*
   * FUNCTIONS
   */
  const calculateDaysDifference = (dateString) => {
    const currentDate = new Date();
    const providedDate = new Date(dateString);
    const timeDifference = currentDate - providedDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  };
  const formatDate = (dateString) => {
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const date = new Date(dateString);

    return date.toLocaleString("en-US", options);
  };
  const exportToExcel = (data) => {
    const ws = XLSX.utils.json_to_sheet(
      data.map((item, index) => ({
        "S.No.": index + 1,
        Amount: `Rs ${item.amount}`,
        "Order Id": item.orderId,
        "Reciept Id": item.recieptId,
        Status: item.status,
        "Created on": formatDate(item.createdAt),
      })),
      {
        header: [
          "S.No.",
          "Amount",
          "Order Id",
          "Reciept Id",
          "Status",
          "Created on",
        ],
      }
    );

    ws["!cols"] = [{ width: 5 }, { width: 25 }, { width: 20 }];
    ws["!rows"] = [{}, { hpx: 20 }];
    ws.A1.s = {
      font: { bold: true },
      fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFFF00" } },
    };
    ws.B1.s = {
      font: { bold: true },
      fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFFF00" } },
    };
    ws.C1.s = {
      font: { bold: true },
      fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFFF00" } },
    };
    ws.D1.s = {
      font: { bold: true },
      fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFFF00" } },
    };
    ws.E1.s = {
      font: { bold: true },
      fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFFF00" } },
    };

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);

      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;

      return buf;
    };

    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      "data.xlsx"
    );
  };
  const handleSubscriptionPlanUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await SubscriptionPlanUpdateMutation({
        variables: {
          subscriptionPlanId: selectedPlanID,
          amount: parseInt(amountEdit),
          subscriptionName: planNameEdit,
          discount: parseInt(discount),
        },
      });

      const data = response.data.SubscriptionPlanUpdate;

      if (!_.isEmpty(data) && data.status === "SUBSCRIPTION_PLAN_UPDATED") {
        toast({
          title: "Form Submitted",
          description: "Data saved successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        window.location.reload();
      }
    } catch (error) {
      // Show error toast
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };
  const handleOnChange = (e) => {
    setSelectedPlan(e.target.value);
    setPlanNameEdit(e.target.value);
    const FilteredList =
      _SubscriptionPlanRead?.data?.SubscriptionPlanRead?.filter(
        (name) => name.subscriptionName === e.target.value
      );
    setAmountEdit(FilteredList?.[0]?.amount);
    setDiscount(FilteredList?.[0]?.discount);
    // eslint-disable-next-line no-console
    console.log(FilteredList?.[0]?.discount);
    setSelectedPlanID(FilteredList?.[0]?.id);
  };


  /*
   * USEEFFECT
   */

  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
        w="100%"
        minHeight="100vh"
      >
        <Flex w="100%" h="100px"></Flex>

        <Flex mt="1rem">
          <Flex overflow="scroll" direction="column" w="100%">
            <Tabs width="100%">
              <TabList
                gap="3rem"
                padding="0px"
                style={tabStyle}
                borderColor="none"
                borderBottom="1px solid #505050"
              >
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Edit Subscription Plans
                </Tab>
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Subscription Logs
                </Tab>
              </TabList>
              <TabIndicator
                mt="-2px"
                height="3px"
                bg="#FFFFFF"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel w="100%" padding="0px">
                  <Flex direction="column" pt="50px" pb="50px" align="center">
                    <form onSubmit={handleSubscriptionPlanUpdate}>
                      <Flex
                        borderRadius="20px"
                        w="100%"
                        h="100%"
                        border="2px solid purple"
                        p="40px"
                        gap="50px"
                        justify="space-evenly"
                        direction="column"
                      >
                        <FormControl>
                          <FormLabel style={videoBigTextStyle} htmlFor="select">
                            Select level:
                          </FormLabel>
                          <Select
                            w="500px"
                            h="50px"
                            borderColor="rgba(116, 116, 116, 1)"
                            placeholder="Choose level"
                            color="white"
                            id="select"
                            name="selectOption"
                            value={selectedPlan}
                            onChange={handleOnChange}
                            required
                          >
                            {_SubscriptionPlanRead?.data?.SubscriptionPlanRead?.map(
                              (item, index) => (
                                <option
                                  key={item.id}
                                  value={item.subscriptionName}
                                >
                                  {item.subscriptionName}
                                </option>
                              )
                            )}
                          </Select>
                        </FormControl>

                        <FormControl>
                          <FormLabel style={videoBigTextStyle}>
                            Amount:
                          </FormLabel>
                          <Input
                            w="500px"
                            h="50px"
                            borderColor="rgba(116, 116, 116, 1)"
                            placeholder="Amount"
                            color="white"
                            type="number"
                            isDisabled={!selectedPlan}
                            value={amountEdit}
                            onChange={(e) => setAmountEdit(e.target.value)}
                            required
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel style={videoBigTextStyle}>
                            Discount:
                          </FormLabel>
                          <Input
                            w="500px"
                            h="50px"
                            borderColor="rgba(116, 116, 116, 1)"
                            placeholder="Discount In Percentage"
                            color="white"
                            type="number"
                            isDisabled={!selectedPlan}
                            value={discount}
                            onChange={(e) => setDiscount(e.target.value)}
                            required
                          />
                        </FormControl>

                        <FormControl>
                          <FormLabel style={videoBigTextStyle}>
                            Subscription Plan Name:
                          </FormLabel>
                          <Input
                            w="500px"
                            h="50px"
                            borderColor="rgba(116, 116, 116, 1)"
                            placeholder="Plan Name"
                            color="white"
                            type="text"
                            isDisabled={!selectedPlan}
                            value={planNameEdit}
                            onChange={(e) => setPlanNameEdit(e.target.value)}
                            required
                          />
                        </FormControl>

                        <Button type="submit" mt={4} colorScheme="teal">
                          {loadingSubscriptionPlanUpdate ? (
                            <Spinner />
                          ) : (
                            "Update"
                          )}
                        </Button>
                      </Flex>
                    </form>
                  </Flex>
                </TabPanel>
                <TabPanel w="100%" padding="0px">
                  {_PaymentLogs?.loading ? (
                    <Flex align="center" justify="center" w="100%">
                      <Loader />
                    </Flex>
                  ) : (
                    <Flex
                      overflowY="auto"
                      maxHeight="700px"
                      p="35px 24px"
                      direction="column"
                      gap="1rem"
                    >
                      {_PaymentLogs?.data?.PaymentLogs?.map((data, index) => (
                        <Flex
                          p="20px"
                          key={index}
                          borderRadius="10px"
                          bg="#000000"
                          w="100%"
                          gap="1rem"
                          align="center"
                        >
                          <Flex
                            border="2px solid white"
                            w="35px"
                            h="35px"
                            borderRadius="50%"
                            color="white"
                            justify="center"
                            align="center"
                            fontWeight="700"
                          >
                            {index + 1}
                          </Flex>
                          <Flex w="100%" justify="space-between">
                            <Text style={videoBigTextStyle}>
                              User name -{" "}
                              <span
                                style={{ ...videoBigTextStyle, color: "grey" }}
                              >
                                {data?.fullName}
                              </span>
                            </Text>
                            <Text style={videoBigTextStyle}>
                              Email -{" "}
                              <span
                                style={{ ...videoBigTextStyle, color: "grey" }}
                              >
                                {data?.email}
                              </span>
                            </Text>
                            <Text style={videoBigTextStyle}>
                              History -{" "}
                              <span
                                style={{ ...videoBigTextStyle, color: "grey" }}
                              >
                                {data?.Payment?.length}
                              </span>
                            </Text>

                            <Button
                              bg="blue"
                              _hover={{ bg: "blue.500" }}
                              color="white"
                              onClick={() => {
                                exportToExcel(data?.Payment);
                              }}
                            >
                              Export logs
                            </Button>
                          </Flex>
                        </Flex>
                      ))}
                    </Flex>
                  )}
                </TabPanel>
                <TabPanel w="100%" padding="0px">
                  {_PaymentLogs?.loading ? (
                    <Flex align="center" justify="center" w="100%">
                      <Loader />
                    </Flex>
                  ) : (
                    <Flex
                      overflowY="auto"
                      maxHeight="700px"
                      p="35px 24px"
                      direction="column"
                      gap="1rem"
                    >
                      {_PaymentLogs?.data?.PaymentLogs?.map((data, index) => (
                        <Flex
                          p="20px"
                          key={index}
                          borderRadius="10px"
                          bg="#000000"
                          w="100%"
                          gap="1rem"
                          align="center"
                        >
                          <Flex
                            border="2px solid white"
                            w="35px"
                            h="35px"
                            borderRadius="50%"
                            color="white"
                            justify="center"
                            align="center"
                            fontWeight="700"
                          >
                            {index + 1}
                          </Flex>
                          <Flex w="100%" justify="space-between">
                            <Text style={videoBigTextStyle}>
                              User name -{" "}
                              <span
                                style={{ ...videoBigTextStyle, color: "grey" }}
                              >
                                {data?.fullName}
                              </span>
                            </Text>
                            <Text style={videoBigTextStyle}>
                              Email -{" "}
                              <span
                                style={{ ...videoBigTextStyle, color: "grey" }}
                              >
                                {data?.email}
                              </span>
                            </Text>
                            <Text style={videoBigTextStyle}>
                              History -{" "}
                              <span
                                style={{ ...videoBigTextStyle, color: "grey" }}
                              >
                                {data?.Payment?.length}
                              </span>
                            </Text>

                            <Button
                              bg="blue"
                              _hover={{ bg: "blue.500" }}
                              color="white"
                              onClick={() => {
                                exportToExcel(data?.Payment);
                              }}
                            >
                              Export logs
                            </Button>
                          </Flex>
                        </Flex>
                      ))}
                    </Flex>
                  )}
                </TabPanel>
                
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}

/*
 * PROPTYPES
 */
Index.propTypes = {
  account: PropTypes.object,
};

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
