/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable init-declarations */
/* eslint-disable no-bitwise */
/* eslint-disable array-element-newline */
/* eslint-disable multiline-comment-style */
/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";
import { connect } from "react-redux"; // NPM: React-redux library.
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import Loader from "../LandingPage/Loader";
import _ from "underscore";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import {
  tabStyle,
  videoSmallTextStyle,
  videoBigTextStyle,
} from "../../../../components/Styles/index.style";
import DatePicker from "react-datepicker";
import { parseISO, addDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  Image,
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  Button,
  useToast,
  Spinner,
} from "@chakra-ui/react";

/*
 * MUTATION
 */
import ApproveDeveloperAccount from "../__mutation__/index.approveDevTokenRequest.mutation";
import DeclineDeveloperAccount from "../__mutation__/index.declineDevTokenRequest.mutation";

/*
 * QUERY
 */
import DevInfoForAdmin from "../__query__/index.devTokenRequest.query";
import LogsRead from "./__query__/index.logsRead.query";
import SpecificLogsRead from "./__query__/index.specificLogRead.query";
import VideoLogsRead from "./__query__/index.videoLogs.query";
import VideoLogsCount from "./__query__/index.videoCount.query";

function Index({ account }) {
  /*
   * States
   */
  const toast = useToast();
  const [pendingRequest, setPendingRequest] = useState();
  const [companyNameCheck, setCompanyNameCheck] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);


  const _LogsRead = useQuery(LogsRead, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  const [SpecificLogsReadQuery, { loading: loadingSpecificLogsRead }] =
    useLazyQuery(SpecificLogsRead, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const [VideoLogsReadQuery, { loading: loadingVideoLogsRead }] = useLazyQuery(
    VideoLogsRead,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );

  const [VideoLogsCountQuery, { loading: loadingVideoLogsCount }] =
    useLazyQuery(VideoLogsCount, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const {
    refetch: DevInfoForAdminRefetch,
    data: DevInfoForAdminQuery,
    loading: DevInfoForAdminLoading,
  } = useQuery(DevInfoForAdmin, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  const [
    ApproveDeveloperAccountMutation,
    { loading: loadingApproveDeveloperAccount },
  ] = useMutation(ApproveDeveloperAccount, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  const [
    DeclineDeveloperAccountMutation,
    { loading: loadingDeclineDeveloperAccount },
  ] = useMutation(DeclineDeveloperAccount, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  /*
   * STYLE
   */

  /*
   * FUNCTIONS
   */

  const calculateDaysDifference = (dateString) => {
    const currentDate = new Date();
    const providedDate = new Date(dateString);
    const timeDifference = currentDate - providedDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  };

  async function HandleDevInfoForAdminRefetch() {
    await DevInfoForAdminRefetch();
  }

  const handleApproveDevTokenRequest = async (id) => {
    try {
      const response = await ApproveDeveloperAccountMutation({
        variables: {
          developerAccountId: id,
        },
      });

      const data = response.data.ApproveDeveloperAccount;

      if (!_.isEmpty(data)) {
        toast({
          title: "Form Submitted",
          description: "Data saved successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      // Show error toast
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };
  const handleDeclineDevTokenRequest = async (id) => {
    try {
      const response = await DeclineDeveloperAccountMutation({
        variables: {
          developerAccountId: id,
        },
      });

      const data = response.data.DeclineDeveloperAccount;

      if (!_.isEmpty(data)) {
        toast({
          title: "Form Submitted",
          description: "Data saved successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      // Show error toast
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };
  const formatDate = (dateString) => {
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const date = new Date(dateString);

    return date.toLocaleString("en-US", options);
  };
  const exportToExcel = (data) => {
    const ws = XLSX.utils.json_to_sheet(
      data.map((item, index) => ({
        "S.No.": index + 1,
        "Type of content": item.queryName,
        "Used on": formatDate(item.createdAt),
      })),
      { header: ["S.No.", "Type of content", "Used on"] }
    );

    ws["!cols"] = [{ width: 5 }, { width: 25 }, { width: 20 }];
    ws["!rows"] = [{}, { hpx: 20 }];
    ws.A1.s = {
      font: { bold: true },
      fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFFF00" } },
    };
    ws.B1.s = {
      font: { bold: true },
      fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFFF00" } },
    };
    ws.C1.s = {
      font: { bold: true },
      fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFFF00" } },
    };

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);

      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;

      return buf;
    };

    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      "data.xlsx"
    );
  };
  const exportToExcelVideoLogs = (data) => {
    const ws = XLSX.utils.json_to_sheet(
      data.map((item, index) => ({
        "S.No.": index + 1,
        "Video Title": item?.ContentDetail?.ContentSummary?.contentTitle,
        "Used on": formatDate(item.createdAt),
      })),
      { header: ["S.No.", "Video Title", "Used on"] }
    );

    ws["!cols"] = [{ width: 5 }, { width: 25 }, { width: 20 }];
    ws["!rows"] = [{}, { hpx: 20 }];
    ws.A1.s = {
      font: { bold: true },
      fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFFF00" } },
    };
    ws.B1.s = {
      font: { bold: true },
      fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFFF00" } },
    };
    ws.C1.s = {
      font: { bold: true },
      fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFFF00" } },
    };

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);

      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;

      return buf;
    };

    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      "data.xlsx"
    );
  };
  const exportToExcelVideoCount = (data) => {
    const ws = XLSX.utils.json_to_sheet(
      data.map((item, index) => ({
        "S.No.": index + 1,
        "Video Title": item.contentTitle,
        "Total Hits": item.count,
      })),
      { header: ["S.No.", "Video Title", "Total Hits"] }
    );

    ws["!cols"] = [{ width: 5 }, { width: 25 }, { width: 20 }];
    ws["!rows"] = [{}, { hpx: 20 }];
    ws.A1.s = {
      font: { bold: true },
      fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFFF00" } },
    };
    ws.B1.s = {
      font: { bold: true },
      fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFFF00" } },
    };
    ws.C1.s = {
      font: { bold: true },
      fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFFF00" } },
    };

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);

      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;

      return buf;
    };

    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      "data.xlsx"
    );
  };
  const handleLogsExport = async (name) => {
    try {
      const response = await SpecificLogsReadQuery({
        variables: {
          companyName: name,
        },
      });

      const data = response.data.SpecificLogsRead;

      if (!_.isEmpty(data)) {
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;
        if (end) {
          end.setDate(end.getDate() + 1);
        }
        const filteredData = data.filter((item) => {
          const createdAt = parseISO(item.createdAt);

          return (!start || createdAt >= start) && (!end || createdAt <= end);
        });
        exportToExcel(filteredData);
      }
    } catch (error) {
      // Show error toast
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };
  const handleVideoLogsExport = async (name) => {
    try {
      const response = await VideoLogsReadQuery({
        variables: {
          companyName: name,
        },
      });

      const data = response.data.VideoLogsRead;

      if (!_.isEmpty(data)) {
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;
        if (end) {
          end.setDate(end.getDate() + 1);
        }
        const filteredData = data.filter((item) => {
          const createdAt = parseISO(item.createdAt);

          return (!start || createdAt >= start) && (!end || createdAt <= end);
        });
        exportToExcelVideoLogs(filteredData);
      }
    } catch (error) {
      // Show error toast
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };
  const handleVideoCountExport = async (name) => {
    try {
      const response = await VideoLogsCountQuery({
        variables: {
          companyName: name,
        },
      });

      const data = response.data.VideoLogsCount;

      if (!_.isEmpty(data) && data?.VideoLogsCount) {
        exportToExcelVideoCount(data?.VideoLogsCount);
      }
    } catch (error) {
      // Show error toast
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  /*
   * USEEFFECT
   */
  useEffect(() => {
    HandleDevInfoForAdminRefetch();
  }, [loadingApproveDeveloperAccount, loadingDeclineDeveloperAccount]);

  useEffect(() => {
    if (DevInfoForAdminQuery && DevInfoForAdminQuery.DevInfoForAdmin) {
      setPendingRequest(DevInfoForAdminQuery?.DevInfoForAdmin);
    }
  }, [DevInfoForAdminQuery]);

  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
        w="100%"
        minHeight="100vh"
      >
        <Flex w="100%" h="100px"></Flex>

        <Flex mt="1rem">
          <Flex overflow="scroll" direction="column" w="100%">
            <Tabs width="100%">
              <TabList
                gap="3rem"
                padding="0px"
                style={tabStyle}
                borderColor="none"
                borderBottom="1px solid #505050"
              >
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Request List
                </Tab>
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Logs
                </Tab>
              </TabList>
              <TabIndicator
                mt="-2px"
                height="3px"
                bg="#FFFFFF"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel w="100%" padding="0px">
                  {DevInfoForAdminLoading ? (
                    <Flex align="center" justify="center" w="100%">
                      <Loader />
                    </Flex>
                  ) : (
                    <Flex
                      overflowY="auto"
                      maxHeight="700px"
                      p="35px 24px"
                      direction="column"
                      gap="1rem"
                    >
                      {pendingRequest?.map((data, index) => (
                        <Flex
                          p="20px"
                          key={data.id}
                          borderRadius="10px"
                          bg="#000000"
                          w="100%"
                          gap="1rem"
                          align="center"
                        >
                          <Flex
                            border="2px solid white"
                            w="35px"
                            h="35px"
                            borderRadius="50%"
                            color="white"
                            justify="center"
                            align="center"
                            fontWeight="700"
                          >
                            {index + 1}
                          </Flex>
                          <Flex gap="20px" direction="column">
                            <Text style={videoBigTextStyle}>
                              Company name -{" "}
                              <span style={videoSmallTextStyle}>
                                {data?.companyName}
                              </span>
                            </Text>

                            <Text style={videoBigTextStyle}>
                              Company email -{" "}
                              <span style={videoSmallTextStyle}>
                                {data?.companyEmail}
                              </span>
                            </Text>

                            <Text w="700px" style={videoBigTextStyle}>
                              Reason for token -{" "}
                              <span style={videoSmallTextStyle}>
                                {data?.reason}
                              </span>
                            </Text>
                          </Flex>
                          <Flex ml="10rem" gap="20px" direction="column">
                            <Text style={videoBigTextStyle}>
                              Phone number -{" "}
                              <span style={videoSmallTextStyle}>
                                {data?.phone}
                              </span>
                            </Text>

                            <Text style={videoBigTextStyle}>
                              Requested -{" "}
                              <span style={videoSmallTextStyle}>
                                {calculateDaysDifference(data?.createdAt)} days
                                ago
                              </span>
                            </Text>
                          </Flex>

                          <Flex gap="10px" ml="10rem">
                            {data?.isAccepted === "PENDING" ? (
                              <>
                                <Button
                                  bg="red"
                                  color="white"
                                  onClick={() =>
                                    handleDeclineDevTokenRequest(data?.id)
                                  }
                                >
                                  {loadingDeclineDeveloperAccount ? (
                                    <Spinner />
                                  ) : (
                                    "Decline"
                                  )}
                                </Button>
                                <Button
                                  bg="green"
                                  color="white"
                                  onClick={() =>
                                    handleApproveDevTokenRequest(data?.id)
                                  }
                                >
                                  {loadingApproveDeveloperAccount ? (
                                    <Spinner />
                                  ) : (
                                    "Approve"
                                  )}
                                </Button>
                              </>
                            ) : (
                              <Text
                                style={videoBigTextStyle}
                                color={
                                  data?.isAccepted === "DECLINED"
                                    ? "red !important"
                                    : "green !important"
                                }
                              >
                                {data?.isAccepted}
                              </Text>
                            )}
                          </Flex>
                        </Flex>
                      ))}
                    </Flex>
                  )}
                </TabPanel>
                <TabPanel w="100%" padding="0px">
                  <Flex p="25px" gap="20px" w="50%">
                    <Flex align="center" gap="10px">
                      <Text fontSize="20px" color="white">
                        Start Date :-
                      </Text>
                      <Box
                        as={DatePicker}
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select Start Date"
                        css={{
                          width: "200px",
                          padding: "10px",
                          fontSize: "16px",
                          color: "white",
                          backgroundColor: "black",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          "&:focus": {
                            borderColor: "#3182ce",
                            boxShadow: "0 0 5px rgba(49, 130, 206, 0.5)",
                            outline: "none",
                          },
                        }}
                      />
                    </Flex>
                    <Flex align="center" gap="10px">
                      <Text fontSize="20px" color="white">
                        End Date :-
                      </Text>
                      <Box
                        as={DatePicker}
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select End Date"
                        css={{
                          width: "200px",
                          padding: "10px",
                          fontSize: "16px",
                          color: "white",
                          backgroundColor: "black",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          "&:focus": {
                            borderColor: "#3182ce",
                            boxShadow: "0 0 5px rgba(49, 130, 206, 0.5)",
                            outline: "none",
                          },
                        }}
                      />
                    </Flex>
                  </Flex>
                  {_LogsRead?.loading ? (
                    <Flex align="center" justify="center" w="100%">
                      <Loader />
                    </Flex>
                  ) : (
                    <Flex
                      overflowY="auto"
                      maxHeight="700px"
                      p="35px 24px"
                      direction="column"
                      gap="1rem"
                    >
                      {_LogsRead?.data?.LogsRead?.map((data, index) => (
                        <Flex
                          p="20px"
                          key={index}
                          borderRadius="10px"
                          bg="#000000"
                          w="100%"
                          gap="1rem"
                          align="center"
                        >
                          <Flex
                            border="2px solid white"
                            w="35px"
                            h="35px"
                            borderRadius="50%"
                            color="white"
                            justify="center"
                            align="center"
                            fontWeight="700"
                          >
                            {index + 1}
                          </Flex>
                          <Flex w="100%" justify="space-between">
                            <Text style={videoBigTextStyle}>
                              Company name -{" "}
                              <span style={videoSmallTextStyle}>
                                {data?.companyName}
                              </span>
                            </Text>
                            <Text style={videoBigTextStyle}>
                              Movies total hit count -{" "}
                              <span style={videoSmallTextStyle}>
                                {data?.countMovie}
                              </span>
                            </Text>
                            <Text style={videoBigTextStyle}>
                              Web Series total hit count -{" "}
                              <span style={videoSmallTextStyle}>
                                {data?.countWebseries}
                              </span>
                            </Text>
                            <Button
                              bg="blue"
                              _hover={{ bg: "blue.500" }}
                              color="white"
                              onClick={() => {
                                setCompanyNameCheck(data?.companyName);
                                handleLogsExport(data?.companyName);
                              }}
                            >
                              {loadingSpecificLogsRead &&
                              companyNameCheck === data?.companyName ? (
                                <Spinner />
                              ) : (
                                "Export logs"
                              )}
                            </Button>
                            <Button
                              bg="blue"
                              _hover={{ bg: "blue.500" }}
                              color="white"
                              onClick={() => {
                                setCompanyNameCheck(data?.companyName);
                                handleVideoLogsExport(data?.companyName);
                              }}
                            > 
                              {loadingVideoLogsRead &&
                              companyNameCheck === data?.companyName ? (
                                <Spinner />
                              ) : (
                                "Export video logs"
                              )}
                            </Button>
                            <Button
                              bg="blue"
                              _hover={{ bg: "blue.500" }}
                              color="white"
                              onClick={() => {
                                setCompanyNameCheck(data?.companyName);
                                handleVideoCountExport(data?.companyName);
                              }}
                            >
                              {loadingVideoLogsCount &&
                              companyNameCheck === data?.companyName ? (
                                <Spinner />
                              ) : (
                                "Export video counts"
                              )}
                            </Button>
                          </Flex>
                        </Flex>
                      ))}
                    </Flex>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
