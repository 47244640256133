/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // Npm: Apollo client for handling graphql request.

/*
 * GRAPHS
 */
const Index = gql`
  query MyQuery {
    BuyFormRead {
      companyName
      companyUrl
      createdAt
      email
      firstName
      id
      lastName
      message
      phone
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
