/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
mutation MyMutation($categoryId: ID, $categoryName: String, $priority: Int) {
    VideoCategoryEdit(
      categoryId: $categoryId
      categoryName: $categoryName
      priority: $priority
    ) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
