/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable array-element-newline */
/* eslint-disable array-bracket-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable max-lines-per-function */
/*
 * IMPORT
 */
import React, { useEffect } from "react";
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";
import _ from "underscore";
import PodcastForm from "./podcastForm";
import Carousel from "./PodCastTray";
import { tabStyle } from "../../../../components/Styles/index.style";

/*
 * CHAKRA IMPORTS
 */
import {
  Flex,
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
} from "@chakra-ui/react";

/*
 * MUTATAION
 */

/*
 * QUERY
 */
import PodcastRead from "./__query__/index.podcastRead.query";
import { useQuery } from "@apollo/client";

/*
 * OBJECT
 */
function Index() {
  /*
   * States
   */
  const _getPodcast = useQuery(PodcastRead);

  /*
   * STYLE
   */

  /*
   * Functions
   */

  /*
   * UseEffect
   */

  useEffect(() => {
    _getPodcast?.refetch();
  }, []);

  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
        w="100%"
        minHeight="100vh"
      >
        <Flex w="100%" h="100px"></Flex>

        <Flex mt="1rem">
          <Flex overflow="scroll" direction="column" w="100%">
            <Tabs width="100%">
              <TabList
                gap="3rem"
                padding="0px"
                style={tabStyle}
                borderColor="none"
                borderBottom="1px solid #505050"
              >
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Upload Podcast
                </Tab>
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Audio Podcast List
                </Tab>
              </TabList>
              <TabIndicator
                mt="-2px"
                height="3px"
                bg="#FFFFFF"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel w="100%" padding="0px">
                  <PodcastForm />
                </TabPanel>
                <TabPanel w="100%" padding="0px">
                  <Box
                    overflow="hidden"
                    w="100%"
                    pt="5rem"
                    fontFamily="Poppins"
                    fontSize={["clamp(12px, 1.5vw, 15px)"]}
                    fontWeight="500"
                    color="#263238"
                  >
                    <Flex direction="column">
                      <Carousel data={_getPodcast?.data?.PodcastRead} />
                    </Flex>
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}

/*
 * EXPORTS
 */
export default Index;
