/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // Npm: Apollo client for handling graphql request.

/*
 * GRAPHS
 */
const Index = gql`
  query MyQuery($buyformId: ID) {
    BuyFormCartInfo(buyformId: $buyformId) {
      data
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
