/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // Npm: Apollo client for handling graphql request.

/*
 * GRAPHS
 */
const Index = gql`
  query MyQuery {
    CategoryRead {
      id
      message
      name
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
