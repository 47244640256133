
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-shadow */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import _ from "underscore";
import { createUploadLink } from "apollo-upload-client";
import { FaPlus } from "react-icons/fa6";
import { MdOutlineFileUpload, MdErrorOutline } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import { IoClose, IoCheckmarkDoneSharp } from "react-icons/io5";
import { useLazyQuery, useMutation } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import { connect } from "react-redux"; // NPM: React-redux library.
import AddMoreEpisodeByAdmin from "../../../__mutation__/index.webSeriesAddMore.mutation";
import TimeInput from "../../../../../../components/timeInput/timeInput";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client, S3 } from "@aws-sdk/client-s3";
import { sellbutton , labelStyle , inputStyle , inputStyle1 } from "../../../../../../components/Styles/index.style"
import NoSleep from "nosleep.js";


const initialState = {
  creatorName: "",
  lastName: "",
  userName: "",
  email: "",
  phone: "",
  contentType: "",
  contentTitle: "",
  contentLink: "",
  contentVideoLink: "",
  plotSummary: "",
  duration: "",
  language: "",
  verticalThumbnailStoredAt: "",
  thumbnailStoredAt: "",
  ageRating: "",
  genre: "",
  dop: "",
  episodeNumber: "",
  producer: "",
  cast: "",
  name: "",
  music: "",

  isEmbedded: false,
};

/*
 * CHAKRA UI IMPORT
 */
import {
  FormControl,
  FormLabel,
  Text,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Input,
  Select,
  useDisclosure,
  Checkbox,
  InputGroup,
  InputRightElement,
  CircularProgress,
  CircularProgressLabel,
  Image,
  useToast,
} from "@chakra-ui/react";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

/*
 * OBJECT
 */
const TimeWrapper = ({ onDataFromChild }) => {
  const [timeDuration, setTimeDuration] = useState("");

  const onTimeChangeHandler = (val) => {
    const dur =
      parseInt(val.split(":")[0]) * 60 +
      parseInt(val.split(":")[1]) +
      parseInt(val.split(":")[2]) / 60;
    setTimeDuration(dur);
    onDataFromChild(dur);
  };

  return (
    <TimeInput
      name="duration"
      borderColor="none"
      className="s-input -time"
      onTimeChange={onTimeChangeHandler}
      placeholder="HH:MM:SS" // Set the placeholder text
    />
  );
};

/*
 * OBJECT
 */
function Index({ account, contentDetailId }) {
  /*
   * STATE
   */

  const toast = useToast();
  const history = useHistory();
  const [formData, setFormData] = useState(initialState);
  const [linkStateMultiple, setLinkStateMultiple] = useState([]);
  const [videoLoading, setVideoLoading] = useState(false);
  const [videoUploadStatus, setVideoUploadStatus] = useState("");
  const [horizontalUploadStatus, setHorizontalUploadStatus] = useState("");
  const [verticalUploadStatus, setVerticalUploadStatus] = useState("");
  const [emptyDuration, setEmptyDuration] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const {
    isOpen: isOpenWebSeries,
    onOpen: onOpenWebSeries,
    onClose: onCloseWebSeries,
  } = useDisclosure();

  const [videoUploadFile, setVideoUploadFile] = useState();
  const [progress, setProgress] = useState(0); // State to track progress

  const [linkState, setLinkState] = useState({
    id: uuidv4(),
    contentLink: "",
    plotSummary: "",
    duration: "",
    episodeNumber: "",
    verticalThumbnailStoredAt: "",
    contentVideoLink: "",
    thumbnailStoredAt: "",
    isEmbedded: false,
  });

 

  // Function to confirm leaving the page
  const confirmLeave = (e) => {
    const confirmationMessage =
      "Your upload is in progress. Are you sure you want to leave?";
    e.returnValue = confirmationMessage;

    return confirmationMessage;
  };

 
  const [
    // eslint-disable-next-line array-element-newline
    AddMoreEpisodeByAdminMutation,
    { loading: loadingAddMoreEpisodeByAdmin },
  ] = useMutation(AddMoreEpisodeByAdmin, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  const resetForm = () => {
    setFormData({
      creatorName: "",
      lastName: "",
      userName: "",
      email: "",
      phone: "",
      contentType: "",
      contentTitle: "",
      contentLink: "",
      contentVideoLink: "",
      plotSummary: "",
      duration: "",
      language: "",
      verticalThumbnailStoredAt: "",
      thumbnailStoredAt: "",
      ageRating: "",
      genre: "",
      dop: "",
      episodeNumber: "",
      producer: "",
      cast: "",
      name: "",
      music: "",
      isEmbedded: false,
    });
    setVideoUploadStatus("");
    setVerticalUploadStatus("");
    setHorizontalUploadStatus("");
    setLinkState({});
    setLinkStateMultiple([]);
  };

  /*
   * FUNCTION
   */

  const handleCreatorForm = async () => {
    try {
      const response = await AddMoreEpisodeByAdminMutation({
        variables: {
            ContentSummaryId: contentDetailId,
          content: linkStateMultiple,
        },
      });

      const data = response.data.AddMoreEpisodeByAdmin;

      if (!_.isEmpty(data)) {
        if (data.status === "REQUIRE__LOGIN") {
          resetForm(); // Reset form data after submission
          onCloseWebSeries(); // Close the modal after submission
          // Show success toast
          toast({
            title: "Error",
            description: "Login Required",
            status: "error",
            duration: 5000, // Milliseconds
            isClosable: true,
          });
        } else if (data.status === "FORM_SUBMIT") {
          resetForm(); // Reset form data after submission
          onCloseWebSeries();
          history.push("/");
          // Show success toast
          toast({
            title: "Form Submitted",
            description: "Your form has been submitted successfully!",
            status: "success",
            duration: 5000, // Milliseconds
            isClosable: true,
          });
        }
      }
    } catch (error) {
      // Show error toast
      toast({
        title: "Error",
        description:
          "An error occurred while submitting the form Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLinkState((prevFormData) => ({
        ...prevFormData,
        thumbnailStoredAt: file,
      }));
      setHorizontalUploadStatus("success");
    } else {
      setHorizontalUploadStatus("error");
    }
  };
  // Function to disable modal closing actions
  const disableModalClosing = () => {
    // Disable modal close
    setModalOpen(false);
  };

  const enableModalClosing = () => {
    // Enable modal close
    setModalOpen(true);
  };

  const handleVideoUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      setLinkState((prevFormData) => ({
        ...prevFormData,
        contentVideoLink: file,
      }));
      setVideoUploadStatus("success");
    } else {
      setVideoUploadStatus("error");
    }
  };

  const handleVerticalImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      setLinkState((prevFormData) => ({
        ...prevFormData,
        verticalThumbnailStoredAt: file,
      }));
      setVerticalUploadStatus("success");
    } else {
      setVerticalUploadStatus("error");
    }
  };

  React.useEffect(() => {
    // Update buffer to window.
    window.Buffer = window.Buffer || require("buffer").Buffer;
  });

  const updateProgressBar = (percent) => {
    setProgress(percent); // Update the progress state
  };

  const handleLinkState = () => {
    if (
      linkState.duration &&
      linkState.plotSummary &&
      linkState.episodeNumber &&
      linkState.contentVideoLink
    ) {
      const uniqueId = uuidv4();

      const newLinkState = {
        ...linkState,
        id: uniqueId, // Add the unique ID to the linkState object
      };

      setLinkStateMultiple((prevLinkState) => [...prevLinkState, newLinkState]);

      setLinkState((prev) => ({
        ...prev,
        contentLink: "",
        plotSummary: "",
        episodeNumber: "",
        duration: "",
        verticalThumbnailStoredAt: "",
        thumbnailStoredAt: "",
        contentVideoLink: "",
        isEmbedded: false,
      }));
      setEmptyDuration(true);
      setVideoUploadStatus("");
      setHorizontalUploadStatus("");
      setVerticalUploadStatus("");
    } else if (!linkState.duration) {
      toast({
        title: "Duration is required",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else if (!linkState.plotSummary) {
      toast({
        title: "Plot Summary is required",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else if (!linkState.episodeNumber) {
      toast({
        title: "Episode Number is required",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else if (!linkState.contentVideoLink) {
      toast({
        title: "Content Video Link is required",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleLinkStateChange = (e) => {
    const { name, value } = e.target;

    setLinkState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (linkStateMultiple.length > 0) {
      // Check if there are files to upload
      setVideoLoading(true);
      disableModalClosing();
      const noSleep = new NoSleep();

      // Prevent page refresh or tab close
      window.addEventListener("beforeunload", confirmLeave);
      noSleep.enable();

      try {
        const s3Client = new S3Client({
          forcePathStyle: false,
          endpoint: process.env.REACT_APP_S3_ENDPOINT,
          region: "blr1",
          credentials: {
            accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
          },
        });

        // Array to store promises for each upload
        const uploadPromises = [];

        // Iterate over each object in linkStateMultiple
        linkStateMultiple.forEach((item, index) => {
          const id = uuidv4();
          const parallelUploads3 = new Upload({
            client: s3Client,
            params: {
              Bucket: "shucaeimage",
              Key: `shucaeContent/${id}.mp4`,
              Body: item.contentVideoLink,
              ACL: "public-read",
            },
            queueSize: 4, // Optional concurrency configuration
            partSize: 1024 * 1024 * 10, // Optional size of each part, in bytes, at least 5MB
            leavePartsOnError: false, // Optional manually handle dropped parts
          });

          // Add event listener for progress
          parallelUploads3.on("httpUploadProgress", (progress) => {
            const percentUploaded = Math.round(
              (progress.loaded / progress.total) * 100
            );
            updateProgressBar(percentUploaded); // Update progress bar
          });
         // Push promise to array
         uploadPromises.push(
          parallelUploads3.done().then((_data) => {
            // Store location in linkStateMultiple
            if (_data.Location) {
              if (!_data.Location.startsWith("https://")) {
                _data.Location = `https://${_data.Location}`;
              }
              linkStateMultiple[index].contentVideoLink = _data.Location;
            }
          })
        );
      });

        // Wait for all uploads to complete
        await Promise.all(uploadPromises);
        handleCreatorForm();
        noSleep.disable();

        setVideoLoading(false);
      } catch (e) {
        setVideoLoading(false);
        noSleep.disable();
      } finally {
        window.removeEventListener("beforeunload", confirmLeave);
        enableModalClosing();
        noSleep.disable();
      }
    }
  };

  const handleDeleteLinkState = (id) => {
    // Filter out the item with the specified id
    const updatedLinkState = linkStateMultiple.filter((item) => item.id !== id);

    // Update the state with the modified array
    setLinkStateMultiple(updatedLinkState);
  };

  const handleClose = () => {
    if (modalOpen) {
      resetForm();
      onCloseWebSeries();
    }
  };

  const handleDuration = (data) => {
    // Handle the data received from the child component
    const form = { ...formData, duration: data };
    setLinkState((prevLinkState) => ({
      ...prevLinkState,
      duration: data,
    }));
    setFormData(form);
  };

  return (
    <>
      <Button
        bg="blue"
        style={sellbutton}
        onClick={() => onOpenWebSeries()}
        width= "150px"
        height= "60px"
        _hover={{ bg: "blue" }}
      >
        Add
      </Button>
      <Modal
        size="5xl"
        isOpen={isOpenWebSeries}
        onClose={handleClose}
        scrollBehavior="inside"
        closeOnOverlayClick={false}
      >
        {" "}
        <ModalOverlay />
        <ModalContent
          p={{ base: "7px", md: "15px" }}
          borderRadius="20px"
          bg="rgba(42, 45, 62, 1)"
          position="fixed"
        >
          <ModalHeader
            fontFamily="Roboto"
            fontWeight="500"
            fontSize={{ base: "25px", md: "32px" }}
            lineHeight="28.21px"
            letterSpacing="-0.64px"
            color="rgba(255, 255, 255, 1)"
          >
            Add More Episode
          </ModalHeader>
          <ModalCloseButton
            size="lg"
            mt="15px"
            mr="10px"
            color="rgba(238, 93, 80, 1)"
          />
          <ModalBody pb={10} overflowY="auto" overflowX="hidden">
            {/* /
            Form
            / */}

            <form onSubmit={handleSubmit}>
              <Flex gap="1rem" direction="column">
                {/* /
                Link of Content
                and
                Plot Summary
                and 
                Duration
                and
                Thumbnail
                / */}

                <Flex
                  p="1rem 10px"
                  align="center"
                  direction="column"
                  bg="#1E202D"
                  w={{ base: "106%", md: "100%" }}
                  ml={{ base: "-0.4rem", md: "0" }}
                >
                  <Flex
                    align="center"
                    flexWrap="wrap"
                    p="10px 10px 10px 30px"
                    w="100%"
                    gap="1rem"
                  >
                    {linkStateMultiple.map((item) => (
                      <Flex
                        p="20px"
                        borderRadius="5px"
                        bg="#D9D9D9"
                        key={item.id}
                        align="top"
                        gap="1rem"
                        style={labelStyle}
                        w="100%"
                      >
                        <Flex
                          w="100%"
                          gap=".5rem"
                          color="#666874"
                          direction="column"
                        >
                          <Text>
                            Video name:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item?.contentVideoLink?.name}
                            </span>
                          </Text>
                          <Text>
                            Plot:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item?.plotSummary}
                            </span>
                          </Text>
                          <Text>
                            Episode number:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item?.episodeNumber}
                            </span>
                          </Text>
                          <Text>
                            Duration:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item?.duration} mins
                            </span>
                          </Text>
                          <Text>
                            Embedded Link:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item?.isEmbedded ? "Yes" : "No"}
                            </span>
                          </Text>
                          <Text>
                            Horizontal Poster Name:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item?.thumbnailStoredAt?.name
                                ? item?.thumbnailStoredAt?.name
                                : "No Poster"}
                            </span>
                          </Text>
                          <Text>
                            Vertical Poster Name:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item?.verticalThumbnailStoredAt?.name
                                ? item?.verticalThumbnailStoredAt?.name
                                : "No Poster"}
                            </span>
                          </Text>
                          {item?.thumbnailStoredAt?.name ? (
                            <Flex
                              borderRadius="10px"
                              border="1px solid #878992"
                            >
                              <Image
                                borderRadius="10px"
                                w="100%"
                                src={
                                  item.thumbnailStoredAt
                                    ? URL.createObjectURL(
                                        item.thumbnailStoredAt
                                      )
                                    : ""
                                }
                              />
                            </Flex>
                          ) : (
                            <></>
                          )}
                          {item?.verticalThumbnailStoredAt?.name ? (
                            <Flex
                              w="250px"
                              h="375px"
                              borderRadius="10px"
                              border="1px solid #878992"
                            >
                              <Image
                                borderRadius="10px"
                                src={
                                  item.verticalThumbnailStoredAt
                                    ? URL.createObjectURL(
                                        item.verticalThumbnailStoredAt
                                      )
                                    : ""
                                }
                              />
                            </Flex>
                          ) : (
                            <></>
                          )}
                        </Flex>
                        <Flex>
                          <IoClose
                            size="20px"
                            cursor="pointer"
                            color="#7551FF"
                            onClick={() => handleDeleteLinkState(item.id)}
                          />
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                  <Flex
                    gap="1rem"
                    direction="column"
                    w={{ base: "100%", md: "92%" }}
                  >
                    <Flex
                      gap="32px"
                      align="center"
                      direction={{ base: "column", md: "row" }}
                      width="100%"
                    >
                      <FormControl w="200%">
                        <FormLabel style={labelStyle}>
                          <Flex align="center" gap="5px">
                            Upload video
                            {videoUploadStatus === "success" ? (
                              <IoCheckmarkDoneSharp />
                            ) : videoUploadStatus === "error" ? (
                              <MdErrorOutline />
                            ) : (
                              ""
                            )}
                          </Flex>
                        </FormLabel>

                        <InputGroup align="center">
                          <Button
                            border="1px solid #3D445A"
                            bg="transparent"
                            _hover={{ bg: "transparent" }}
                            onClick={() => {
                              // Trigger the hidden file input when the button is clicked
                              document.getElementById("videoInput").click();
                            }}
                            style={inputStyle}
                            w="100%"
                            position="relative"
                          >
                            {videoLoading ? (
                              <Spinner />
                            ) : (
                              <MdOutlineFileUpload
                                size="25px"
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                }}
                              />
                            )}
                          </Button>
                          {/* Hidden file input */}
                          <Input
                            type="file"
                            id="videoInput"
                            name="contentVideoLink"
                            onChange={handleVideoUpload}
                            display="none"
                          />
                        </InputGroup>
                      </FormControl>

                      <FormControl>
                        <FormLabel style={labelStyle}>Duration</FormLabel>

                        <TimeWrapper
                          onDataFromChild={handleDuration}
                          emptyDuration={emptyDuration}
                        />
                      </FormControl>
                    </Flex>
                    <FormControl>
                      <FormLabel style={labelStyle}>Episode number</FormLabel>
                      <Input
                        name="episodeNumber"
                        type="number"
                        onChange={handleLinkStateChange}
                        value={linkState.episodeNumber}
                        placeholder="Episode number"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                        w={{ base: "320px", md: "410px" }}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel style={labelStyle}>Plot summary</FormLabel>
                      <Input
                        name="plotSummary"
                        type="text"
                        onChange={handleLinkStateChange}
                        value={linkState.plotSummary}
                        placeholder="Write summary"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle1}
                        width={{ base: "320px", md: "852px" }}
                      />
                    </FormControl>

                    <FormControl w="200%">
                      <FormLabel style={labelStyle}>
                        <Flex align="center" gap="5px">
                          Upload content horizontal poster
                          {horizontalUploadStatus === "success" ? (
                            <IoCheckmarkDoneSharp />
                          ) : horizontalUploadStatus === "error" ? (
                            <MdErrorOutline />
                          ) : (
                            ""
                          )}
                        </Flex>
                      </FormLabel>

                      <InputGroup align="center">
                        <Button
                          border="1px solid #3D445A"
                          bg="transparent"
                          _hover={{ bg: "transparent" }}
                          width={{ base: "320px", md: "852px" }}
                          onClick={() => {
                            // Trigger the hidden file input when the button is clicked
                            document
                              .getElementById("horizontalThumbnailInput")
                              .click();
                          }}
                          style={inputStyle1}
                          position="relative"
                        >
                          <MdOutlineFileUpload
                            size="25px"
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          />
                        </Button>
                        {/* Hidden file input */}
                        <Input
                          type="file"
                          id="horizontalThumbnailInput"
                          name="thumbnailStoredAt"
                          onChange={handleImageUpload}
                          display="none"
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl w="200%">
                      <FormLabel style={labelStyle}>
                        <Flex align="center" gap="5px">
                          Upload content vertical poster
                          {verticalUploadStatus === "success" ? (
                            <IoCheckmarkDoneSharp />
                          ) : verticalUploadStatus === "error" ? (
                            <MdErrorOutline />
                          ) : (
                            ""
                          )}
                        </Flex>
                      </FormLabel>

                      <InputGroup align="center">
                        <Button
                          border="1px solid #3D445A"
                          bg="transparent"
                          _hover={{ bg: "transparent" }}
                          width={{ base: "320px", md: "852px" }}
                          onClick={() => {
                            // Trigger the hidden file input when the button is clicked
                            document
                              .getElementById("verticalThumbnailInput")
                              .click();
                          }}
                          style={inputStyle1}
                          position="relative"
                        >
                          <MdOutlineFileUpload
                            size="25px"
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          />
                        </Button>
                        {/* Hidden file input */}
                        <Input
                          type="file"
                          id="verticalThumbnailInput"
                          name="verticalThumbnailStoredAt"
                          onChange={handleVerticalImageUpload}
                          display="none"
                        />
                      </InputGroup>
                    </FormControl>

                    <Flex
                      w="54px"
                      h="50px"
                      border="1px solid #3D445A"
                      borderRadius="12px"
                      justify="center"
                      align="center"
                      onClick={() => handleLinkState()}
                      cursor="pointer"
                    >
                      <FaPlus color="#666874" />
                    </Flex>
                  </Flex>
                </Flex>

                <Flex
                  color="rgba(43, 54, 116, 1)"
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="16px"
                  fontFamily="Roboto"
                  letterSpacing="-0.32px"
                  justifyContent="center"
                  mt="3rem"
                >
                  <Button
                    _focus={{ outline: "2px solid rgba(66, 153, 225, 0.6)" }}
                    type="submit"
                    width={{ base: "361px", md: "385px" }}
                    height={{ base: "41.34px", md: "54px" }}
                    borderRadius={{ base: "7.6px", md: "10px" }}
                    bg="rgba(255, 255, 255, 1)"
                  >
                    <Text
                      bg="linear-gradient(96.31deg, #052DB0 7.3%, #C30280 118.79%)"
                      bgClip="text"
                    >
                      {loadingAddMoreEpisodeByAdmin || videoLoading ? (
                        <Spinner color="black" />
                      ) : (
                        <Text>Submit</Text>
                      )}
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

/*
 * EXPORT
 */

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
