
/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React, { useRef, useState } from "react";

import "./index.css";
import { connect } from "react-redux"; // NPM: React-redux library.
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// Import required modules
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";

/*
 * CHAKRA IMPORTS
 */
import { Flex, Text, Image } from "@chakra-ui/react";

/*
 * MUTATION
 */

/*
 * QUERY
 */
import MovieDetail from "./MovieDetail";

/*
 * OBJECT
 */
function Index({ data, account }) {
  return (
    <Flex py="2rem" bg="transparent" flexDir="column">
      <Flex align="center" gap="10px">
        <Text pb="5px" pl="30px" color="white" fontSize="28px">
          PODCAST
        </Text>
      </Flex>
      <Flex ml="10px" mt="20px" position="relative" maxW="100%">
        {data?.length > 0 ? (
          <Flex pl="30px" pr="30px" w="100%">
            <Swiper
              slidesPerView={5}
              spaceBetween={50}
              centeredSlides={false}
              modules={[Pagination, Navigation]}
              className="mySwiper"
              style={{
                width: "100%",
                alignItems: "center",
                overflow: "visible",
              }}
            >
              {data?.map((movie, index) => (
                <SwiperSlide
                  key={index}
                  style={{
                    width: "100px",
                  }}
                >
                  <Flex w="100%" justify="center" align="center" h="100%">
                    <CardComp account={account} key={index} item={movie} />
                  </Flex>
                </SwiperSlide>
              ))}
            </Swiper>
          </Flex>
        ) : (
          <Flex
            w="100%"
            justify="center"
            align="center"
            h="300px"
            fontWeight="bold"
          >
            <Text color="white" fontSize="40px">
              NO VIDEOS IN THIS TRAY
            </Text>
          </Flex>
        )}
      </Flex>
      {/* <Flex w="100%">
        <Swiper
          slidesPerView={5}
          spaceBetween={10}
          centeredSlides={false}
          modules={[Pagination, Navigation]}
          className="mySwiper"
          style={{
            width: "100%",
            alignItems: "center",
            overflow: "visible",
          }}
        >
          {data?.map((movie, index) => (
            <SwiperSlide
              key={index}
              style={{
                width: "100px",
              }}
            >
              <Flex w="100%" justify="center" align="center" h="100%">
                <CardComp account={account} key={index} item={movie} />
              </Flex>
            </SwiperSlide>
          ))}
        </Swiper>
      </Flex> */}
    </Flex>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

/*
 * COMPONENT
 */

/*
 * COMPONENT
 */
function CardComp({ account, item, title }) {
  const cardRef = useRef(null);
  const [showModal, setShowModal] = useState(false);

  const titleStyle = {
    color: "#FFFFFF",
    fontWeight: "700",
    fontFamily: "Montserrat",
    fontSize: "20px",
  };
  const subTitleStyle = {
    color: "#878992",
    fontWeight: "600",
    fontFamily: "Montserrat",
    fontSize: "16px",
    letterSpacing: "-0.32px",
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  function truncateAfterTenLetters(text) {
    if (!text) return "";
    if (text.length <= 30) return text;

    return `${text.substring(0, 30)}...`;
  }

  return (
    <Flex
      ref={cardRef}
      mx="10px"
      borderRadius="15px"
      position="relative"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      transition="all 0.3s ease"
      onClick={toggleModal}
      cursor="pointer"
    >
      <Flex w="320px" h="200px" className="card_image_div" borderRadius="15px">
        <Image
          src={item?.horizontalPosterLink}
          borderRadius="15px"
          className="movie_thumbnail"
          alt="image"
        />
      </Flex>
      <Flex p="10px" w="100%" direction="column">
        <Text style={titleStyle}>{truncateAfterTenLetters(item?.podcastTitle)}</Text>
        <Text style={subTitleStyle}>
          {new Date(item?.createdAt).getFullYear()}{" "}
        </Text>
      </Flex>

      <MovieDetail
        account={account}
        isOpen={showModal}
        onClose={toggleModal}
        movieDetails={item}
        title={title}
      />
    </Flex>
  );
}
