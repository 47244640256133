
/* eslint-disable max-lines */
/* eslint-disable init-declarations */
/* eslint-disable max-lines-per-function */
/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React, { useState } from "react";
import { connect } from "react-redux";
import { videoSmallTextStyle , videoBigTextStyle } from "../../../../components/Styles/index.style"

/*
 * CHAKRA IMPORTS
 */
import { Image, Flex, Text } from "@chakra-ui/react";

/*
 * MUTATION
 */

/*
 * QUERY
 */

function Index({ approved }) {
  /*
   * States
   */

  const [expandedSummary, setExpandedSummary] = useState({});

  /*
   * STYLE
   */



  /*
   * Functions
   */
  const toggleSummary = (id) => {
    setExpandedSummary((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };


  function formatDuration(durationInMinutes) {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.floor(durationInMinutes % 60);
    const seconds = Math.floor((durationInMinutes % 1) * 60);
  
    let formattedDuration = "";
  
    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }
  
    if (minutes > 0) {
      formattedDuration += `${minutes}m `;
    }
  
    if (seconds > 0) {
      formattedDuration += `${seconds}s`;
    }
  
    return formattedDuration.trim(); // Remove any leading/trailing whitespace
  }


  return (
    <Flex
      overflowY="auto"
      maxHeight="700px"
      p="35px 24px"
      direction="column"
      gap="1rem"
    >
      {approved?.map((data, index) => (
        <Flex
          p="15px"
          key={data.id}
          borderRadius="10px"
          bg="#000000"
          w="100%"
          gap="1rem"
          cursor="pointer"
        >
          <Flex color="white">{index + 1}</Flex>
          <Flex w="256px" h="158px">
            <Image width="100%" height="100%" src={data?.lowerThumbnailLink} />
          </Flex>
          <Flex gap=".5rem" direction="column">
            <Text style={videoBigTextStyle}>
              {data?.ContentSummary?.contentTitle}
            </Text>
            <Text w="850px" style={videoSmallTextStyle}>
              {expandedSummary[index] ? (
                <span>
                  {data?.plotSummary}
                  <Text
                    color="rgba(0, 129, 223, 1)"
                    cursor="pointer"
                    onClick={() => toggleSummary(index)}
                  >
                    read less
                  </Text>
                </span>
              ) : (
                <span>
                  {data?.plotSummary?.split(" ")?.slice(0, 20)?.join(" ")}
                  {data?.plotSummary?.split(" ").length > 20 && (
                    <Text
                      color="rgba(0, 129, 223, 1)"
                      cursor="pointer"
                      onClick={() => toggleSummary(index)}
                    >
                      read more
                    </Text>
                  )}
                </span>
              )}
            </Text>
            <Flex gap=".5rem" direction="column">
              <Flex align="center" gap="1rem">
                <Text
                  color="rgba(69, 211, 105, 1)"
                  fontFamily="Roboto"
                  fontSize="19px"
                  fontWeight="700"
                  lineHeight="21.54px"
                  letterSpacing="-0.36px"
                >
                  {new Date(data?.createdAt).getFullYear()}
                </Text>
                <Text
                  color="rgba(186, 186, 186, 1)"
                  fontFamily="Roboto"
                  fontSize="19px"
                  fontWeight="400"
                  lineHeight="21.54px"
                  letterSpacing="-0.36px"
                >
                  {formatDuration(data?.duration)}
                </Text>
                <Flex
                  borderRadius="3px"
                  justify="center"
                  align="center"
                  h="19px"
                  border="1px solid rgba(116, 116, 116, 1)"
                  color="rgba(255, 255, 255, 1)"
                  fontFamily="Roboto"
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="21.54px"
                >
                  HD
                </Flex>
              </Flex>
              <Flex align="center" gap="1rem">
                <Flex
                  color="white"
                  w="70px"
                  h="20px"
                  justify="center"
                  align="center"
                  border="1px solid rgba(116, 116, 116, 1)"
                >
                  {`${data?.ContentSummary?.ageRating === "18" ? "A" : "U/A"} ${
                    data?.ContentSummary?.ageRating
                  }+`}
                </Flex>
                <Text style={videoSmallTextStyle}>
                  <span style={{ textTransform: "capitalize" }}>
                    {data?.ContentSummary?.genre}
                  </span>
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex ml="15rem" gap=".5rem" direction="column">
            <Text
              style={videoBigTextStyle}
            >{`@${data?.ContentSummary?.Account?.userName}`}</Text>
            <Text style={videoSmallTextStyle}>
              {data?.ContentSummary?.Account?.fullName}
            </Text>
            <Flex gap=".5rem" direction="column">
              <Text style={videoSmallTextStyle}>
                Followers :{" "}
                <span>
                  {
                    data?.ContentSummary?.Account
                      ?.Follow_Follow_Account__followerIdToAccount?.length
                  }
                </span>
              </Text>
              <Flex align="center" gap="1rem">
                <Text color="#5A2CC1">Status</Text>

                <Text color="green">Approved</Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex></Flex>
        </Flex>
      ))}
    </Flex>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
