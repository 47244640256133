/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery {
    HomePageAdsread {
      adsUrl
      adsPoster
      verticalAdsPoster
      brandName
      id
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
