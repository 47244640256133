/* eslint-disable no-constant-condition */
/* eslint-disable multiline-comment-style */
/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import Navbar from "../LandingNavbar/index";
import Footer from "../LandingFooter/index";
import { connect } from "react-redux"; // NPM: React-redux library.
import { useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import Loader from "../../Views/User/website/LandingPage/Loader";
import _ from "underscore";

/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  Image,
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  Button,
  useToast,
  Spinner,
} from "@chakra-ui/react";

/*
 * MUTATION
 */

/*
 * QUERY
 */
import BuyFormRead from "./__query__/index.buyerFormRead.query";

function Index({ account }) {
  /*
   * States
   */
  const [pendingRequest, setPendingRequest] = useState();
  const history = useHistory();
  const [expandedSummary, setExpandedSummary] = useState({});
  const toast = useToast();

  const _BuyFormRead = useQuery(BuyFormRead, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  /*
   * STYLE
   */
  const tabStyle = {
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "55.62px",
    letterSpacing: "0.32px",
  };

  const videoSmallTextStyle = {
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "30px",
  };

  const videoBigTextStyle = {
    color: "grey",
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0.2px",
  };

  /*
   * FUNCTIONS
   */

  const calculateDaysDifference = (dateString) => {
    const currentDate = new Date();
    const providedDate = new Date(dateString);
    const timeDifference = currentDate - providedDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  };

  /*
   * USEEFFECT
   */

  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
        w="100%"
        minHeight="100vh"
      >
        <Flex w="100%" h="100px"></Flex>

        <Flex mt="1rem">
          <Flex overflow="scroll" direction="column" w="100%">
            <Tabs width="100%">
              <TabList
                gap="3rem"
                padding="0px"
                style={tabStyle}
                borderColor="none"
                borderBottom="1px solid #505050"
              >
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                >
                  Request List
                </Tab>
              </TabList>
              <TabIndicator
                mt="-2px"
                height="3px"
                bg="#FFFFFF"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel w="100%" padding="0px">
                  {_BuyFormRead?.loading ? (
                    <Flex align="center" justify="center" w="100%">
                      <Loader />
                    </Flex>
                  ) : (
                    <Flex
                      overflowY="auto"
                      maxHeight="700px"
                      p="35px 24px"
                      direction="column"
                      gap="1rem"
                    >
                      {_BuyFormRead?.data?.BuyFormRead?.length === 0 ? (
                        <>
                          <Flex
                            align="center"
                            justify="center"
                            w="100%"
                            h="100vh"
                            color="white"
                            fontSize="60px"
                            fontWeight="700"
                          >
                            No Request Found
                          </Flex>
                        </>
                      ) : (
                        <>
                          {_BuyFormRead?.data?.BuyFormRead?.map(
                            (data, index) => (
                              <Flex
                                p="20px"
                                key={data?.id}
                                borderRadius="10px"
                                bg="#000000"
                                w="100%"
                                gap="1rem"
                                align="center"
                                onClick={() => {
                                  history.push(`/buyer-cart?id=${data?.id}`);
                                }}
                                cursor="pointer"
                              >
                                <Flex
                                  border="2px solid white"
                                  w="35px"
                                  h="35px"
                                  borderRadius="50%"
                                  color="white"
                                  justify="center"
                                  align="center"
                                  fontWeight="700"
                                >
                                  {index + 1}
                                </Flex>
                                <Flex gap="20px" direction="column">
                                  <Text w="700px" style={videoBigTextStyle}>
                                    First Name -{" "}
                                    <span style={videoSmallTextStyle}>
                                      {data?.firstName}
                                    </span>
                                  </Text>
                                  <Text w="700px" style={videoBigTextStyle}>
                                    Last Name -{" "}
                                    <span style={videoSmallTextStyle}>
                                      {data?.lastName}
                                    </span>
                                  </Text>
                                  <Text w="700px" style={videoBigTextStyle}>
                                    Email -{" "}
                                    <span style={videoSmallTextStyle}>
                                      {data?.email}
                                    </span>
                                  </Text>
                                  <Text w="700px" style={videoBigTextStyle}>
                                    Contact Number -{" "}
                                    <span style={videoSmallTextStyle}>
                                      {data?.phone}
                                    </span>
                                  </Text>
                                  <Text style={videoBigTextStyle}>
                                    Company name -{" "}
                                    <span style={videoSmallTextStyle}>
                                      {data?.companyName}
                                    </span>
                                  </Text>

                                  <Text style={videoBigTextStyle}>
                                    Company url -{" "}
                                    <span style={videoSmallTextStyle}>
                                      <a
                                        href={data?.companyUrl}
                                        style={{ color: "blue" }}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {data?.companyUrl}
                                      </a>
                                    </span>
                                  </Text>
                                </Flex>
                                <Flex ml="10rem" gap="20px" direction="column">
                                  <Text style={videoBigTextStyle}>
                                    Requested -{" "}
                                    <span style={videoSmallTextStyle}>
                                      {calculateDaysDifference(data?.createdAt)}{" "}
                                      days ago
                                    </span>
                                  </Text>
                                </Flex>
                              </Flex>
                            )
                          )}
                        </>
                      )}
                    </Flex>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
